
const USER_LOCAL_STORAGE_KEY = 'userAccessToken';
const USER_DATA_KEY = 'userData';
const ARTWORKS_CACHE_STORAGE_KEY = 'artworksCacheStorage';
const BASE_URL = 'https://velflcollection.com'


const SHARING_OPTIONS_PRIVATE = 'PRIVATE';
const SHARING_OPTIONS_LIMITED = 'LIMITED';
const SHARING_OPTIONS_PUBLIC = 'PUBLIC';

const LOCATION_FUNCTION_DEPOSITARY = 'DEPOSITARY';
const LOCATION_FUNCTION_LOAN = 'LOAN';
const LOCATION_FUNCTION_OTHER = 'OTHER';

const LOCATION_TYPE_CITY = 'CITY';
const LOCATION_TYPE_COUNTRY = 'COUNTRY';
const LOCATION_TYPE_INSTITUTION = 'INSTITUTION';
const LOCATION_TYPE_BUILDING = 'BUILDING';
const LOCATION_TYPE_ROOM = 'ROOM';
const LOCATION_TYPE_SHELF = 'SHELF';
const LOCATION_TYPE_BOX = 'BOX';

const USER_ROLE_MASTER = 'MASTER';
const USER_ROLE_ADMIN = 'ADMIN';
const USER_ROLE_GUEST = 'GUEST';
const USER_ROLE_EDITOR = 'EDITOR';

const PERMISSION_CREATE = 'CREATE';
const PERMISSION_DELETE = 'DELETE';
const PERMISSION_UPDATE = 'UPDATE';
const PERMISSION_EXPORT = 'EXPORT';
const PERMISSION_OWNER = 'OWNER';
const PERMISSION_READ = 'READ';
const PERMISSION_TYPE_LIMITED = 'LIMITED';
const PERMISSION_TYPE_FULL = 'FULL';

const RESOURCE_ALL = 'ALL';
const RESOURCE_AUTHOR = 'AUTHOR';
const RESOURCE_ARTWORK = 'ARTWORK';
const RESOURCE_COLLECTION = 'COLLECTION';
const RESOURCE_EXHIBITION = 'EXHIBITION';
const RESOURCE_IMAGE = 'IMAGE';
const RESOURCE_LOCATION = 'LOCATION';
const RESOURCE_PERMISSION = 'PERMISSION';
const RESOURCE_PUBLICATION = 'PUBLICATION';
const RESOURCE_USER = 'USER';

const resources = [
    { value: RESOURCE_ALL, label: 'Global Access' },
    { value: RESOURCE_AUTHOR, label: 'Artists' },
    { value: RESOURCE_ARTWORK, label: 'Artworks' },
    { value: RESOURCE_USER, label: 'Users' },
    { value: RESOURCE_EXHIBITION, label: 'Exhibitions' },
    { value: RESOURCE_PUBLICATION, label: 'Publications' },
    { value: RESOURCE_LOCATION, label: 'Locations' },
    { value: RESOURCE_COLLECTION, label: 'Collections' },
];

const disabledResources = [
    RESOURCE_COLLECTION,
    RESOURCE_EXHIBITION,
    RESOURCE_PUBLICATION,
    RESOURCE_LOCATION
];

const disabledGuestResources = [
    RESOURCE_ALL,
    RESOURCE_COLLECTION,
    RESOURCE_EXHIBITION,
    RESOURCE_PUBLICATION,
    RESOURCE_LOCATION,
    RESOURCE_USER
];

const permissions = [
    { value: PERMISSION_CREATE, label: 'Create' },
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_UPDATE, label: 'Update' },
    { value: PERMISSION_DELETE, label: 'Delete' },
    { value: PERMISSION_EXPORT, label: 'Export' },
];

const globalGuestPermissions = [
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const sharingPermissions = [
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_UPDATE, label: 'Update' },
    { value: PERMISSION_DELETE, label: 'Delete' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const sharingGuestPermissions = [
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const permissionsGuest = [
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const permissionsEditor = [
    { value: PERMISSION_CREATE, label: 'Create' },
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_UPDATE, label: 'Update' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const permissionsAdmin = [
    { value: PERMISSION_CREATE, label: 'Create' },
    { value: PERMISSION_READ, label: 'View' },
    { value: PERMISSION_UPDATE, label: 'Update' },
    { value: PERMISSION_DELETE, label: 'Delete' },
    { value: PERMISSION_EXPORT, label: 'Export' }
];

const permissionsByRole = {
    [USER_ROLE_MASTER]: permissionsAdmin,
    [USER_ROLE_ADMIN]: permissionsAdmin,
    [USER_ROLE_EDITOR]: permissionsEditor,
    [USER_ROLE_GUEST]: permissionsGuest,
};

const mediumList = [
    { value: 'painting', label: 'painting | malba' },
    { value: 'object', label: 'object | objekt' },
    { value: 'graphic', label: 'graphic | grafika' },
    { value: 'drawing', label: 'drawing | kresba' },
    { value: 'collage', label: 'collage | koláž' },
    { value: 'photograph', label: 'photograph | fotografie' },
    { value: 'book', label: 'book | kniha' },
    { value: 'video', label: 'video' },
    { value: 'poster', label: 'poster | plakát' },
    { value: 'woodboard', label: 'woodboard | překližka' },
    { value: 'ceramics', label: 'ceramics | keramika' },
    { value: 'other', label: 'other | jiné' }
];

const materialList = [
    { value: 'canvas', label: 'canvas | plátno' },
    { value: 'paper', label: 'paper | papír' },
    { value: 'wood', label: 'wood | dřevo' },
    { value: 'plastic', label: 'plastic | plast' },
    { value: 'glass', label: 'glass | sklo' },
    { value: 'rock', label: 'rock | kámen' },
    { value: 'metal', label: 'metal | kov' },
    { value: 'other', label: 'other | jiné' },
];

const techniqueList = [
    { value: 'oil', label: 'oil | olej' },
    { value: 'acrylic', label: 'acrylic | akryl' },
    { value: 'tempera', label: 'tempera' },
    { value: 'watercolor', label: 'watercolor | vodní barvy' },
    { value: 'lithography', label: 'lithography | litografie' },
    { value: 'screen print', label: 'screen print | sítotisk, serigrafie' },
    { value: 'pencil', label: 'pencil | tužka' },
    { value: 'ink', label: 'ink | tuž' },
    { value: 'graphite', label: 'graphite | úhel' },
    { value: 'combined technique', label: 'combined technique | kombinovaná technika' },
    { value: 'other', label: 'other | jiné' },
];

const conditionList = [
    { value: 'good', label: 'good condition | dobrý stav' },
    { value: 'at restorer', label: 'at restorer | u restaurátora' },
    { value: 'damaged', label: 'damaged | poškozené' },
    { value: 'restored', label: 'restored | restaurované' },
    { value: 'other', label: 'other | jiný stav' },
];

const adjustmentList = [
    { value: 'support', label: 'support | podložka' },
    { value: 'mount', label: 'mount | pasparta' },
    { value: 'case', label: 'case | obal' },
    { value: 'glass', label: 'glass | sklo' },
    { value: 'plexiglass', label: 'plexiglass | plexisklo' },
    { value: 'frame', label: 'frame | rám' },
    { value: 'stand', label: 'stand | stojánek' },
    { value: 'other', label: 'other | jiné' }
];

const signatureSidesList = [
    { value: 'front', label: 'front' },
    { value: 'back', label: 'back' },
    { value: 'other', label: 'other' },
];

const signatureLocationList = [
    { value: 'topLeft', label: 'Top Left' },
    { value: 'topCenter', label: 'Top Center' },
    { value: 'topRight', label: 'Top Right' },
    { value: 'centerLeft', label: 'Center Left' },
    { value: 'center', label: 'Center' },
    { value: 'centerRight', label: 'Center Right' },
    { value: 'bottomLeft', label: 'Bottom Left' },
    { value: 'bottomCenter', label: 'Bottom Center' },
    { value: 'bottomRight', label: 'Bottom Right' }
];

const authorTypesList = [
    { value: 'artist', label: 'Artist' },
    { value: 'photographer', label: 'Photographer' }
];

const searchOperations = [
    { value: 'eq', label: 'Equals' },
    { value: 'ne', label: 'Not Equals' },
    { value: 'gt', label: 'Greater Than' },
    { value: 'gte', label: 'Greater Than or Equals' },
    { value: 'lt', label: 'Less Than' },
    { value: 'lte', label: 'Less Than or Equals' },
    { value: 'contains', label: 'Contains' },
    { value: 'ncontains', label: 'Does Not Contain' },
    { value: 'empty', label: 'Is Empty' },
    { value: 'nempty', label: 'Is Not Empty' }
];

const brandName = 'Velfl Collection';

const navigationItems = [
    { name: 'Authors', path: '/people/list' },
    { name: 'Artworks', path: '/artworks/list' },
    { name: 'Exhibitions', path: '/exhibitions/list' },
    { name: 'Locations', path: '/locations/list' }
];

export {
    adjustmentList,
    ARTWORKS_CACHE_STORAGE_KEY,
    authorTypesList,
    BASE_URL,
    brandName,
    conditionList,
    disabledGuestResources,
    disabledResources,
    globalGuestPermissions,
    LOCATION_FUNCTION_DEPOSITARY,
    LOCATION_FUNCTION_LOAN,
    LOCATION_FUNCTION_OTHER,
    LOCATION_TYPE_BOX,
    LOCATION_TYPE_BUILDING,
    LOCATION_TYPE_CITY,
    LOCATION_TYPE_COUNTRY,
    LOCATION_TYPE_INSTITUTION,
    LOCATION_TYPE_ROOM,
    LOCATION_TYPE_SHELF,
    materialList,
    mediumList,
    navigationItems,
    PERMISSION_CREATE,
    PERMISSION_DELETE,
    PERMISSION_EXPORT,
    PERMISSION_OWNER,
    PERMISSION_READ,
    PERMISSION_TYPE_FULL,
    PERMISSION_TYPE_LIMITED,
    PERMISSION_UPDATE,
    permissions,
    permissionsAdmin,
    permissionsByRole,
    permissionsEditor,
    permissionsGuest,
    RESOURCE_ALL,
    RESOURCE_ARTWORK,
    RESOURCE_AUTHOR,
    RESOURCE_COLLECTION,
    RESOURCE_EXHIBITION,
    RESOURCE_IMAGE,
    RESOURCE_LOCATION,
    RESOURCE_PERMISSION,
    RESOURCE_PUBLICATION,
    RESOURCE_USER,
    resources,
    searchOperations,
    sharingGuestPermissions,
    sharingPermissions,
    signatureLocationList,
    signatureSidesList,
    techniqueList,
    USER_DATA_KEY,
    USER_LOCAL_STORAGE_KEY,
    USER_ROLE_ADMIN,
    USER_ROLE_EDITOR,
    USER_ROLE_GUEST,
    USER_ROLE_MASTER
}