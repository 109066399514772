import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Card, CardHeader, CardContent, Chip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PermissionList from '../parts/PermissionList';

import PermissionCard from '../parts/cards/user/PermissionCard';
import { USER_ROLE_ADMIN, USER_ROLE_MASTER } from '../../utils/constants';
import { useUserDetail } from '../../api/user';
import { useAuth } from '../../store/zustand';
import UpdateUserRoleModal from '../parts/modals/user/UpdateUserRoleModal';
import withUserRoleGuard from '../authentication/UserRoleGuard';


const Permissions: React.FC<any> = (props) => {
    const { userId } = useParams();
    const loggedInUser = useAuth(state => state.user);

    const { data: user } = useUserDetail(userId);

    const userEmail = _.get(user, 'email', '');
    const role = _.get(user, 'roleId');
    const roleType = _.get(role, 'roleTypeId');
    const roleName = _.get(roleType, 'roleName');
    const allowedResources: string[] = _.get(roleType, 'allowedResources', [])
    const allowedOperations: string[] = _.get(roleType, 'allowedOperations', [])
    const limitedPermissions = _.filter(_.get(role, 'permissionIds'), { limitedPermission: true });
    const fullPermissions = _.filter(_.get(role, 'permissionIds'), { limitedPermission: false });

        return (
            <>
                <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
                    
                    <Grid size={{ xs:12, md:4 }}>
                        <PermissionCard user={user} allowedOperations={allowedOperations} />
                    </Grid>

                    <Grid size={{ xs:12, md:8 }}>
                        <Card variant='grey' sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                            <CardHeader
                                title={<Typography variant="h4">User Role</Typography>}
                                action={<UpdateUserRoleModal buttonText='Update Role' userId={userId} dialogTitle='Update Role' defaultValues={{ roleName }} />}
                            />
                            <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                                <Typography variant="body1"><Chip size="small" color="primary" label={_.capitalize(roleName)} /> role allows users to {_.map(_.get(roleType, 'allowedOperations'), (permission: string) => <Chip size="small" color="primary" variant="outlined" sx={{ mx: '0.25rem' }} label={_.capitalize(permission)} />)}</Typography>
                                <Typography variant="body1">If you need <b>less permissions</b> for the user, <u>change permissions</u> below.</Typography>
                                <Typography variant="body1">If you need <b>more permissions</b> for the user, please <u>change the role type.</u></Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>

                <PermissionList></PermissionList>
            </>
        )
};

export default withUserRoleGuard([USER_ROLE_ADMIN, USER_ROLE_MASTER])(Permissions);