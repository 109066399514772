import _ from 'lodash';
import React from 'react';
import LocationFormModal from '../../modals/location/LocationFormModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_LOCATION } from '../../../../utils/constants';
import { useDeleteLocation, useLocations } from '../../../../api/location';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Stack, Chip, ListItem, List, ListItemText, Typography } from '@mui/material';
import { ILocation } from '../../../../../../../common/interfaces/IData';
import DeleteModal from '../../modals/DeleteModal';
import CustomListItemText from '../../CustomListItemText';
import { NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type Props = {
    location: ILocation;
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const LocationCard: React.FC<Props> = (props) => {
    const { location } = props;

    const deleteLocation = useDeleteLocation();

    const locationOptions = getOptionsWithLabel(location, { value: '_id', label: 'name' });

    return (
        <CustomCard
            title={{ title: 'Location' }}
            subtitle={_.get(location, 'name')}
            content={
                <List>
                        <ListItem>
                            <ListItemText key="Function" primary='Function' sx={{ flex: '0 0 auto' }} />
                            <Chip size='small' variant="outlined" label={_.toLower(_.get(location, 'function'))} sx={{ color: 'inherit', mx: 1 }} />
                        </ListItem>

                        <ListItem>
                            <ListItemText key="Type" primary='Type' sx={{ flex: '0 0 auto' }} />
                            <Chip size='small' variant="outlined" label={_.toLower(_.get(location, 'type'))} sx={{ color: 'inherit', mx: 1 }} />
                        </ListItem>

                        <ListItem>
                            <ListItemText key="Belongs to Locations" primary='Belongs to Locations' sx={{ flex: '0 0 auto' }} />
                            <Stack direction='row' spacing={1} sx={{ mx: 1 }}>
                                {
                                    _.map(_.get(location, 'belongsToLocationIds'), (belongsToLocationId) =>
                                        <Chip size='small' clickable variant="outlined" label={_.get(belongsToLocationId, 'name')} sx={{ color: 'inherit' }} component={NavLink} to={`/locations/${_.get(belongsToLocationId, '_id')}`} />
                                    )
                                }
                            </Stack>
                        </ListItem>

                        <ListItem>
                            <ListItemText key="Updated At" primary='Updated At' sx={{ flex: '0 0 auto' }} />
                            <Chip size='small' variant="outlined" label={dayjs(_.get(location, 'updatedAt')).format('MMMM D, YYYY h:mm A')} sx={{ color: 'inherit', mx: 1 }} />
                        </ListItem>
                </List>
            }
            footerActionsDisabled={true}
            headerActions={
                <SmallMenu>
                    <LocationFormModal action='update' locationId={_.get(location, '_id')} buttonFullWidth={true} buttonText='Update Location' defaultValues={location} dialogTitle='Update Location' />
                    <DeleteModal action='delete' actionFunction={deleteLocation} resourceTitle={_.get(location, 'name')} resourceDeletionData={{ locationId: _.get(location, '_id') }} buttonFullWidth={true} buttonText='Delete Location' dialogTitle='Delete Location' />
                    <ShareResourceWithUserModal
                        resource={RESOURCE_LOCATION}
                        resourceOptions={locationOptions}
                        resourceDisabled={true}
                        defaultValues={{ resourceId: locationOptions }}
                        buttonFullWidth={true}
                        buttonText='Share Location'
                        dialogTitle='Share Location'
                    />
                </SmallMenu>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_LOCATION])(LocationCard);