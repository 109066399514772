import _ from 'lodash';
import React from 'react';
import CustomCard from '../CustomCard';
import { RESOURCE_ARTWORK, RESOURCE_LOCATION } from '../../../../utils/constants';
import { useRemoveLocationFromResource } from '../../../../api/location';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Chip, Typography, TableContainer, Table, TableHead, TableRow, TableBody, Stack, Button } from '@mui/material';
import { IArtworkLocation, ILocation } from '../../../../../../../common/interfaces/IData';
import DeleteModal from '../../modals/DeleteModal';
import { Link, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';
import CustomTableCellCenter from '../../forms/inputs/CustomTableCellCenter';
import AddLocationToResourceFormModal from '../../modals/location/AddLocationToResourceFormModal';
import CustomClickableChip from '../../CustomClickableChip';
import CustomChip from '../../CustomChip';
import MakeCurrentLocationButton from '../../buttons/MakeCurrentLocationButton';

type Props = {
    locations: IArtworkLocation[];
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
    resource: string;
    resourceId: string;
}

const ArtworkLocationCard: React.FC<Props> = (props) => {
    const { locations } = props;

    const removeLocationFromResource = useRemoveLocationFromResource();

    const tableHead = ['Location', 'Name', 'Type', 'Function', 'Belongs to Locations', 'Date Added', 'Action'];

    // const locationOptions = getOptionsWithLabel(location, { value: '_id', label: 'name' });
    console.log(locations);
    return (
        <CustomCard
            title={{ title: 'Location' }}
            variant='grey'
            content={
                <>
                    <TableContainer className='table-responsive'>
                        <Table className='table align-middle' color="inherit">
                            <TableHead>
                                <TableRow sx={{ alignText: 'center' }}>
                                    {_.map(tableHead, (head: string) => (
                                        <CustomTableCellCenter key={head}>{head}</CustomTableCellCenter>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(locations, (location, index) => {
                                        const { locationId, currentLocation, updatedAt } = location ?? {};

                                        if (!locationId || _.isString(locationId)) return null;

                                        return (
                                            <TableRow key={index} sx={{ alignText: 'center' }} className='no-border-bottom table-hover'>
                                                <CustomTableCellCenter>
                                                    {
                                                        currentLocation &&
                                                        <Chip size='small' variant="filled" color="primary" label='Current Location' />
                                                    }
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <CustomClickableChip label={_.capitalize(_.get(locationId, 'name'))} linkTo={`/locations/${_.get(locationId, '_id')}`} />
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <CustomChip label={_.capitalize(_.get(locationId, 'function'))} />
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <Stack direction="row" spacing={1}>
                                                        {
                                                            _.isArray(_.get(locationId, 'belongsToLocationIds')) && _.every(_.get(locationId, 'belongsToLocationIds'), _.isObject) ?
                                                                _.map(locationId.belongsToLocationIds as ILocation[], (belongsToLocationId: ILocation, index: number) => (
                                                                    <CustomClickableChip key={`belongsToLocationIds-${index}`} label={_.get(belongsToLocationId, 'name')} linkTo={`/locations/${_.get(belongsToLocationId, '_id')}`} />
                                                                ))
                                                                :
                                                                null
                                                        }
                                                    </Stack>
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <Chip size='small' variant="outlined" label={dayjs(updatedAt).format('DD/MM/YYYY')} />
                                                </CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <SmallMenu
                                                        customMenuButtonIcon={<Typography variant="button">Update</Typography>}
                                                        customMenuIconButtonClasses={{ borderRadius: '4px' }}
                                                    >
                                                        <MakeCurrentLocationButton locationId={_.get(locationId, '_id')} resourceId={props.resourceId} />
                                                        <DeleteModal action='delete' actionFunction={removeLocationFromResource} resourceTitle={_.get(locationId, 'name')} resourceDeletionData={{ locationId: _.get(locationId, '_id'), resourceId: props.resourceId, resource: RESOURCE_ARTWORK }} buttonFullWidth={true} buttonText='Remove Location' dialogTitle='Remove Location From Artwork' />
                                                    </SmallMenu>
                                                </CustomTableCellCenter>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
            footerActionsDisabled={true}
            headerActions={
                <AddLocationToResourceFormModal buttonFullWidth={true} buttonText='Add Location' dialogTitle='Add Location' resource={props.resource} resourceId={props.resourceId} />
            }
        />
    )
}

export default withResourceGuard([RESOURCE_LOCATION])(ArtworkLocationCard);