import _ from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, Skeleton, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid2';
import HideImageIcon from '@mui/icons-material/HideImage';

import { getArtworkDimensions, getArtworkYear, getDistinctFilterOptionsAtKey, getInputValue, getFilteredDataFromSearch2 } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../../../utils/constants';

import { IArtwork } from '../../../../../../../common/interfaces/IData';
const ArtworkListImageView: React.FC<{ artworks: IArtwork[] }> = ({ artworks }) => {
    return (
      <Grid container spacing={2} sx={{ alignItems: 'flex-end', marginBottom: 2 }}>
        {_.map(artworks, (artwork: any) =>
          <Grid size={{ xs:12, md:4 }}>
            <Card variant='grey'>
              <CardActionArea component={Link} to={_.get(artwork, 'linkTo.path')}>
                {
                  _.get(artwork, 'image') ?
                    <CardMedia
                      component="img"
                      height="400"
                      src={_.get(artwork, 'image')}
                      alt={_.get(artwork, 'title')}
                    // style={{ objectFit: 'contain' }} use this if the images shouldn't be cropped
                    />
                    :
                    <Skeleton variant="rectangular" height={400} />
                }
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
    )
  }

  export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(ArtworkListImageView);