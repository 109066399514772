import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { removeEmptyValues, isAnyItemEmpty } from '../utils/helpers';
import { useAlert } from '../store/zustand';
import { IArtworkPreview, IArtwork } from '../../../../common/interfaces/IData';

type AuthorArtworkFilter = {
    authorId: string | undefined,
    artworkId: string | undefined
}

type AuthorFilter = {
    authorId: string | undefined
}

type ArtworkCreateResponse = {
    authorId: string,
    artworkId: string,
    [key: string]: any
}

type UpdateArtwork = {
    artworkId: string | undefined,
    artwork: IArtwork
}

type UpdateArtworkCurrentLocation = {
    artworkId: string | undefined,
    locationId: string | undefined
}

type DeleteArtwork = {
    authorId: string | undefined,
    artworkId: string | undefined,
    navigate: any | undefined
}

const createArtwork = async (createArtworkData: IArtwork | undefined): Promise<ArtworkCreateResponse> => {
    const axiosConfig = getAxiosConfig();
    const artworkCleanData = removeEmptyValues(createArtworkData);

    const createArtworkResponse: AxiosResponse<ArtworkCreateResponse> = await axios.post('/artwork/create',
        { artwork: artworkCleanData },
        axiosConfig
    );

    const authorId = _.get(createArtworkResponse, 'data.authorId');
    const artworkId = _.get(createArtworkResponse, 'data.artworkId');
    return { authorId, artworkId };
}

const getArtworkList = async (): Promise<IArtwork[]> => {
    const axiosConfig = getAxiosConfig();
    const { data }: AxiosResponse<IArtwork[]> = await axios.get('/artwork/list', axiosConfig);
    return data;
};

// move images elsewhere
const getArtworksByAuthorImages = async (payload: AuthorFilter): Promise<IArtworkPreview[]> => {
    const axiosConfig = getAxiosConfig();
    const { authorId } = payload;
    
    if (!authorId) {
        return [];
    }

    const { data }: { data: [IArtworkPreview] } = await axios.get(`/author/${authorId}/artworks/images`,
        axiosConfig
    );
    return data
}

const getArtworksByAuthorList = async (payload: AuthorFilter): Promise<IArtworkPreview[]> => {
    const axiosConfig = getAxiosConfig();
    const { authorId } = payload;

    if (!authorId) {
        return [];
    }

    const { data }: { data: [IArtworkPreview] } = await axios.get(`/author/${authorId}/artworks/list`,
        axiosConfig
    );
    return data
}

const getArtwork = async (payload: AuthorArtworkFilter): Promise<IArtwork> => {
    const axiosConfig = getAxiosConfig();
    const { authorId, artworkId } = payload;

    if (!authorId || !artworkId) {
        return {} as IArtwork;
    }

    const { data }: { data: IArtwork } = await axios.get(`/author/${authorId}/artwork/${artworkId}`,
        axiosConfig
    );
    return data
}

const updateArtwork = async (payload: UpdateArtwork): Promise<void> => {
    const axiosConfig = getAxiosConfig();

    const artworkCleanData = removeEmptyValues(payload);

    await axios.put(`/artwork/update/${_.get(artworkCleanData, '_id')}`,
        { artwork: artworkCleanData },
        axiosConfig
    );
}

const updateArtworkCurrentLocation = async (payload: UpdateArtworkCurrentLocation): Promise<void> => {
    const axiosConfig = getAxiosConfig();

    const { artworkId, locationId } = removeEmptyValues(payload);
    console.log('updateArtworkCurrentLocation');
    await axios.put(`/artwork/${artworkId}/current/location/${locationId}`, 
        {},
        axiosConfig
    );
}

const deleteArtwork = async (payload: DeleteArtwork): Promise<void> => {
    const axiosConfig = getAxiosConfig();

    const { authorId, artworkId, navigate } = payload;

    await axios.delete(`/artwork/delete/${artworkId}`,
        axiosConfig
    );
    navigate(`/people/${authorId}`);
}


export const useCreateArtwork = () => {
    const apiRequestPromise = useApiRequestPromise();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (createArtworkData: IArtwork) => apiRequestPromise.handleApiRequestPromise(createArtwork, createArtworkData),
        onSuccess: () => {
            useAlert.getState().addAlert({ message: 'Artwork created successfully', severity: 'success' });
            queryClient.invalidateQueries({ queryKey: ['artworks'] });
            queryClient.invalidateQueries({ queryKey: ['authors'] })
        }
    })
}

export const useArtworksList = () => {
    const apiRequestPromise = useApiRequestPromise();
    return useQuery({
        queryKey: ["artworks", "list"],
        queryFn: async () => apiRequestPromise.handleApiRequestPromise(getArtworkList),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: 5 * 60 * 1000,
    });
}

export const useArtworksByAuthorImages = (filter: AuthorFilter) => {
    const apiRequestPromise = useApiRequestPromise();
    return useQuery({
        queryKey: ["artworks", "list", "images", filter],
        queryFn: async () => apiRequestPromise.handleApiRequestPromise(getArtworksByAuthorImages, filter),
        // enabled: !isAnyItemEmpty(filter),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: 5 * 60 * 1000,
    });
}

export const useArtworksByAuthorList = (filter: AuthorFilter) => {
    const apiRequestPromise = useApiRequestPromise();
    return useQuery({
        queryKey: ["artworks", "list", filter],
        queryFn: async () => apiRequestPromise.handleApiRequestPromise(getArtworksByAuthorList, filter),
        // enabled: !isAnyItemEmpty(filter),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: 5 * 60 * 1000,
    });
}

export const useArtworkDetail = (filter: AuthorArtworkFilter) => {
    const apiRequestPromise = useApiRequestPromise();
    return useQuery({
        queryKey: ["artworks", "detail", filter],
        queryFn: async () => apiRequestPromise.handleApiRequestPromise(getArtwork, filter),
        // enabled: !isAnyItemEmpty(filter),
        placeholderData: (previousData, previousQuery) => previousData,
        staleTime: 5 * 60 * 1000,
    });
}

export const useUpdateArtwork = () => {
    const queryClient = useQueryClient();
    const apiRequestPromise = useApiRequestPromise();
    return useMutation({
        mutationFn: (updateArtworkData: UpdateArtwork) => apiRequestPromise.handleApiRequestPromise(updateArtwork, updateArtworkData),
        onSuccess: () => {
            useAlert.getState().addAlert({ message: 'Artwork updated successfully', severity: 'success' });
            queryClient.invalidateQueries({ queryKey: ['artworks'] })
        }
    })
}

export const useUpdateArtworkCurrentLocation = () => {
    const queryClient = useQueryClient();
    const apiRequestPromise = useApiRequestPromise();
    return useMutation({
        mutationFn: (data: UpdateArtworkCurrentLocation) => apiRequestPromise.handleApiRequestPromise(updateArtworkCurrentLocation, data),
        onSuccess: () => {
            useAlert.getState().addAlert({ message: 'Artwork location updated successfully', severity: 'success' });
            queryClient.invalidateQueries({ queryKey: ['artworks'] })
        }
    })
}

export const useDeleteArtwork = () => {
    const queryClient = useQueryClient();
    const apiRequestPromise = useApiRequestPromise();
    return useMutation({
        mutationFn: (deleteArtworkData: DeleteArtwork) => apiRequestPromise.handleApiRequestPromise(deleteArtwork, deleteArtworkData),
        onSuccess: () => {
            useAlert.getState().addAlert({ message: 'Artwork deleted successfully', severity: 'success' });
            queryClient.invalidateQueries({ queryKey: ['artworks'] })
        }
    })
}

// use this to refetch after success
// queryClient.invalidateQueries({ queryKey: ['inventory'] })
