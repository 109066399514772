import _ from 'lodash';
import React from 'react';
import AuthorFormModal from '../../modals/author/AuthorFormModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_AUTHOR } from '../../../../utils/constants';
import { useAuthors } from '../../../../api/people';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Typography } from '@mui/material';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type AuthorsCardProps = {
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const AuthorsCard = (props: AuthorsCardProps) => {
    const { data: authors } = useAuthors();
    const authorOptions = getOptionsWithLabel(authors, { value: '_id', label: 'name' });

    return (
        <CustomCard
            title={{ title: 'Authors', linkTo: '/people/list' }}
            content={<Typography variant='body1'><i>the collection has {_.size(authors)} authors</i></Typography>}
            headerActionsDisabled={props.headerActionsDisabled}
            headerActions={
                <SmallMenu>
                <ShareResourceWithUserModal
                    resource={RESOURCE_AUTHOR}
                    resourceOptions={authorOptions}
                    buttonFullWidth={true}
                    buttonText='Share Authors'
                    dialogTitle='Share Authors'
                />
                </SmallMenu>
            }
            footerActionsDisabled={props.footerActionsDisabled}
            footerActionLink={
                <AuthorFormModal action='create' buttonClassName='align-self-start' buttonFullWidth={false} buttonText='Create Author' dialogTitle='Create Author'></AuthorFormModal>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_AUTHOR])(AuthorsCard);