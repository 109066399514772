import React, { useState } from 'react';
import { Button, Menu, MenuList, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { set } from 'lodash';

type SmallMenuProps = {
    children: React.ReactNode;
    customMenuButtonIcon?: React.ReactNode;
    customMenuButtonText?: string;
    customMenuIconButtonClasses?: any;
    customMenuButtonProps?: any;
}

type SmallMenuIconProps = {
    isMenuOpen: boolean;
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void;
    customMenuButtonIcon?: React.ReactNode;
    customMenuIconButtonClasses?: any;
    customMenuButtonText?: string;
    customMenuButtonProps?: any;
};

type MenuPopoverProps = {
    isMenuOpen: boolean;
    anchorEl: HTMLElement | null;
    handleCloseMenu: () => void;
    children: React.ReactNode;
};

// Custom hook for managing SmallMenu's open/close state
const useSmallMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    return {
        isMenuOpen,
        anchorEl,
        handleOpenMenu,
        handleCloseMenu,
    };
};

const SmallMenuIcon: React.FC<SmallMenuIconProps> = ({
    customMenuButtonIcon,
    isMenuOpen,
    handleOpenMenu,
    customMenuIconButtonClasses,
    customMenuButtonText,
    customMenuButtonProps
}) => {
    const icon = customMenuButtonIcon || <MenuIcon sx={{ fontSize: 35 }} />;
    return (
        <Button
            aria-label="small-menu-button"
            variant="text"
            color="inherit"
            aria-haspopup="true"
            aria-expanded={isMenuOpen}
            onClick={handleOpenMenu}
            size="large"
            sx={{ ...customMenuIconButtonClasses }}
            {...customMenuButtonProps}
        >
            {icon}
            {customMenuButtonText}
        </Button>
    );
};

const SmallMenu: React.FC<SmallMenuProps> = ({
    children,
    customMenuButtonIcon,
    customMenuIconButtonClasses,
    customMenuButtonText,
    customMenuButtonProps,
}) => {
    const { isMenuOpen, anchorEl, handleOpenMenu, handleCloseMenu } = useSmallMenu();

    return (
        <>
            <SmallMenuIcon
                isMenuOpen={isMenuOpen}
                handleOpenMenu={handleOpenMenu}
                customMenuButtonIcon={customMenuButtonIcon}
                customMenuIconButtonClasses={customMenuIconButtonClasses}
                customMenuButtonText={customMenuButtonText}
                customMenuButtonProps={customMenuButtonProps}
            />
            <Menu
                open={isMenuOpen}
                anchorEl={anchorEl}
                keepMounted
                onBlur={handleCloseMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    style: {
                        width: '300px',
                        maxWidth: '100%',
                        padding: 0,
                    },
                }}
            >
                <MenuList disablePadding>
                    {children}
                </MenuList>
            </Menu>
        </>
    );
};

export { SmallMenu, useSmallMenu };