import _ from 'lodash';
import React from 'react';
import { Stack, TextField, Card, CardContent } from '@mui/material';
import { useForm } from 'react-hook-form';

type SearchCardProps = {
  searchFunction: any,
  sx?: object
}

const SearchCard: React.FC<SearchCardProps> = ({ searchFunction, sx }) => {

  return (
    <Card variant='grey' sx={sx}>
      <CardContent sx={{ marginTop: 'auto' }}>
        <TextField
          sx={{
            '& .MuiInputLabel-root': {
              color: 'inherit',
              fontSize: '1.2rem',
              lineHeight: '1.5rem',
              fontWeight: 300,
            },
            '& .MuiInput-underline:before': {
              borderBottom: '1px solid black', // Add a 2px black solid bottom border on focus
            },
            '& .MuiInput-root': {
              paddingBottom: 1
            }
          }}
          name='Search' label='Search Anything' type='text' variant='standard' fullWidth onChange={(e: any) => searchFunction(e.target.value)}></TextField>
      </CardContent>
    </Card>
  )
}

export default SearchCard;
