import _ from 'lodash';
import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import ImageForm from '../../forms/image/ImageForm';
import FormModal from '../FormModal';
import { useUploadImages } from '../../../../api/image';
import Progress from '../../Progress';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_CREATE } from '../../../../utils/constants';

interface FileUploadProps {
  authorId: string | undefined,
  artworkId: string | undefined,
  fileType: string,
  buttonClassName?: string,
  dialogTitle: string,
  buttonText: string,
  buttonFullWidth?: boolean
}

const FileUploadFormModal = (props: FileUploadProps) => {
  const [open, setOpen] = React.useState(false);

  const { control, getValues, reset, handleSubmit, formState: { isSubmitting } } = useForm();

  const createImages = useUploadImages();

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    // this function closes the modal and resets the form
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    const values = getValues();
    const { images, photographer } = values;
    
    const uploadData = {
        authorId: props.authorId,
        artworkId: props.artworkId,
        photographerId: photographer.value,
        uploadData: images
    };

    const imageIds = await createImages.mutateAsync(uploadData);
    console.log(imageIds);
    // TODO: remove imageIds from view and if the upload is not complete, give the user the option to retry

    handleClose();
  }

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ImageForm control={control} />
          <Progress className='mt-2 mb-2' />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE])(FileUploadFormModal);