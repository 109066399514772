import _ from 'lodash';
import React from 'react';
import { Button, Stack } from '@mui/material';

import FormModal from '../FormModal';
import CustomTextField from '../../forms/inputs/CustomTextField';
import { useForm, useWatch } from 'react-hook-form';
import { useUpdateUserRoleType } from '../../../../api/role';
import { getUpdatedFormFields } from '../../../../utils/helpers';
import { UserFormRole } from '../../forms/user/UserFormRole';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_USER } from '../../../../utils/constants';

interface UpdateUserRoleModalProps {
  userId: string | undefined,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  dialogTitle: string,
  defaultValues?: any
}

type UpdateUserRoleFormValues = {
  roleName: string | undefined;
}

const UpdateUserRoleModal = (props: UpdateUserRoleModalProps) => {
  const [open, setOpen] = React.useState(false);

  const updateUserRoleType = useUpdateUserRoleType();
  
  const { control, getValues, reset, handleSubmit, formState: { errors, isSubmitting, dirtyFields } } = useForm<UpdateUserRoleFormValues>();

  const handleOpen = () => {
    reset(props.defaultValues);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    if (_.isEmpty(errors)) {
      const { roleName } = getValues();
      await updateUserRoleType.mutateAsync({ userId: props.userId, roleName });
      handleClose();
    }
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserFormRole control={control} />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Button color="inherit" onClick={handleClose}>Cancel</Button>
          <Button type='submit' variant='contained' disabled={!_.isEmpty(errors)}>{props.buttonText}</Button>
        </Stack>
      </form>
    </FormModal>
  );
};

export default withResourceGuard([RESOURCE_USER])(UpdateUserRoleModal);