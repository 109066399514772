import _ from 'lodash';
import React from 'react';
import pMap from 'p-map';
import { useFieldArray, Control } from 'react-hook-form';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import { convertImage } from '../../../../api/image';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

type CustomImageUploadProps = {
    name: string;
    label?: string;
    rules?: any;
    control: Control<any>;
}


const CustomImageUpload = ({ name, rules, control }: CustomImageUploadProps) => {
  
    const { fields: fieldsArray, append } = useFieldArray({
        name,
        control,
        rules
    });

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        fieldsArray.pop();

        const files = _.get(e, 'target.files', []);

        await pMap(files, async (file: any) => {
            let result;
            if (
              _.includes(
                ['image/png', 'image/jpeg', 'image/bmp', 'image/tiff', 'image/gif'],
                _.get(file, 'type')
              )
            ) {
              const convertedImage: any = await convertImage(file);
              result = {
                filename: _.get(file, 'name'),
                size: _.get(file, 'size'),
                content: file,
                lastModifiedDate: _.get(file, 'lastModified'),
                thumbnailBase64: convertedImage,
                type: _.get(file, 'type'),
                hasThumbnail: true
              }
            } else {
              result = {
                filename: _.get(file, 'name'),
                size: _.get(file, 'size'),
                content: file,
                lastModifiedDate: _.get(file, 'lastModified'),
                type: _.get(file, 'type'),
                hasThumbnail: false,
              }
            }
            append(result);
          });
    }

    return (
        <Button component="label" variant="outlined" startIcon={<CloudUploadIcon />}>
            Upload images
            <VisuallyHiddenInput type="file" onChange={handleFileChange} multiple />
        </Button>
    );
}

export default CustomImageUpload;