import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import Resizer from 'react-image-file-resizer';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import pMap from 'p-map';
import { useProgress, useAlert } from '../store/zustand';

import { ICreateImageResponse, ICreateFile, IFile } from '../../../../common/interfaces/IData';

type UpdateImage = {
  imageId: string | undefined,
  updateData: {
    description?: string | undefined,
    photographerId?: string | undefined  
  }
}

type DownloadImage = {
  imageId: string | undefined,
  filename: string
}

type UploadImages = {
  authorId: string | undefined,
  artworkId: string | undefined,
  photographerId: string | undefined,
  uploadData: ICreateFile
}

type DeleteImage = {
  imageId: string | undefined
}

export const convertImage = async (file: any) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      2000,
      500,
      'JPEG',
      70,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  })
}

export const uploadImages = async (uploadImagesData: UploadImages): Promise<ICreateImageResponse[] | undefined> => {
  const axiosConfig = getAxiosConfig();
  const { authorId, artworkId, photographerId, uploadData } = uploadImagesData;

  const imageDescription = _.get(uploadData, 'description', null);

  const imageCount = _.size(_.get(uploadData, 'files'));

  const imageIds = await pMap(uploadData.files, async (image: IFile, index: number) => {
    useAlert.getState().addAlert({ message: `Uploading ${index + 1} out of ${imageCount} images.`, severity: 'info' });

    try {
      // get presigned URL from backend to upload directly to AWS S3
      const imageUploadUrlData = await axios.post('/image/upload/url',
        { artistId: authorId, artworkId },
        axiosConfig
      );

      const imageUploadUrl = _.get(imageUploadUrlData, 'data.url');

      // Config for AWS S3 Upload
      const imageKey = `${authorId}/${artworkId}/${_.get(image, 'filename')}`;
      const formData = new FormData();
      const fields = _.get(imageUploadUrlData, 'data.fields');
      _.map(fields, (fieldValue, field) => formData.append(field, fieldValue));
      formData.append('x-amz-acl', 'private');
      formData.append('Key', imageKey);
      formData.append('file', _.get(image, 'content'));

      // Upload image to S3
      await axios.post(imageUploadUrl, formData, {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const progress = Math.round(loaded / total * 100);
          useProgress.getState().setProgress(progress);
        },
        timeout: 500000
      });

      // Config for database
    const createImageData = {
      artistId: authorId,
      artworkId,
      photographerId,
      ...(imageDescription ? { description: imageDescription } : {}),
      filename: _.get(image, 'filename'),
      size: _.get(image, 'size'),
      lastModifiedDate: _.get(image, 'lastModifiedDate'),
      thumbnailBase64: _.get(image, 'thumbnailBase64'),
      type: _.get(image, 'type'),
      hasThumbnail: _.get(image, 'hasThumbnail'),
      key: imageKey
    };

      // save image to database
      const { data: imageId}: {data: ICreateImageResponse} = await axios.post('/image/create', { image: createImageData }, axiosConfig);
      
      useAlert.getState().addAlert({ message: `Image ${_.get(image, 'filename')} was uploaded successfully!`, severity: 'success' });
      return imageId;
    } catch (e) {
      const errorMessage = _.get(e, 'response.data') ? _.get(e, 'response.data') : _.get(e, 'message');
      
      useAlert.getState().addAlert({ message: `Image ${_.get(image, 'filename')} upload failed: ${errorMessage}`, severity: 'error' });
      console.log(errorMessage);
    }
  }, { concurrency: 1 });

  // reset progress once finished
  useProgress.getState().setProgress(0);

  return _.compact(imageIds);
}

export const downloadImage = async (payload: DownloadImage): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { imageId, filename } = payload;
  const { data: url } = await axios.get(`/image/export/${imageId}`, axiosConfig);
  console.log(url);
  return await axios({
    url,
    method: 'GET',
    responseType: 'blob'
  }).then(result => {
    const url = window.URL.createObjectURL(new Blob([result.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });
}

const deleteImage = async (payload: DeleteImage): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { imageId } = payload;

  return await axios.delete(`/image/${imageId}`, axiosConfig);
}

const updateImage = async (payload: UpdateImage): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { imageId, updateData } = payload;

  return await axios.put(`/image/${imageId}`, { image: updateData }, axiosConfig);
}

export const useUploadImages = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (uploadImagesData: UploadImages) => apiRequestPromise.handleApiRequestPromise(uploadImages, uploadImagesData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artworks'] });
      queryClient.invalidateQueries({ queryKey: ['images'] })
    }
  })
}

export const useDeleteImage = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (deleteImageData: DeleteImage) => apiRequestPromise.handleApiRequestPromise(deleteImage, deleteImageData),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'Image deleted successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['images'] });
      queryClient.invalidateQueries({ queryKey: ['artworks'] }); // TODO: remove this inefficient reloading after images are being reloaded on their own
    }
  })
}

export const useUpdateImage = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateImageData: UpdateImage) => apiRequestPromise.handleApiRequestPromise(updateImage, updateImageData),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'Image updated successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['images'] });
      queryClient.invalidateQueries({ queryKey: ['artworks'] }); // TODO: remove this inefficient reloading after images are being reloaded on their own
    }
  })
}

// use this to refetch after success
// queryClient.invalidateQueries({ queryKey: ['inventory'] })
