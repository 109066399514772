import _ from 'lodash';
import React, { useState } from 'react';

const PaintSplashIcon: React.FC = () => {
      const [greenCircle, setGreenCircle] = useState([35, 28, 10]);
      const [yellowCircle, setYellowCircle] = useState([40, 16, 12]);
      const [redCircle, setRedCircle] = useState([47, 28, 14]);


    const randomPlusMinus = (a: number, b: number) => (_.random(1) === 0 ? _.add(a, b) : _.subtract(a, b));

    const generate3RandomNumbers = (x: number, y: number, r: number) => {
        const max = 50;
        const random1 = randomPlusMinus(max - x, _.random(6));
        const random2 = randomPlusMinus(max - y, _.random(6));
        const random3 = randomPlusMinus(r, _.random(2));
        return [random1, random2, random3];
    };

    const handleHover = () => {
        setGreenCircle(generate3RandomNumbers(greenCircle[0], greenCircle[1], greenCircle[2]));
        setYellowCircle(generate3RandomNumbers(yellowCircle[0], yellowCircle[1], yellowCircle[2]));
        setRedCircle(generate3RandomNumbers(redCircle[0], redCircle[1], redCircle[2]));
    };

    const handleHoverExit = () => {
        setGreenCircle([35, 28, 10]);
        setYellowCircle([40, 16, 12]);
        setRedCircle([47, 28, 14]);
    };

    const circleStyle = {
        transition: 'cx 0.3s ease, cy 0.3s ease, r 0.3s ease',
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="none"
            overflow='visible'
            onMouseEnter={handleHover}
            onMouseLeave={handleHoverExit}
        >
            <circle style={circleStyle} cx={greenCircle[0]} cy={greenCircle[1]} r={greenCircle[2]} fill="#007300" />
            <circle style={circleStyle} cx={yellowCircle[0]} cy={yellowCircle[1]} r={yellowCircle[2]} fill="#FFCC5C" />
            <circle style={circleStyle} cx={redCircle[0]} cy={redCircle[1]} r={redCircle[2]} fill="#e5635e" />
        </svg>
    );
};

export default PaintSplashIcon;
