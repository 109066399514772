import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import ExhibitionForm from '../../forms/exhibition/ExhibitionForm';
import FormModal from '../FormModal';
import { useCreateExhibition, useUpdateExhibition } from '../../../../api/exhibition';

import { IExhibition } from '../../../../../../../common/interfaces/IData';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';
import { getUpdatedFormFields } from '../../../../utils/helpers';

interface ExhibitionFormProps {
  action: string,
  exhibitionId: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any
  dialogTitle: string,
}

const ExhibitionFormModal = (props: ExhibitionFormProps) => {
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, getValues, control, reset, formState: { isSubmitting, dirtyFields } } = useForm<IExhibition>();

  const createExhibition = useCreateExhibition();
  const updateExhibition = useUpdateExhibition();

  const handleOpen = () => {
    reset(props.defaultValues);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    const values = getValues();
    
    const changedData = getUpdatedFormFields(values, dirtyFields) as IExhibition;

    if (props.action === 'create') {
      await createExhibition.mutateAsync(changedData);
    }

    if (props.action === 'update') {
      await updateExhibition.mutateAsync({ exhibitionId: props.exhibitionId, exhibition: changedData});
    }

    handleClose();
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullScreen={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ExhibitionForm control={control} />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )}
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(ExhibitionFormModal);