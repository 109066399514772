import _ from 'lodash';
import React, { ReactInstance, useRef, forwardRef, ForwardedRef } from 'react';
import { PERMISSION_EXPORT } from '../../../utils/constants';
import withPermissionGuard from '../../authentication/PermissionGuard';
import { GridApiCommon, GridToolbarExportProps, GridCsvExportMenuItem, GridCsvExportOptions } from '@mui/x-data-grid';
import { Box, Menu, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { SmallMenu, useSmallMenu } from '../SmallMenu';
import { SaveAlt } from '@mui/icons-material';
import { json2csv } from 'json-2-csv';
import { useReactToPrint } from 'react-to-print';
import { useTheme, makeStyles } from '@mui/material/styles';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

type Props = {
  apiRef: React.MutableRefObject<GridApiCommon>;
}

type TableToPrintProps = {
  tableData: any[];
}

const TableToPrint = forwardRef((props: TableToPrintProps, ref: ForwardedRef<any>) => {
  const { tableData } = props;
  const keys = _.keysIn(_.first(props.tableData));

  return (
    <Table ref={ref} sx={{ width: '100%', overflow: 'visible', height: 'fit-content' }}>
      <TableHead>
        <TableRow>
          {_.map(keys, (column) => (
            <TableCell key={column}>{column}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {_.map(tableData, (row, index) => (
          <TableRow key={index}>
            {_.map(keys, (column) => {
              if (column === 'image') {
                return (
                  <TableCell key={column}>
                    <Box sx={{ width: '90%', height: '90%', maxHeight: '200px', display: 'flex', justifyContent: 'center' }}>
                      <img src={row[column]} alt={column} style={{ objectFit: 'contain', borderRadius: '4px' }} />
                    </Box>
                  </TableCell>
                )
              } else {
                return (
                  <TableCell key={column}>{row[column]}</TableCell>
                )
              }
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

const ExportButtonMuiGrid: React.FC<Props> = (props) => {
  const contentToPrint = useRef(null);
  const [tableData, setTableData] = React.useState<any[]>([]);

  const handleSetData = async () => {
    const selectedData = !_.isEmpty(props.apiRef.current.getSelectedRows()) ? Array.from(props.apiRef.current.getSelectedRows().values()) : props.apiRef.current.getSortedRows();
    const visibleColumns = _.without(_.map(props.apiRef.current.getVisibleColumns(), 'field'), '__check__', 'linkTo');
    const filteredData = _.map(selectedData, (data) => _.pick(data, visibleColumns));
    setTableData(filteredData);
    if (_.size(filteredData) === _.size(tableData)) {
      return Promise.resolve();
    }
  }

  const reactToPrint = useReactToPrint({
    documentTitle: `Velfl Collection Export - ${dayjs().format('DD.MM.YYYY')}`,
    contentRef: contentToPrint,
  });

  const handlePrint = async () => {
    await handleSetData();
    reactToPrint();
  }

  const handleCsvExport = async () => {
    await handleSetData();
    const csv = await json2csv(tableData, { delimiter: { field: ';' }, useLocaleFormat: true, escapeHeaderNestedDots: false, excelBOM: true });
    saveAs(new Blob([csv], { type: "text/csv;charset=utf-8" }), 'velflcollection-export.csv');
  }

  return (
    <>
      <Box style={{ display: 'none', overflow: 'visible', height: 'fit-content' }}>
        <TableToPrint tableData={tableData} ref={contentToPrint} />
      </Box>
      <SmallMenu customMenuButtonIcon={<SaveAlt sx={{ mr: 0.5 }} />} customMenuButtonText='Export' customMenuButtonProps={{ size: 'small' }}>
        <MenuItem onClick={handleCsvExport}>Export to Excel</MenuItem>
        <MenuItem onClick={handlePrint}>Print</MenuItem>
      </SmallMenu>
    </>
  )
}

export default withPermissionGuard([PERMISSION_EXPORT])(ExportButtonMuiGrid);