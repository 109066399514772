import _ from 'lodash';
import React from 'react';
import AuthorFormSelect from '../author/AuthorFormSelect';
import CustomImageUpload from '../inputs/CustomImageUpload';
import CustomTextField from '../inputs/CustomTextField';
import { useWatch, Control } from 'react-hook-form';
import { Card, CardHeader, CardContent, Stack, ImageList, ImageListItem, Typography } from '@mui/material';

type ImageFormProps = {
  control: Control<any>;
}

const ImageThumbnails = (images: any) => (
  <ImageList cols={3} rowHeight={120}>
    {
      _.map(images, image => {
        if (image) {
          return (
            <ImageListItem key={_.get(image, 'filename')}>
              <img
                src={_.get(image, 'thumbnailBase64')}
                id={_.get(image, 'filename')}
                alt={_.get(image, 'filename')}
                loading="lazy"
                style={{ width: 'auto', height: '80%' }}
              />
              <Typography variant='caption'>{_.get(image, 'filename')}</Typography>
            </ImageListItem>
          )
        }
      })
    }
  </ImageList>
)

const ImageForm = ({ control }: ImageFormProps) => {
  const images = useWatch({ name: `${_.join(['images', 'files'], '.')}`, control });

  return (
    <Card className='my-3'>
      <CardHeader
        action={<CustomImageUpload control={control} name={_.join(['images', 'files'], '.')} />}
      />
      <CardContent>
        <>
          {images && ImageThumbnails(images)}
          <Stack spacing={2}>
            <AuthorFormSelect name='photographer' control={control} type='photographer'></AuthorFormSelect>
            <CustomTextField
              name={_.join(['images', 'description'], '.')}
              label='image description'
              variant='standard'
              control={control}
              fullWidth
            />
          </Stack>
        </>
      </CardContent>
    </Card>
  )

};

export default ImageForm;