import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState, memo } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@mui/material/Grid2';

import { getArtworkDimensions, getArtworkYear, getDistinctFilterOptionsAtKey, getFilteredDataFromSearch2, getInputValue, getNormalizedString, getObjectWithoutEmptyValues, prepareSearchDataOptions } from '../../utils/helpers';

import { PERMISSION_EXPORT, RESOURCE_ARTWORK, materialList, mediumList, techniqueList } from '../../utils/constants';
import ArtworksCard from '../parts/cards/artwork/ArtworksCard';
import FulltextSearchCard from '../parts/cards/FulltextSearchCard';

import { useArtworksList } from '../../api/artwork';
import withResourceGuard from '../authentication/ResourceGuard';
import { useForm, useWatch } from 'react-hook-form';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ExportButtonMuiGrid from '../parts/buttons/ExportButtonMuiGrid';
import CustomDataGrid from '../parts/CustomDataGrid';

const omitFields = ['adjustment', 'authorId', 'tagIds', 'publicationIds', 'exhibitionIds', 'private', '__v'];

const ArtworkList = (props: any) => {
  const { data: artworks } = useArtworksList();

  const artworkData = useMemo(() => _.map(artworks, (artwork) => {
    const artworkData = _.omit(artwork, omitFields);
    return {
      _id: _.get(artwork, '_id'),
      author: _.get(artwork, 'authorId.name'),
      title: _.get(artwork, 'title'),
      ...artworkData,
      linkTo: {
        label: 'View Artwork',
        path: `/people/${_.get(artwork, 'authorId._id')}/artworks/${_.get(artwork, '_id')}`
      }
    }
  }), [artworks]);

  const distinctFilterOptionsAtKey = getDistinctFilterOptionsAtKey({ data: artworkData, excludedFieldsArray: ['linkTo', 'image', '_id', 'createdAt', 'updatedAt'] });
  const { control } = useForm();

  const search = useWatch({ control, name: 'search' });
  const searchFilters = useWatch({ control, name: 'searchFilter' });

  const areAllFiltersValid = (filters: any[]): boolean => _.every(filters, (filter) => filter && filter.field && filter.operation);

  const filteredData = useMemo(() => {
    if (areAllFiltersValid(searchFilters)) {
      return getFilteredDataFromSearch2(artworkData, search, searchFilters);
    } else {
      return artworkData;
    }
  }, [artworkData, search, searchFilters]);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4}}>
          <ArtworksCard footerActionsDisabled={true} />
        </Grid>
        <Grid size={{ xs:12, md:8}}>
          <FulltextSearchCard control={control} options={distinctFilterOptionsAtKey} />
        </Grid>
      </Grid>

      <CustomDataGrid data={filteredData} density='comfortable' />
    </>
  )
}

export default withResourceGuard([RESOURCE_ARTWORK])(ArtworkList);