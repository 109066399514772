import React from 'react';
import { Control } from 'react-hook-form';

import CustomAutocomplete from '../inputs/CustomAutocomplete';

import { useLocations } from '../../../../api/location';
import { getOptionsWithLabel } from '../../../../utils/helpers';

type AddLocationFormProps = {
  control: Control<any>;
}

const AddLocationForm = ({ control }: AddLocationFormProps) => {
  const { data: locations } = useLocations();

  const locationOptions = getOptionsWithLabel(locations, { value: '_id', label: ['name', 'type', 'function'] });

  return (
      <CustomAutocomplete
        name='locationId'
        label='Location'
        control={control}
        options={locationOptions}
      />
  )
};


export default AddLocationForm;