import _ from 'lodash';
import React from 'react';
import { Stack } from '@mui/material';
import { Control, useWatch } from 'react-hook-form';

import CustomTextField from '../inputs/CustomTextField';
import { 
  LOCATION_FUNCTION_DEPOSITARY,
  LOCATION_FUNCTION_LOAN,
  LOCATION_FUNCTION_OTHER,
  LOCATION_TYPE_CITY,
  LOCATION_TYPE_COUNTRY,
  LOCATION_TYPE_INSTITUTION,
  LOCATION_TYPE_BUILDING,
  LOCATION_TYPE_ROOM,
  LOCATION_TYPE_SHELF,
  LOCATION_TYPE_BOX
} from '../../../../utils/constants';
import CustomAutocomplete from '../inputs/CustomAutocomplete';

import { useLocations } from '../../../../api/location';
import { getOptionsWithLabel } from '../../../../utils/helpers';

const locationTypes = [
  { value: LOCATION_TYPE_COUNTRY, label: _.capitalize(LOCATION_TYPE_COUNTRY) },
  { value: LOCATION_TYPE_CITY, label: _.capitalize(LOCATION_TYPE_CITY) },
  { value: LOCATION_TYPE_INSTITUTION, label: _.capitalize(LOCATION_TYPE_INSTITUTION) },
  { value: LOCATION_TYPE_BUILDING, label: _.capitalize(LOCATION_TYPE_BUILDING) },
  { value: LOCATION_TYPE_ROOM, label: _.capitalize(LOCATION_TYPE_ROOM) },
  { value: LOCATION_TYPE_SHELF, label: _.capitalize(LOCATION_TYPE_SHELF) },
  { value: LOCATION_TYPE_BOX, label: _.capitalize(LOCATION_TYPE_BOX) }
]

const locationFunctions = [
  { value: LOCATION_FUNCTION_DEPOSITARY, label: _.capitalize(LOCATION_FUNCTION_DEPOSITARY) },
  { value: LOCATION_FUNCTION_LOAN, label: _.capitalize(LOCATION_FUNCTION_LOAN) },
  { value: LOCATION_FUNCTION_OTHER, label: _.capitalize(LOCATION_FUNCTION_OTHER) }
];

type LocationFormProps = {
  control: Control<any>;
}

const LocationForm = ({ control }: LocationFormProps) => {
  const { data: locations } = useLocations();
  const locationType = useWatch({ control, name: 'type' });
  const filteredLocations = _.filter(locations, (location) => location.type === locationType);
  const locationOptions = getOptionsWithLabel(locations, { value: '_id', label: ['name', 'type', 'function'] });
  console.log(locationOptions);
  return (
    <Stack spacing={2}>

      <CustomTextField
        name='name'
        label='location name'
        type='string'
        required={true}
        variant='standard'
        control={control}
      />

      <CustomTextField
        name='type'
        label='location type'
        type='string'
        required={true}
        variant='standard'
        control={control}
        options={locationTypes}
      />

      <CustomTextField
        name='function'
        label='location function'
        type='string'
        variant='standard'
        control={control}
        options={locationFunctions}
      />

      <CustomTextField
        name='postalAddress'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />

      <CustomTextField
        name='description'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />

      <CustomAutocomplete
        name='belongsToLocationIds'
        label='belongs to locations'
        control={control}
        options={locationOptions}
        multiple={true}
      />

    </Stack>
  )
};


export default LocationForm;