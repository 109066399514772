import _ from 'lodash';
import React, { useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';

import { useCreateAuthor, useUpdateAuthor } from '../../../../api/people';
import { IAuthorCreate } from '../../../../../../../common/interfaces/IData';
import FormModal from '../FormModal';
import AuthorForm from '../../forms/author/AuthorForm';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';

interface AuthorFormProps {
  action: 'create' | 'update',
  authorId?: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any
  dialogTitle: string,
}

const AuthorFormModal = (props: AuthorFormProps) => {
  const [open, setOpen] = React.useState(false);

  const { getValues, setError, clearErrors, reset, setValue, control, handleSubmit, formState: { errors, isSubmitting } } = useForm();

  const authorType = useWatch({ name: 'author.type', control });
  const authorName = useWatch({ name: 'author.name', control });

  const fieldErrorMessage = !_.isEmpty(errors) ? _.toString(errors['author']?.message) : null;

  const createAuthor = useCreateAuthor();
  const updateAuthor = useUpdateAuthor();

  const handleOpen = () => {
    reset({ author: props.defaultValues });
    setOpen(true);
  }

  const handleClose = () => {
    // this function closes the modal and resets the form
    setOpen(false);
    reset();
  }

  // clear errors if author name is changed
  useEffect(() => {
    // use timeout for performance reasons
    setTimeout(() => {
      if (!_.isEmpty(errors)) {
        clearErrors('author');
      }
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorName]);

  const onSubmit = async () => {
    const { author } = getValues();

    if (props.action === 'create') {
      const newAuthor: IAuthorCreate = {
        name: author.name.value,
        type: author.type,
        description: author.description
      };

      await createAuthor.mutateAsync(newAuthor);
    }

    if (props.action === 'update') {
      await updateAuthor.mutateAsync(author);
    }

    if (author.name.value && author.name.value.length > 0) {
      setError('author', { type: 'custom', message: 'This author already exists! Please upload your artwork to this author.' });
      return;
    }

    handleClose();
  }

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} buttonType='MenuItem' handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <AuthorForm authorType={authorType} fieldErrorMessage={fieldErrorMessage} control={control} setValue={setValue}></AuthorForm>
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Button color="inherit" onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
            </Stack>
        </form>
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(AuthorFormModal);