import _ from 'lodash';
import React from 'react';
import { TextField, StandardTextFieldProps, MenuItem } from '@mui/material';
import { useController, Control } from 'react-hook-form';

interface OptionProps {
    value: string,
    label: string
}

interface Props extends StandardTextFieldProps {
    name: string;
    label?: string;
    rules?: any;
    control: Control<any>;
    options?: OptionProps[];
}

const CustomTextField = ({ name, label, rules, options, control, ...props }: Props) => {
    const { field, fieldState: { error } } = useController({ name, control, rules });
    
    const fieldErrorMessage = error ? _.toString(error?.message) : null;
    const labelName = _.isString(label) ? _.startCase(label) : _.startCase(_.last(_.split(name, '.')));

    return (
        <TextField
            {...props}
            name={field.name}
            label={labelName}
            value={field.value || ''}
            onChange={field.onChange}
            onBlur={field.onBlur}
            error={Boolean(error)}
            helperText={fieldErrorMessage}
            select={Boolean(options)}
            inputRef={field.ref}
            key={field.name}
        >
            {options &&
                options.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
        </TextField>
    );
};

export default CustomTextField;