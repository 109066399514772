import React from 'react';
import { ListItemText, Typography } from '@mui/material';

type CustomListItemTextProps = {
    label: string,
    value: string
}

const CustomListItemText: React.FC<CustomListItemTextProps> = ({ label, value }) => {
    return (
        <ListItemText key={label || value} primary={<Typography variant="body1"><b>{label || ''}</b></Typography>} secondary={<Typography variant="body1">{value || ''}</Typography>} />
    )
}

export default CustomListItemText;