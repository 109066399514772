import axios from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { removeEmptyValues } from '../utils/helpers';
import { useAlert } from '../store/zustand';
import { ILocation, ILocationResources } from '../../../../common/interfaces/IData';

interface IUpdateLocation {
  locationId: string,
  location: ILocation
}

interface IDeleteLocation {
  locationId: string
}

interface ILocationResource {
  locationId: string,
  resourceId: string,
  resource: string,
}

const createLocation = async (payload: ILocation | undefined): Promise<ILocation> => {
  const axiosConfig = getAxiosConfig();
  const locationCleanData = removeEmptyValues(payload);
  
  const { data }: { data: ILocation } = await axios.post('/location/create',
      { location: locationCleanData },
      axiosConfig
  );

  return data;
}

const addLocationToResource = async (payload: ILocationResource | undefined): Promise<ILocation> => {
  const axiosConfig = getAxiosConfig();
  const { locationId, resourceId, ...locationCleanData } = removeEmptyValues(payload);
  
  const { data }: { data: ILocation } = await axios.put(`/location/${locationId}/add/resource/${resourceId}`,
      { location: locationCleanData },
      axiosConfig
  );

  return data;
}

const removeLocationFromResource = async (payload: ILocationResource | undefined): Promise<ILocation> => {
  const axiosConfig = getAxiosConfig();
  const { locationId, resourceId, resource } = removeEmptyValues(payload);

  const { data }: { data: ILocation } = await axios.put(`/location/${locationId}/remove/resource/${resourceId}`,
      { location: { resource } },
      axiosConfig
  );

  return data;
}

const getLocations = async (): Promise<ILocation[]> => {
    const axiosConfig = getAxiosConfig();
    const { data }: { data: [ILocation] } = await axios.get('/location/find', axiosConfig);
    return data;
}

const getLocationResources = async (locationId: string | undefined): Promise<ILocationResources[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: { data: [ILocationResources] } = await axios.get(`/location/${locationId}/resources`, axiosConfig);
  return data;
}

const updateLocation = async ({ location, locationId }: IUpdateLocation): Promise<ILocation> => {
  const axiosConfig = getAxiosConfig();

  const locationCleanData = removeEmptyValues(location);

  const { data }: { data: ILocation } = await axios.put(`/location/${locationId}`,
      { location: locationCleanData },
      axiosConfig
  );

  return data;
}

const deleteLocation = async ({ locationId }: IDeleteLocation): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  
  const { data } = await axios.delete(`/location/${locationId}`,
      axiosConfig
  );

  return data;
}

export const useCreateLocation = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (location: ILocation) => apiRequestPromise.handleApiRequestPromise(createLocation, location),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Location created successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['locations'] });
      }
  })
}

export const useAddLocationToResource = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (location: ILocationResource) => apiRequestPromise.handleApiRequestPromise(addLocationToResource, location),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Location added successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['artworks'] });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
          queryClient.invalidateQueries({ queryKey: ['locations'] });
      }
  })
}

export const useRemoveLocationFromResource = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (location: ILocationResource) => apiRequestPromise.handleApiRequestPromise(removeLocationFromResource, location),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Location removed successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['artworks'] });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
          queryClient.invalidateQueries({ queryKey: ['locations'] });
      }
  })
}

export const useLocations = () => {
  const apiRequestPromise = useApiRequestPromise();
  return useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      return apiRequestPromise.handleApiRequestPromise(getLocations)
    },
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 5 * 60 * 1000,
  });
}

export const useLocationResources = (locationId: string | undefined) => {
  const apiRequestPromise = useApiRequestPromise();
  return useQuery({
    queryKey: ["locations", locationId],
    queryFn: async () => apiRequestPromise.handleApiRequestPromise(getLocationResources, locationId),
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 5 * 60 * 1000,
  });
}

export const useUpdateLocation = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (location: IUpdateLocation) => apiRequestPromise.handleApiRequestPromise(updateLocation, location),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Location updated successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['locations'] });
      }
  })
}

export const useDeleteLocation = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (payload: IDeleteLocation) => apiRequestPromise.handleApiRequestPromise(deleteLocation, payload),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Location deleted successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['locations'] });
      }
  })
}

