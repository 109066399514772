import _ from 'lodash';
import React, { useEffect } from 'react';
import { Table, TableBody, Box, TableContainer, TableHead, TableRow, Chip, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useUsers } from '../../api/user';
import { IUser } from '../../../../../common/interfaces/IData';
import { SmallMenu, useSmallMenu } from './SmallMenu';
import UpdatePasswordModal from './modals/user/UpdatePasswordModal';
import UpdateUserProfileModal from './modals/user/UpdateUserProfileModal';
import UpdateUserRoleModal from './modals/user/UpdateUserRoleModal';
import DeleteModal from './modals/DeleteModal';
import CustomTableCellCenter from './forms/inputs/CustomTableCellCenter';
import { useDeleteUser } from '../../api/user';
import withResourceGuard from '../authentication/ResourceGuard';
import { RESOURCE_USER } from '../../utils/constants';

const UserList = () => {
    const { data: users } = useUsers();
    const navigate = useNavigate();
    const deleteUser = useDeleteUser();
    const tableHead = ['Name', 'Email', 'Role', 'Action'];

    return (
        <TableContainer className='table-responsive'>
            <Table className='table align-middle'>
                <TableHead>
                    <TableRow sx={{ alignText: 'center' }}>
                        {_.map(tableHead, (head: string) => (
                            <CustomTableCellCenter key={head}>{head}</CustomTableCellCenter>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_.map(users, (user: IUser, index) => (
                        <TableRow key={index} sx={{ alignText: 'center' }} className='no-border-bottom table-hover'>
                            <CustomTableCellCenter>{user.name}</CustomTableCellCenter>
                            <CustomTableCellCenter>{user.email}</CustomTableCellCenter>
                            <CustomTableCellCenter><Chip color="primary" variant="outlined" label={_.capitalize(user.roleName)} /></CustomTableCellCenter>
                            <CustomTableCellCenter>
                                <SmallMenu
                                    customMenuButtonIcon={<Typography variant="button">Update User</Typography>}
                                    customMenuIconButtonClasses={{ borderRadius: '4px' }}
                                >
                                    <UpdateUserProfileModal userId={user._id} buttonText='Update Profile' buttonFullWidth dialogTitle='Update Profile' defaultValues={user} />
                                    <UpdatePasswordModal userId={user._id} buttonText='Update Password' buttonFullWidth dialogTitle='Update Password' />
                                    <Button fullWidth variant="child" component={Link} to={`/settings/${user._id}/permissions`}>Manage Permissions</Button>
                                    <DeleteModal action='delete' actionFunction={deleteUser} resourceTitle={_.get(user, 'name')} resourceDeletionData={{ userId: _.get(user, '_id') }} buttonFullWidth={true} buttonText='Delete Account' dialogTitle='Delete User Account' />
                                </SmallMenu>
                            </CustomTableCellCenter>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default withResourceGuard([RESOURCE_USER])(UserList);
