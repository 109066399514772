import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { removeEmptyValues } from '../utils/helpers';
import { useAlert } from '../store/zustand';
import { IAuthor, IAuthorCreate } from '../../../../common/interfaces/IData';

const createAuthor = async (payload: IAuthorCreate | undefined): Promise<IAuthor> => {
  const axiosConfig = getAxiosConfig();
  const authorCleanData = removeEmptyValues(payload);
  
  const { data }: { data: IAuthor } = await axios.post('/author/create',
      { author: authorCleanData },
      axiosConfig
  );

  return data;
}

const getAuthors = async (): Promise<IAuthor[]> => {
    const axiosConfig = getAxiosConfig();
    const { data }: { data: [IAuthor] } = await axios.get('/author/list', axiosConfig);
    return data;
}

const getAuthorSuggestions = async (): Promise<IAuthor[]> => {
  const axiosConfig = getAxiosConfig();
  const { data }: { data: [IAuthor] } = await axios.get('/author/suggestions', axiosConfig);
  return data;
}

const updateAuthor = async (author: IAuthorCreate | undefined): Promise<IAuthor> => {
  const axiosConfig = getAxiosConfig();

  const authorCleanData = removeEmptyValues(author);

  const { data }: { data: IAuthor } = await axios.put(`/author/${_.get(authorCleanData, '_id')}`,
      { author: authorCleanData },
      axiosConfig
  );

  return data;
}

const deleteAuthor = async (payload: any): Promise<void> => {
  const { authorId, navigate  } = payload;
  const axiosConfig = getAxiosConfig();
  
  const { data } = await axios.delete(`/author/${authorId}`,
      axiosConfig
  );

  return data;
}

export const useCreateAuthor = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (author: IAuthorCreate) => apiRequestPromise.handleApiRequestPromise(createAuthor, author),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Author created successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['authors'] });
      }
  })
}

export const useAuthors = () => {
  const apiRequestPromise = useApiRequestPromise();
  return useQuery({
    queryKey: ["authors"],
    queryFn: async () => {
      return apiRequestPromise.handleApiRequestPromise(getAuthors)
    },
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 5 * 60 * 1000,
  });
}

// this is a candidate for deleting and merging with useAuthors
export const useAuthorSuggestions = () => {
  const apiRequestPromise = useApiRequestPromise();

return useQuery({
  queryKey: ["authors.suggestions"],
  queryFn: async () => {
    return apiRequestPromise.handleApiRequestPromise(getAuthorSuggestions)
  },
  placeholderData: (previousData, previousQuery) => previousData,
  staleTime: 5 * 60 * 1000,
});
}

export const useUpdateAuthor = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (author: IAuthorCreate) => apiRequestPromise.handleApiRequestPromise(updateAuthor, author),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Author updated successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['authors'] });
      }
  })
}

type IAuthorDelete = {
  authorId: string;
  navigate: any;
}

export const useDeleteAuthor = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (authorDeletionData: IAuthorDelete) => apiRequestPromise.handleApiRequestPromise(deleteAuthor, authorDeletionData),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Author deleted successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['authors'] });
      }
  })
}

