import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Card, CardMedia, CardContent, CardActions, CardActionArea, Typography, Skeleton, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import HideImageIcon from '@mui/icons-material/HideImage';

import { getArtworkDimensions, getArtworkYear, getInputValue, getNormalizedString, getObjectWithoutEmptyValues } from '../../utils/helpers';
import { useArtworksByAuthorImages } from '../../api/artwork';
import { useAuthors } from '../../api/people';
import AuthorCard from '../parts/cards/author/AuthorCard';
import SearchCard from '../parts/cards/SearchCard';
import AuthorsCard from '../parts/cards/author/AuthorsCard';
import ArtworksCard from '../parts/cards/artwork/ArtworksCard';
import withResourceGuard from '../authentication/ResourceGuard';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../utils/constants';

const HomePage = (props: any) => {
  const { data: authors } = useAuthors();
  
  const artists = useMemo(() => _.filter(authors, { type: 'artist' }), [authors]);
  const randomAuthor = useMemo(() => _.sample(artists), [artists]);
  const authorId = randomAuthor && _.get(randomAuthor, '_id');
  const { data: artworks } = useArtworksByAuthorImages({ authorId: _.get(randomAuthor, '_id') });

  const normalizedArtworks = _.map(artworks, (artwork) => {
    const { title, ...artworkData } = artwork;
    return {
      normalizedTitle: getNormalizedString(title),
      mediumName: _.get(artwork, 'medium.name'),
      materialName: _.get(artwork, 'material.name'),
      techniqueName: _.get(artwork, 'technique.name'),
      title,
      ...artworkData
    }
  });

  const orderedArtworks = _.orderBy(normalizedArtworks, ['normalizedTitle'], ['asc']);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4 }}>
          <AuthorsCard headerActionsDisabled={true} footerActionsDisabled={true} />
        </Grid>
        <Grid size={{ xs:12, md:4 }}>
          <ArtworksCard headerActionsDisabled={true} footerActionsDisabled={true} />
        </Grid>
        <Grid size={{ xs:12, md:4 }}>
          <AuthorCard author={randomAuthor} headerActionsDisabled={true} footerActionsDisabled={true} authorDescription={<i>the pick of today</i>} />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ alignItems: 'flex-end', marginBottom: 2 }}>

        {_.map(orderedArtworks, (artwork: any) =>
          <Grid size={{ xs:12, md:4 }}>
            <Card variant='grey'>
              <CardActionArea component={Link} to={`/people/${authorId}/artworks/${_.get(artwork, '_id')}`}>
              {
                _.get(artwork, 'image.thumbnailBase64') ?
                  <CardMedia
                    component="img"
                    height="400"
                    src={_.get(artwork, 'image.thumbnailBase64')}
                    alt={_.get(artwork, 'image.filename')}
                    // style={{ objectFit: 'contain' }} use this if the images shouldn't be cropped
                  />
                  :
                  <Skeleton variant="rectangular" height={400} />
              }
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(HomePage);