import _ from 'lodash';
import React from 'react';
import { Chip, TextField, Card, CardContent, Button, Stack, IconButton } from '@mui/material';
import { Control, useFieldArray, useWatch } from 'react-hook-form';
import CustomAutocomplete from '../forms/inputs/CustomAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import CustomCard from './CustomCard';
import CustomTextField from '../forms/inputs/CustomTextField';
import { getOptionsWithLabel } from '../../../utils/helpers';
import { searchOperations } from '../../../utils/constants';

type AnyDataObject = {
    [key: string]: any[]
}

type SearchCardProps = {
    options: AnyDataObject,
    control: Control<any>;
    SearchCardActionsLeft?: React.ReactNode;
}

type SearchTextFieldProps = {
    control: Control<any>;
}


const CustomSearch: React.FC<SearchTextFieldProps> = ({ control }) => {
    return (
        <CustomAutocomplete
            name='search'
            label='search anything...'
            renderTags={(value, getTagProps) =>
                _.map(value, (option, index) => (
                    <Chip
                        variant="outlined"
                        color='primary'
                        deleteIcon={<ClearIcon />}
                        label={option.label || option}
                        {...getTagProps({ index })}
                    />
                ))
            }
            control={control}
            options={[]}
            multiple={true}
            freeSolo={true}
            sx={{
                '& input': { padding: 2 },
                '& .MuiInputLabel-root': {
                    color: 'inherit',
                    fontSize: '1.2rem',
                    lineHeight: '1.5rem',
                    fontWeight: 300,
                },
                '& .MuiInput-underline:before': {
                    borderBottom: '1px solid black', // Add a 2px black solid bottom border on focus
                },
                '& .MuiInput-root': {
                    paddingBottom: 1
                }
            }}
        />
    )
}

const SearchCard: React.FC<SearchCardProps> = ({ control, options, SearchCardActionsLeft }) => {
    const groupBy = (option: { value: string, label: string }) => _.first(_.split(option.label, ":")) || "";

    const { fields: searchFilters, append, prepend, remove, swap, move, insert } = useFieldArray({
        name: "searchFilter",
        control: control
    });

    const searchFilter = useWatch({ control, name: 'searchFilter' });

    const fieldNames = getOptionsWithLabel(_.keysIn(options));

    return (
        <CustomCard
            variant='grey'
            headerActions={
                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                {SearchCardActionsLeft}
                <Button variant="text" color="inherit" sx={{ marginLeft: 'auto' }} onClick={() => append({ field: '', operation: '', value: '' })}>Add Filter</Button>
                </Stack>
            }
            footerActionsDisabled={true}
            content={
                <Stack direction='column' spacing={1}>
                    <Stack direction='column' spacing={1}>
                        {
                            _.map(searchFilters, (field, index) => {
                                let searchFilterValueSuggestions = [];
                                const searchFilterAtIndex = searchFilter?.[index] || {};
                                const searchFilterField = searchFilterAtIndex?.field;
                                const searchFilterOperation = searchFilterAtIndex?.operation;
                                
                                if (searchFilterField && searchFilterOperation) {
                                    searchFilterValueSuggestions = _.get(options, searchFilterField);
                                }

                                return (
                                    <Stack direction='row' spacing={1}>
                                        <CustomTextField
                                            name={`searchFilter[${index}].field`}
                                            variant='standard'
                                            control={control}
                                            type='text'
                                            select
                                            options={_.castArray(fieldNames) || []}
                                            fullWidth
                                            sx={{ mx: 1 }}
                                            required={true}
                                        />
                                        <CustomTextField
                                            name={`searchFilter[${index}].operation`}
                                            variant='standard'
                                            control={control}
                                            fullWidth
                                            select
                                            options={searchOperations}
                                            sx={{ mx: 1 }}
                                        />
                                        <CustomAutocomplete
                                            name={`searchFilter[${index}].value`}
                                            control={control}
                                            fullWidth
                                            renderTags={(value, getTagProps) =>
                                                _.map(value, (option, index) => (
                                                    <Chip
                                                        variant="outlined"
                                                        color='primary'
                                                        deleteIcon={<ClearIcon />}
                                                        label={option.label || option}
                                                        {...getTagProps({ index })}
                                                    />
                                                ))
                                            }
                                            options={searchFilterValueSuggestions}
                                            multiple={false}
                                            freeSolo={true}
                                        />
                                        <IconButton
                                            aria-label="Remove"
                                            onClick={() => remove(index)}
                                            sx={{ marginLeft: 'auto' }}
                                            component='button'
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </Stack>
                                )
                            })
                        }
                    </Stack>
                    <CustomSearch control={control} />
                </Stack>
            }
            headerSx={{ '& .MuiCardHeader-action': { width: '100%'}, '& .MuiCardHeader-content': { flex: 0 } }}
        >
        </CustomCard>

    )
}

export default SearchCard;
