import _ from 'lodash';
import React, { useState } from 'react';

import { RESOURCE_LOCATION } from '../../utils/constants';
import { getNormalizedString, getOptionsWithLabel } from '../../utils/helpers';
import { useLocations } from '../../api/location';
import { Button, Card, CardHeader, CardContent, Chip, Typography, Stack, Container, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomAutocomplete from '../parts/forms/inputs/CustomAutocomplete';
import ImageModal from '../parts/modals/image/ImageModal';
import LocationsCard from '../parts/cards/location/LocationsCard';
import SearchCardAutocomplete from '../parts/cards/SearchCardAutocomplete';
import { Link, useParams } from 'react-router-dom';
import withResourceGuard from '../authentication/ResourceGuard';
import { getInputValue, getFilteredDataFromSearch, prepareSearchDataOptions } from '../../utils/helpers';
import { ILocation } from '../../../../../common/interfaces/IData';
import { useForm, useWatch } from 'react-hook-form';
import CustomClickableChip from '../parts/CustomClickableChip';

enum LocationTypes {
  Artist = 'artist',
  Photographer = 'photographer',
  Publisher = 'publisher',
  Curator = 'curator'
}

const LocationList: React.FC = (props: any) => {
  const { control } = useForm();

  const searchTerms = useWatch({ control, name: 'search' });

  const { data: locations } = useLocations();

  const locationFilterData = _.map(locations, (location: ILocation) => ({
    name: location.name,
    type: location.type,
    function: location.function,
    belongsToLocationIds: _.map(location.belongsToLocationIds, (belongsToLocationId) => _.get(belongsToLocationId, 'name'))
  }));

  const locationOptions = prepareSearchDataOptions(locationFilterData);
  
 const filteredLocations = getFilteredDataFromSearch(locations, searchTerms);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4 }}>
          <LocationsCard />
        </Grid>
        <Grid size={{ xs:12, md:8 }}>
            <SearchCardAutocomplete control={control} options={locationOptions} />
        </Grid>
      </Grid>

      {
        _.map(filteredLocations, (location) =>
          <>
            <Stack direction='row' sx={{ textAlign: 'left', mt: 1 }}>
              <Button
                component={Link}
                type="button"
                to={`/locations/${_.get(location, '_id')}`}
                sx={{ textTransform: 'none', padding: 0 }}
              >
                <Typography variant="h3" color="text.primary">{_.get(location, 'name')}</Typography>
              </Button>
            </Stack>
            <Stack direction='row' sx={{ textAlign: 'left' }} spacing={1}>
              <>
                {_.get(location, 'type') &&
                  <Chip size='small' label={_.capitalize(_.get(location, 'type'))} />
                }
                {
                  _.get(location, 'function') &&
                  <Chip size='small' label={_.capitalize(_.get(location, 'function'))} />
                }
                {
                  _.map(_.get(location, 'belongsToLocationIds'), (belongsToLocationId) =>
                    <CustomClickableChip label={_.get(belongsToLocationId, 'name')} linkTo={`/locations/${_.get(belongsToLocationId, '_id')}`} variant='filled' />
                  )
                }
              </>
            </Stack></>
        )
      }
    </>
  )
}

export default withResourceGuard([RESOURCE_LOCATION])(LocationList);