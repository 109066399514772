import React from 'react';

import { Container } from '@mui/material';
import { Outlet } from "react-router-dom";

import Navigation from '../parts/navigation/Navigation';
import BottomNavigation from '../parts/navigation/BottomNavigation';
import Authentication from '../authentication/Authentication';
import Alerts from '../parts/Alerts';
import { useTheme } from '@mui/material/styles';

const AuthenticatedLayout: React.FC = () => {
    const theme = useTheme();
    return (
        <Authentication>
            <Alerts></Alerts>
            <Navigation></Navigation>
                <Container maxWidth="xl" sx={{ px: 1, pb: 4, [theme.breakpoints.up('sm')]: { pt: 8 } }}>
                <Outlet />
                </Container>
            <BottomNavigation />
        </Authentication>
    )
}

export default AuthenticatedLayout;

