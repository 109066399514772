import _ from 'lodash';
import { AxiosRequestConfig, AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { useAuth, useAlert } from '../store/zustand';
import { error } from 'console';
import { useUserLogout } from './user';

type RequestFunction = (params?: any) => Promise<any>;

export const getAxiosConfig = () => {
  const token = useAuth.getState().token;

  const headers = token ? { Authorization: `Bearer ${token}` } : null;

  const axiosConfig: AxiosRequestConfig = {
    baseURL: `${process.env.BACKEND_API}`,
    ...(headers && { headers } ),
    validateStatus: function (status: number) {
      return status >= 200 && status < 399; // default
    },
    timeout: 60000,
    timeoutErrorMessage: 'Ooops, the request took too long, something is stuck!',
  };

  return axiosConfig;
}

export const useApiRequestPromise = () => {
  const userLogout = useUserLogout();
  
  const handleApiRequestPromise = async (requestFunction: RequestFunction, params?: any): Promise<any> => {
    try {
      const result = await requestFunction(params);
      return result;
    } catch (e: any) {
      const { errorMessage, errorStatus } = getAxiosErrorMessageAndStatus(e);
      
      if (_.includes([401, 403], errorStatus)) {
        userLogout.logout(); // handle case when client has a token but is not allowed by the server
      }
  
      useAlert.getState().addAlert({ message: `Ooops, something went wrong: ${errorMessage}`, severity: 'error' });
      throw new Error(errorMessage);
    }
  }

  return { handleApiRequestPromise };
}

export const getAxiosErrorMessageAndStatus = (e: AxiosError) => {
  const errorStatus = _.get(e, 'response.status') ? _.get(e, 'response.status') : _.get(e, 'request.status');
  const errorMessage = _.get(e, 'response.data') ? _.get(e, 'response.data') : _.get(e, 'message');
  return { errorStatus, errorMessage: _.toString(errorMessage) };
}

export const handleError = (errorMessage: any) => toast.error(`Something went wrong: ${_.toString(errorMessage)}`);