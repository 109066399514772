import _ from 'lodash';
import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import FormModal from '../FormModal';
import { useUpdateImage } from '../../../../api/image';
import Progress from '../../Progress';
import CustomTextField from '../../forms/inputs/CustomTextField';
import AuthorFormSelect from '../../forms/author/AuthorFormSelect';
import { IImage } from '../../../../../../../common/interfaces/IData';
import { AutocompleteOption } from '../../forms/inputs/CustomAutocomplete';
import { getAutocompleteValue, getOptionsWithLabel, getUpdatedFormFields } from '../../../../utils/helpers';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_UPDATE } from '../../../../utils/constants';

interface FileUpdateProps {
  buttonClassName?: string,
  dialogTitle: string,
  buttonText: string,
  buttonFullWidth?: boolean,
  defaultValues: IImage
}

type FileUpdateFormValues = {
  description: string;
  photographerId: AutocompleteOption | AutocompleteOption[];
}

const FileUpdateFormModal = (props: FileUpdateProps) => {
  const { defaultValues } = props;
  const imageId = _.get(defaultValues, '_id');
  const [open, setOpen] = React.useState(false);

  const { control, getValues, reset, handleSubmit, formState: { isSubmitting, dirtyFields } } = useForm<FileUpdateFormValues>();

  const updateImage = useUpdateImage();

  const handleOpen = () => {
    reset({
      description: _.get(defaultValues, 'description'),
      photographerId: getOptionsWithLabel(_.get(defaultValues, 'photographerId'), { label: 'name', value: '_id' })
    
    });
    setOpen(true);
  }

  const handleClose = () => {
    // this function closes the modal and resets the form
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    const { description, photographerId } = getValues();
    const values = { description, photographerId: getAutocompleteValue(photographerId) }
    
    const updateData = getUpdatedFormFields(values, dirtyFields);
    
    await updateImage.mutateAsync({ imageId, updateData });

    handleClose();
  }

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
            <AuthorFormSelect control={control} type='photographer' name='photographerId' label='photographer'></AuthorFormSelect>
            <CustomTextField
              name='description'
              label='image description'
              variant='standard'
              control={control}
              fullWidth
            />
          </Stack>
          <Progress className='mt-2 mb-2' />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Update</Button>
          </Stack>
        </form>
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_UPDATE])(FileUpdateFormModal);