import _ from 'lodash';
import React from 'react';
import ExhibitionFormModal from '../../modals/exhibition/ExhibitionFormModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_EXHIBITION } from '../../../../utils/constants';
import { useExhibitions } from '../../../../api/exhibition';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Typography } from '@mui/material';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type ExhibitionsCardProps = {
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const ExhibitionsCard = (props: ExhibitionsCardProps) => {
    const { data: exhibitions } = useExhibitions();
    const exhibitionOptions = getOptionsWithLabel(exhibitions, { value: '_id', label: 'title' });

    return (
        <CustomCard
            title={{ title: 'Exhibitions', linkTo: '/exhibitions/list' }}
            content={<Typography variant='body1'><i>the collection has {_.size(exhibitions)} exhibitions</i></Typography>}
            footerActionLink={
                <ExhibitionFormModal action='create' buttonClassName='align-self-start' buttonFullWidth={false} buttonText='Create Exhibition' dialogTitle='Create Exhibition'></ExhibitionFormModal>
            }
            footerActionsDisabled={props.footerActionsDisabled}
            headerActionsDisabled={props.headerActionsDisabled}
            headerActions={
                <SmallMenu>
                    <ShareResourceWithUserModal
                        resource={RESOURCE_EXHIBITION}
                        resourceOptions={exhibitionOptions}
                        buttonFullWidth={true}
                        buttonText='Share Exhibitions'
                        dialogTitle='Share Exhibitions'
                    />
                </SmallMenu>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_EXHIBITION])(ExhibitionsCard);