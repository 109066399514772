import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import ArtworkForm from '../../forms/artwork/ArtworkForm';
import FormModal from '../FormModal';
import { useCreateArtwork, useUpdateArtwork } from '../../../../api/artwork';

import { IArtwork } from '../../../../../../../common/interfaces/IData';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';


interface ArtworkFormProps {
  action: string,
  artworkId?: string,
  authorId?: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any
  dialogTitle: string,
}

const ArtworkFormModal = (props: ArtworkFormProps) => {
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, getValues, control, setValue, reset, formState: { isSubmitting } } = useForm();

  const createArtwork = useCreateArtwork();
  const updateArtwork = useUpdateArtwork();

  const handleOpen = () => {
    reset({ artwork: props.defaultValues });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    const { artwork } = getValues();

    if (props.action === 'create') {
      const artworkData: IArtwork = {
        authorId: props.authorId,
        ...artwork
      };

      await createArtwork.mutateAsync(artworkData);
    }
    if (props.action === 'update') {
      await updateArtwork.mutateAsync(artwork);
    }

    handleClose();
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullScreen={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ArtworkForm control={control} setValue={setValue} />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )}
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(ArtworkFormModal);