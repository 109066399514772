import _ from 'lodash';
import React, { useMemo } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, List, ListItem, Stack, SwipeableDrawer } from '@mui/material';
import { useUserLogout } from '../../../api/user';
import { Link, NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PaintSplashIcon from './PaintSplashIcon';
import { useAuth } from '../../../store/zustand';
const { brandName, navigationItems, RESOURCE_ALL } = require('../../../utils/constants');

const BottomNavigation: React.FC = () => {
    const user = useAuth(state => state.user);
    const userLogout = useUserLogout();
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const theme = useTheme();

    const handleDrawerToggle = () => {
        setDrawerOpen((prevState) => !prevState);
    };

    const filteredNavigation = useMemo(() => {
        const allowedResources = _.get(user, 'allowedResources', []);
        if (_.includes(allowedResources, RESOURCE_ALL)) {
            return navigationItems;
        }
        if (!_.isEmpty(allowedResources)) {
            const regexExpressions = _.map(allowedResources, (resource) => new RegExp(`^${resource}`, 'gmi'));

            const filter = _.filter(navigationItems, (item) => _.some(regexExpressions, (regex) => regex.test(_.get(item, 'name'))));
            return filter;
        }
        return [];
    }, [user]);

    

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center', height: '100%', backgroundColor: theme.palette.grey[200] }}>
            <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ mt: 2, mx: 2 }}>
                <Typography variant="h6" sx={{ mx: 1 }}>
                    <Link to='/'>
                        <PaintSplashIcon />
                    </Link>
                </Typography>
                <Box sx={{ width: '100%' }}>
                    <Typography variant="h6" sx={{ mx: 1, fontStyle: 'italic', textTransform: 'uppercase', textAlign: 'center' }}>
                        {brandName}
                    </Typography>
                </Box>
            </Stack>
            <List>
                {_.map(filteredNavigation, (item) => (
                    <ListItem key={_.get(item, 'name')} disablePadding>
                        <Button variant='child' sx={{ textAlign: 'center' }} component={NavLink} to={_.get(item, 'path')} fullWidth>
                            {_.get(item, 'name')}
                        </Button>
                    </ListItem>
                ))}
                <ListItem disablePadding>
                    <Button variant='child' sx={{ textAlign: 'center' }} component={NavLink} to='/settings' fullWidth>
                        Settings
                    </Button>
                </ListItem>
                <ListItem disablePadding>
                    <Button variant='child' sx={{ textAlign: 'center' }} onClick={() => userLogout.logout()} fullWidth>
                        Logout
                    </Button>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <>
                    <IconButton
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mx: '0.7rem', display: 'flex', position: 'fixed', bottom: 0, right: 0, justifyContent: 'center', alignItems: 'center', [theme.breakpoints.up('sm')]: { display: 'none' }, backgroundColor: 'transparent', color: 'white', borderRadius: '8px' }}
                    >
                        <PaintSplashIcon />
                    </IconButton>
                
            <nav>
                <SwipeableDrawer
                    anchor='bottom'
                    open={drawerOpen}
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    elevation={2}
                    sx={{
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%', maxHeight: '70%', borderTopLeftRadius: '8px', borderTopRightRadius: '8px' },
                    }}
                >
                    {drawer}
                </SwipeableDrawer>
            </nav>
        </>
    )
}

export default BottomNavigation;
