import { get, set, del } from "idb-keyval";
import { PersistedClient, Persister } from "@tanstack/react-query-persist-client";
import { ARTWORKS_CACHE_STORAGE_KEY } from "../utils/constants";

export function useIDBPersister(idbValidKey: IDBValidKey = ARTWORKS_CACHE_STORAGE_KEY) {
      const persistClient = async (client: PersistedClient) => {
        set(idbValidKey, client);
      };

      const restoreClient = async () => {
        return await get<PersistedClient>(idbValidKey);
      };

      const removeClient = async () => {
        await del(idbValidKey);
      };

      return {
        persistClient,
        restoreClient,
        removeClient,
      } as Persister;
}