import _ from 'lodash';
import React, { useState } from 'react';

import { RESOURCE_LOCATION } from '../../utils/constants';
import { getArtworkDimensions, getArtworkYear, getNormalizedString } from '../../utils/helpers';
import { useLocationResources, useLocations } from '../../api/location';
import { Card, CardContent, Typography, Stack, Container, CardMedia, CardActions, Skeleton, Box, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomAutocomplete from '../parts/forms/inputs/CustomAutocomplete';
import ImageModal from '../parts/modals/image/ImageModal';
import LocationCard from '../parts/cards/location/LocationCard';
import SearchCard from '../parts/cards/SearchCard';
import { Link, useParams } from 'react-router-dom';
import withResourceGuard from '../authentication/ResourceGuard';
import { getInputValue } from '../../utils/helpers';
import CustomListItemText from '../parts/CustomListItemText';
import CustomClickableChip from '../parts/CustomClickableChip';

enum LocationTypes {
  Artist = 'artist',
  Photographer = 'photographer',
  Publisher = 'publisher',
  Curator = 'curator'
}

// TODO: Location Detail, Images, Artwork List, Author List, Location

const LocationList: React.FC = (props: any) => {
  const [searchString, setSearchString] = useState('');
  const { locationId } = useParams();

  const { data: locations } = useLocations();

  const location = _.find(locations, { _id: locationId });
  const locationData = _.omit(location, ['_id']);

  const { data: locationResources } = useLocationResources(locationId);

  console.log(locationResources);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:12 }}>
          <LocationCard location={location}></LocationCard>
        </Grid>
      </Grid>

      <Typography variant='h3' sx={{ marginBottom: 1 }}>Authors</Typography>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:12 }}>
          <Stack direction='row' spacing={1}>
            {_.map(locationResources?.authors, (author: any) => <CustomClickableChip label={author.name} linkTo={`/people/${author._id}`} />)}
          </Stack>
        </Grid>
      </Grid>

      <Stack direction='row' spacing={1} sx={{ marginBottom: 1 }}>
      <Typography variant='h3' sx={{ marginBottom: 1 }}>Artworks</Typography>
      </Stack>
        <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1, overflow: 'scroll' }}>
        {_.map(locationResources?.artworks, (artwork: any) =>
          <Grid size={{ xs:6, md:3 }}>
            <Link to={`/people/${_.get(artwork, 'authorId._id')}/artworks/${_.get(artwork, '_id')}`} style={{ color: 'inherit', textDecoration: 'none' }}>
              <Card variant='grey' sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                {
                  _.get(artwork, 'image.thumbnailBase64') ?
                    <CardMedia
                      component="img"
                      height={150}
                      src={_.get(artwork, 'image.thumbnailBase64')}
                      alt={_.get(artwork, 'image.filename')}
                      sx={{ flex: '0 0 150px' }}
                    // style={{ objectFit: 'contain' }} use this if the images shouldn't be cropped
                    />
                    :
                    <Skeleton variant="rectangular" height={150} sx={{ borderRadius: '10px', flex: '0 0 150px' }} />
                }
                <Box sx={{ padding: 0, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                  <CardContent sx={{ paddingBottom: 0, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                    <Typography gutterBottom variant="h4" component="div" sx={{ alignSelf: 'flex-start' }}>
                      {getInputValue({ inputName: 'title', value: _.get(artwork, 'title') })}
                    </Typography>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
                      <Typography variant="body2" color="text.secondary">{getArtworkYear(_.get(artwork, 'year'))}</Typography>
                      <Typography variant="body2" color="text.secondary">{getArtworkDimensions(_.get(artwork, 'dimensions'))}</Typography>
                      <Typography variant="body2" color="text.secondary">{getInputValue({ inputName: 'technique', value: _.get(artwork, 'technique.name') })}</Typography>
                      <Typography variant="body2" color="text.secondary">{getInputValue({ inputName: 'material', value: _.get(artwork, 'material.name') })}</Typography>
                      <Typography variant="body2" color="text.secondary">{getInputValue({ inputName: 'medium', value: _.get(artwork, 'medium.name') })}</Typography>
                    </Box>
                  </CardContent>
                  <CardActions sx={{ p: 0, marginBottom: 1 }}>
                    <Button variant='child' type='button' component={Link} to={`/people/${_.get(artwork, 'authorId._id')}/artworks/${_.get(artwork, '_id')}`} >View Artwork</Button>
                  </CardActions>
                </Box>
              </Card>
            </Link>
          </Grid>
        )}
      </Grid>

      <Typography variant='h3' sx={{ marginBottom: 1 }}>Exhibitions</Typography>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:12 }}>
          <Stack direction='row' spacing={1}>
            {_.map(locationResources?.exhibitions, (exhibition: any) => <CustomClickableChip label={exhibition.title} linkTo={`/exhibitions/${exhibition._id}`} />)}
          </Stack>
        </Grid>
      </Grid>

      <Typography variant='h3' sx={{ marginBottom: 1 }}>Publications</Typography>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:12 }}>
          <Stack direction='row' spacing={1}>
            {_.map(locationResources?.publications, (publication: any) => <CustomClickableChip label={publication.title} linkTo={`/publication/${publication._id}`} />)}
          </Stack>
        </Grid>
      </Grid>
    </>
  )
}

export default withResourceGuard([RESOURCE_LOCATION])(LocationList);