import React from 'react';
import { Chip, ChipProps } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props extends ChipProps {
    label: string;
    linkTo: string;
    key?: string;
}

const CustomClickableChip: React.FC<Props> = (props) => {
    const chip = props.label ? <Chip size='small' clickable={true} key={props.key || `chip-0`} variant="outlined" sx={{ color: 'inherit' }} component={Link} to={props.linkTo} {...props} /> : null;
    
    return (chip);
}

export default CustomClickableChip;