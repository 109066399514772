import _ from 'lodash';
import React from 'react';
import { Typography } from '@mui/material';
import { useArtworksList } from '../../../../api/artwork';

import CustomCard from '../CustomCard';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../../../utils/constants';
import withResourceGuard from '../../../authentication/ResourceGuard';

type Props = {
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean; 
}

const ArtworksCard: React.FC<Props> = (props) => {
    const { data: artworks } = useArtworksList();

    return (
        <CustomCard
            title={{ title: 'Artworks', linkTo: '/artworks/list' }}
            content={
                <Typography variant='body1'><i>the collection has {_.size(artworks)} artworks</i></Typography>
            }
            headerActionsDisabled={props.headerActionsDisabled}
            footerActionsDisabled={props.footerActionsDisabled}
        />
    )
}

export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(ArtworksCard);