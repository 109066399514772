import _ from 'lodash';
import React from 'react';
import ArtworkFormModal from '../../modals/artwork/ArtworkFormModal';
import AuthorFormModal from '../../modals/author/AuthorFormModal';
import { IAuthor } from '../../../../../../../common/interfaces/IData';
import { useDeleteAuthor } from '../../../../api/people';
import DeleteModal from '../../modals/DeleteModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../../../utils/constants';
import { useArtworksByAuthorList } from '../../../../api/artwork';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type AuthorCardProps = {
    author: IAuthor,
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
    authorDescription?: string;
}

const AuthorCard = (props: AuthorCardProps) => {
    const { author } = props;

    const deleteAuthor = useDeleteAuthor();
    const { data: artworks } = useArtworksByAuthorList({ authorId: _.get(author, '_id') });

    const artworkOptions = getOptionsWithLabel(artworks, { value: '_id', label: 'title' });

    return (
        <CustomCard
            title={{ title: _.get(author, 'name'), linkTo: `/people/${_.get(author, '_id')}` }}
            content={props.authorDescription ? props.authorDescription : _.get(author, 'description')}
            headerActionsDisabled={props.headerActionsDisabled}
            headerActions={
                <SmallMenu>
                    <AuthorFormModal action='update' authorId={_.get(author, '_id')} buttonFullWidth={true} buttonText='Update Author' defaultValues={author} dialogTitle='Update Author' />
                    <DeleteModal action='delete' actionFunction={deleteAuthor} resourceTitle={_.get(author, 'name')} resourceDeletionData={{ authorId: _.get(author, '_id') }} buttonFullWidth={true} buttonText='Delete Author' dialogTitle='Delete Author' />
                    <ShareResourceWithUserModal
                        resource={RESOURCE_ARTWORK}
                        resourceOptions={artworkOptions}
                        buttonFullWidth={true}
                        buttonText='Share Artworks'
                        dialogTitle='Share Artworks'
                    />
                </SmallMenu>
            }
            footerActionsDisabled={props.footerActionsDisabled}
            footerActionLink={
                <ArtworkFormModal authorId={_.get(author, '_id')} action='create' buttonClassName='align-self-end' buttonText='Add New Artwork' dialogTitle='Add New Artwork'></ArtworkFormModal>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_AUTHOR])(AuthorCard);