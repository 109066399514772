import _ from 'lodash';
import React, { useState, useMemo } from 'react';

import { RESOURCE_EXHIBITION } from '../../utils/constants';
import { getNormalizedString, getOptionsWithLabel } from '../../utils/helpers';
import { useExhibitionDetail } from '../../api/exhibition';
import { Card, CardHeader, CardContent, CardMedia, Skeleton, Box, Typography, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { CollectionsOutlined, GridOnOutlined, NotesOutlined, PhotoOutlined } from '@mui/icons-material';

import CustomCard from '../parts/cards/CustomCard';
import ExhibitionCard from '../parts/cards/exhibition/ExhibitionCard';
import { Link, useParams } from 'react-router-dom';
import withResourceGuard from '../authentication/ResourceGuard';

import ExhibitionLocationCard from '../parts/cards/location/ExhibitionLocationCard';
import CustomClickableChip from '../parts/CustomClickableChip';
import CustomDataGrid from '../parts/CustomDataGrid';
import ArtworkListImageView from '../parts/cards/artwork/ArtworkListImageView';
import ArtworkListCardView from '../parts/cards/artwork/ArtworkListCardView';
import AddArtworksToExhibition from '../parts/modals/exhibition/AddArtworksToExhibition';
import { useAuthors } from '../../api/people';
import { useArtworksList } from '../../api/artwork';
import { getInputValue, getArtworkDimensions, getArtworkYear, getDistinctFilterOptionsAtKey, getFilteredDataFromSearch2 } from '../../utils/helpers';

// TODO: Exhibition Detail, Images, Artwork List, Author List

const ExhibitionDetail: React.FC = (props: any) => {
  const [searchString, setSearchString] = useState('');
  const [artworksContentView, setArtworksContentView] = React.useState<string | null>('card');

  const { exhibitionId } = useParams();

  const { data: exhibition } = useExhibitionDetail(exhibitionId);
  const { data: authors } = useAuthors();
  const { data: artworks } = useArtworksList();
  const defaultArtworkOptions = _.castArray(getOptionsWithLabel(_.get(exhibition, 'artworkIds', []), { value: '_id', label: ['authorId.name', 'title', 'year.createdStart'] })).sort((a, b) => a.label.localeCompare(b.label, "cs", { sensitivity: "base" }));
  const artworkOptions = _.castArray(getOptionsWithLabel(artworks, { value: '_id', label: ['authorId.name', 'title', 'year.createdStart'] })).sort((a, b) => a.label.localeCompare(b.label, "cs", { sensitivity: "base" }));

  // const exhibitionAuthors = _.get(exhibition, 'authorIds', []); this will be reworker with the data model change
  const exhibitionArtworks = useMemo(() =>
    _.map(_.get(exhibition, 'artworkIds', []), (artwork) => {
      return {
        _id: _.get(artwork, '_id'),
        image: _.get(artwork, 'image.thumbnailBase64'),
        title: _.get(artwork, 'title'),
        author: _.get(artwork, 'authorId.name'),
        medium: _.get(artwork, 'medium.name'),
        material: _.get(artwork, 'material.name'),
        technique: _.get(artwork, 'technique.name'),
        year: _.get(artwork, 'year'),
        dimensions: _.get(artwork, 'dimensions'),
        condition: _.get(artwork, 'condition'),
        linkTo: {
          label: 'View Artwork',
          path: `/people/${_.get(artwork, 'authorId._id')}/artworks/${_.get(artwork, '_id')}`
        }
      }
    }).sort((a, b) => a.title.localeCompare(b.title, "cs", { sensitivity: "base" }))
    , [exhibition]);

  const exhibitionAuthors = _.uniqBy(_.map(_.get(exhibition, 'artworkIds', []), (artwork) => _.get(artwork, 'authorId')), '_id');

  const handleSetArtworksContentView = (event: React.MouseEvent<HTMLElement>, newContentView: string | null) => {
    setArtworksContentView(newContentView);
  };

  let contentViewComponent = null;

  switch (artworksContentView) {
    case 'card': {
      contentViewComponent = <ArtworkListCardView artworks={exhibitionArtworks} />
      break;
    }
    case 'image': {
      contentViewComponent = <ArtworkListImageView artworks={exhibitionArtworks} />;
      break;
    }
    case 'list': {
      contentViewComponent = <CustomDataGrid data={exhibitionArtworks} density='comfortable' />;
      break;
    }
    default: {
      contentViewComponent = (<ArtworkListCardView artworks={exhibitionArtworks} />)
    }
  }

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>

        <Grid size={{ xs:12, md:12 }}>
          <ExhibitionCard exhibition={exhibition}></ExhibitionCard>
        </Grid>

        <Grid size={{ xs:12, md:12 }}>
          <Card variant='grey'>
            <CardHeader
              title={<Typography variant="h3">Notes</Typography>}
            />
            <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
              <Typography variant="body1">{getInputValue({ inputName: 'exhibition notes', value: _.get(exhibition, 'notes') })}</Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs:12, md:12 }}>
          <ExhibitionLocationCard resourceId={_.get(exhibition, '_id')} resource={RESOURCE_EXHIBITION} locationId={_.get(exhibition, 'locationId')}></ExhibitionLocationCard>
        </Grid>

        <Grid size={{ xs:12, md:12 }}>
          <CustomCard
            variant='grey'
            title={{ title: 'Authors' }}
            footerActionsDisabled={true}
            content={
              <Stack direction='row' spacing={1}>
                {_.map(exhibitionAuthors, (author: any) => <CustomClickableChip label={author?.name} linkTo={`/people/${author?._id}`} />)}
              </Stack>
            }
          >
          </CustomCard>
        </Grid>

        <Grid size={{ xs:12, md:12 }}>
          <CustomCard
            variant='grey'
            title={{ title: 'Artworks' }}
            headerActions={
              <Stack direction='row' spacing={1}>
                <ToggleButtonGroup
                  size="small"
                  value={artworksContentView}
                  exclusive
                  onChange={handleSetArtworksContentView}
                  aria-label="text alignment"
                  color="primary"
                  sx={{
                    border: 0,
                  }}
                >
                  <ToggleButton value="image" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><PhotoOutlined /></ToggleButton>
                  <ToggleButton value="card" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><CollectionsOutlined /></ToggleButton>
                  <ToggleButton value="list" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><GridOnOutlined /></ToggleButton>
                </ToggleButtonGroup>
                <AddArtworksToExhibition buttonFullWidth={true} buttonText='Update Artworks' dialogTitle={`Update Exhibition ${_.get(exhibition, 'title')} Artworks`} artworkOptions={artworkOptions} defaultValues={defaultArtworkOptions} exhibitionId={exhibitionId} />
              </Stack>
            }
            content={
              <>
                {contentViewComponent}
              </>
            }
          ></CustomCard>
        </Grid>

      </Grid >
    </>
  )
}

export default withResourceGuard([RESOURCE_EXHIBITION])(ExhibitionDetail);