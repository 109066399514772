import React from 'react';
import { TableCell } from '@mui/material';

const CustomTableCellCenter = (props: any) => {
    const { children, ...otherProps } = props;
    return (
        <TableCell sx={{ textAlign: 'center' }} {...otherProps}>{children}</TableCell>
    )
}

export default CustomTableCellCenter;