import _ from 'lodash';
import React from 'react';
import LocationFormModal from '../../modals/location/LocationFormModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_LOCATION } from '../../../../utils/constants';
import { useLocations } from '../../../../api/location';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Typography } from '@mui/material';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type LocationsCardProps = {
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const LocationsCard = (props: LocationsCardProps) => {
    const { data: locations } = useLocations();
    const locationOptions = getOptionsWithLabel(locations, { value: '_id', label: 'title' });

    return (
        <CustomCard
            title={{ title: 'Locations', linkTo: '/locations/list' }}
            content={<Typography variant='body1'><i>the collection has {_.size(locations)} locations</i></Typography>}
            footerActionLink={
                <LocationFormModal action='create' buttonClassName='align-self-start' buttonFullWidth={false} buttonText='Create Location' dialogTitle='Create Location'></LocationFormModal>
            }
            headerActionsDisabled={props.headerActionsDisabled}
            footerActionsDisabled={props.footerActionsDisabled}
            headerActions={
                <SmallMenu>
                    <ShareResourceWithUserModal
                        resource={RESOURCE_LOCATION}
                        resourceOptions={locationOptions}
                        buttonFullWidth={true}
                        buttonText='Share Locations'
                        dialogTitle='Share Locations'
                    />
                </SmallMenu>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_LOCATION])(LocationsCard);