import _ from 'lodash';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { ButtonGroup, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { signatureLocationList } from '../../../../utils/constants';

type SignatureBoxProps = {
  signatureLocation: string,
  disabled: boolean,
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const SignatureBox = (props: SignatureBoxProps) => {
  const theme = useTheme();

  return (
      <ButtonGroup variant="text" aria-label="outlined button group" style={{ display: 'grid', border: `1px solid ${theme.palette.text.primary }`, gridTemplateColumns: 'repeat(3, 1fr)', width: '225px' }} sx={{ mx: 'auto' }}>
        {_.map(signatureLocationList, (location, index) => (
          <Button
            key={index}
            style={{
              width: '75px',
              height: '75px',
              backgroundColor: props.signatureLocation === location.value ? theme.palette.primary.light : '',
              fontSize: '0.7rem',
              borderColor: 'transparent',
              color: theme.palette.text.primary
            }}
            value={location.value}
            onClick={props.onClick}
            disabled={props.disabled}
          >
            {_.startCase(location.value)}
          </Button>
        ))}
      </ButtonGroup>
  )
};


export default SignatureBox;