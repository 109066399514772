import _ from 'lodash';
import React from 'react';
import { useAuth } from '../../store/zustand';

type UserRoles = ('GUEST' | 'EDITOR' | 'ADMIN' | 'MASTER')[];

type Props = {
    allowedRoles: UserRoles
    children: React.ReactNode
}

const UserRoleGuard: React.FC<Props> = ({ allowedRoles, children }) => {
    const user = useAuth.getState().user;
    const roleName = _.get(user, 'roleName', '');

    if (_.includes(allowedRoles, roleName)) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return null;
    }
};

const withUserRoleGuard = (allowedRoles: UserRoles) => (WrappedComponent: React.ComponentType<Props>) => (
    props: Props
) => <UserRoleGuard allowedRoles={allowedRoles}>{<WrappedComponent {...props} />}</UserRoleGuard>;

export default withUserRoleGuard;