import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ListItem } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { IArtwork } from '../../../../../../../common/interfaces/IData';
import { useDeleteArtwork } from '../../../../api/artwork';
import { getArtworkDimensions, getArtworkYear, getOptionsWithLabel } from '../../../../utils/helpers';

import ArtworkFormModal from '../../modals/artwork/ArtworkFormModal';
import CustomCard from '../CustomCard';
import DeleteModal from '../../modals/DeleteModal';
import FileUploadFormModal from '../../modals/image/FileUploadFormModal';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../../../utils/constants';
import withResourceGuard from '../../../authentication/ResourceGuard';
import ExportModal from '../../modals/ExportModal';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type ArtworkCardProps = {
    artwork: IArtwork,
    qrcode?: React.ReactNode
}

const ArtworkCard = (props: ArtworkCardProps) => {
    const { artwork, qrcode } = props;
    const author = _.get(artwork, 'authorId');
    const navigate = useNavigate();
    const deleteArtwork = useDeleteArtwork();
    const artworkOptions = getOptionsWithLabel(artwork, { value: '_id', label: 'title' });
    const qrUrl = `/people/${_.get(author, '_id')}/artworks/${_.get(artwork, '_id')}`;
    const artworkSelectData = _.pick(artwork, ['title', 'year', 'dimensions', 'technique', 'material', 'medium', 'notes', 'accessories', 'signature', 'condition', 'images']);
    const artworkDataToPrint = { author: _.get(author, 'name'), ...artworkSelectData };
    return (
        <CustomCard
            title={{ title: _.get(artwork, 'title') }}
            subtitle={_.get(author, 'name')}
            content={
                <Grid container sx={{ height: '100%', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                    <Grid>
                        <ListItem>{getArtworkYear(_.get(artwork, 'year'))}</ListItem>
                        <ListItem>{getArtworkDimensions(_.get(artwork, 'dimensions'))}</ListItem>
                        <ListItem>{_.join(_.compact([_.get(artwork, 'technique.name'), _.get(artwork, 'material.name'), _.get(artwork, 'medium.name')]), ', ')}</ListItem>
                    </Grid>
                    <Grid>
                    {qrcode}
                    </Grid>
                </Grid>
            }
            footerActionsDisabled={true}
            headerActions={
                <SmallMenu>
                    <FileUploadFormModal
                        buttonText='Add Images'
                        dialogTitle='Add Images'
                        buttonFullWidth={true}
                        fileType='image'
                        authorId={_.get(author, '_id')}
                        artworkId={_.get(artwork, '_id')}
                    />
                    <ShareResourceWithUserModal
                        resource={RESOURCE_ARTWORK}
                        resourceOptions={artworkOptions}
                        resourceDisabled={true}
                        defaultValues={{ resourceId: artworkOptions }}
                        buttonFullWidth={true}
                        buttonText='Share Artwork'
                        dialogTitle='Share Artwork'
                    />
                    <ExportModal
                        data={artworkDataToPrint}
                        qrUrl={qrUrl}
                        buttonText='Export Artwork'
                        dialogTitle={`Export ${_.get(artwork, 'title')}`}
                        buttonFullWidth={true}
                    />
                    <ArtworkFormModal
                        artworkId={_.get(artwork, '_id')}
                        buttonFullWidth={true}
                        defaultValues={artwork} action='update'
                        buttonClassName='align-self-end'
                        buttonText='Update Artwork'
                        dialogTitle='Update Artwork'
                    />
                    <DeleteModal 
                        action='delete'
                        actionFunction={deleteArtwork}
                        resourceTitle={_.get(artwork, 'title')}
                        resourceDeletionData={{ authorId: _.get(author, '_id'), artworkId: _.get(artwork, '_id'), navigate }}
                        buttonFullWidth={true}
                        buttonText='Delete Artwork'
                        dialogTitle='Delete Artwork' 
                    />
                </SmallMenu>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(ArtworkCard);