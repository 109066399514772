import _ from 'lodash';
import React, { useMemo } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, ButtonGroup, Card, CardMedia, CardContent, CardActions, Typography, Skeleton, Box, Stack, ToggleButton, ToggleButtonGroup, toggleButtonGroupClasses, styled, CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid2';
import HideImageIcon from '@mui/icons-material/HideImage';

import { getArtworkDimensions, getArtworkYear, getDistinctFilterOptionsAtKey, getInputValue, getFilteredDataFromSearch2 } from '../../utils/helpers';
import { useArtworksByAuthorImages } from '../../api/artwork';
import { useAuthors } from '../../api/people';
import AuthorCard from '../parts/cards/author/AuthorCard';
import SearchCard from '../parts/cards/SearchCard';

import withResourceGuard from '../authentication/ResourceGuard';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../utils/constants';
import { CollectionsOutlined, GridOnOutlined, NotesOutlined, PhotoOutlined } from '@mui/icons-material';

import CustomDataGrid from '../parts/CustomDataGrid';
import ArtworkListImageView from '../parts/cards/artwork/ArtworkListImageView';
import ArtworkListCardView from '../parts/cards/artwork/ArtworkListCardView';

import FulltextSearchCard from '../parts/cards/FulltextSearchCard';
import { useForm, useWatch } from 'react-hook-form';

const ArtworksByAuthor = (props: any) => {
  const [contentView, setContentView] = React.useState<string | null>('card');

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newContentView: string | null) => {
    setContentView(newContentView);
  };

  const { authorId } = useParams();

  const { data: artworks } = useArtworksByAuthorImages({ authorId });
  const { data: authors } = useAuthors();
  const author = _.find(authors, { _id: authorId });

  const artworkData = useMemo(() =>
    _.map(artworks, (artwork) => {
      return {
        _id: _.get(artwork, '_id'),
        image: _.get(artwork, 'image.thumbnailBase64'),
        title: _.get(artwork, 'title'),
        author: _.get(artwork, 'authorId.name'),
        medium: _.get(artwork, 'medium.name'),
        material: _.get(artwork, 'material.name'),
        technique: _.get(artwork, 'technique.name'),
        year: _.get(artwork, 'year'),
        dimensions: _.get(artwork, 'dimensions'),
        condition: _.get(artwork, 'condition'),
        linkTo: {
          label: 'View Artwork',
          path: `/people/${authorId}/artworks/${_.get(artwork, '_id')}`
        }
      }
    }).sort((a, b) => a.title.localeCompare(b.title, "cs", { sensitivity: "base" }))
    ,[artworks, authorId]);

  const distinctValuesAtKey = getDistinctFilterOptionsAtKey({ data: artworkData, excludedFieldsArray: ['linkTo', 'image'] });

  const { control } = useForm();

  const search = useWatch({ control, name: 'search' });
  const searchFilters = useWatch({ control, name: 'searchFilter' });

  const areAllFiltersValid = (filters: any) => _.every(filters, (filter) => filter && filter.field && filter.operation);

  const filteredData = useMemo(() => {
    if (areAllFiltersValid(searchFilters)) {
      return getFilteredDataFromSearch2(artworkData, search, searchFilters);
    } else {
      return artworkData;
    }
  }, [artworkData, search, searchFilters]);

  let contentViewComponent = null;

  switch (contentView) {
    case 'card': {
      contentViewComponent = <ArtworkListCardView artworks={filteredData} />
      break;
    }
    case 'image': {
      contentViewComponent = <ArtworkListImageView artworks={filteredData} />;
      break;
    }
    case 'list': {
      contentViewComponent = <CustomDataGrid data={filteredData} density='comfortable' />;
      break;
    }
    default: {
      contentViewComponent = (<ArtworkListCardView artworks={filteredData} />)
    }
  }

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4}}>
          <AuthorCard author={author} />
        </Grid>

        <Grid size={{ xs:12, md:8}}>
          <FulltextSearchCard
            control={control}
            options={distinctValuesAtKey}
            SearchCardActionsLeft={
              <ToggleButtonGroup
                size="small"
                value={contentView}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                color="primary"
                sx={{
                  border: 0,
                }}
              >
                <ToggleButton value="image" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><PhotoOutlined /></ToggleButton>
                <ToggleButton value="card" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><CollectionsOutlined /></ToggleButton>
                <ToggleButton value="list" sx={{ border: 0, color: 'inherit', borderRadius: '10px!important' }}><GridOnOutlined /></ToggleButton>
              </ToggleButtonGroup>
            }
          />
        </Grid>
      </Grid>



      {contentViewComponent}
    </>
  )
}

export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(ArtworksByAuthor);