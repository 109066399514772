import React, { FC } from 'react';
import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { useIDBPersister } from './app/store/persister';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ThemeProvider } from '@mui/material/styles';

import { theme } from './app/styles/custom-theme';
import AuthenticatedLayout from './app/components/layouts/AuthenticatedLayout';

import HomePage from './app/components/pages/HomePage';
import ArtworksByAuthor from './app/components/pages/ArtworksByAuthor';
import ArtworkDetail from './app/components/pages/ArtworkDetail';
import PublicLayout from './app/components/layouts/PublicLayout';
import LoginForm from './app/components/parts/forms/user/LoginForm';
import Settings from './app/components/pages/Settings';
import Permissions from './app/components/pages/Permissions';
import AuthorList from './app/components/pages/AuthorList';
import ArtworkList from './app/components/pages/ArtworkList';
import ExhibitionList from './app/components/pages/ExhibitionList';
import ExhibitionDetail from './app/components/pages/ExhibitionDetail';
import ErrorPage from './app/components/pages/ErrorPage';
import LocationList from './app/components/pages/LocationList';
import LocationDetail from './app/components/pages/LocationDetail';
import LoginCard from './app/components/parts/cards/user/LoginCard';

const App: FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: 1000 * 60 * 60 * 1, // 1 hour
      },
    },
  });

  const persister = useIDBPersister();

  const router = createBrowserRouter([
    {
      element: <PublicLayout />,
      children: [
        { path: '*', element: <Navigate to='/login' replace /> },
        { path: '/login', element: <LoginCard /> },
      ]
    },
    { 
      element: <AuthenticatedLayout />,
      errorElement: <ErrorPage />,
      children: [
        { path: '/', element: <HomePage /> },
        { path: 'people/list', element: <AuthorList /> },
        { path: 'artworks/list', element: <ArtworkList /> },
        { path: 'exhibitions/list', element: <ExhibitionList /> },
        { path: 'exhibition/:exhibitionId', element: <ExhibitionDetail /> },
        { path: 'locations/list', element: <LocationList /> },
        { path: 'locations/:locationId', element: <LocationDetail /> },
        { path: 'settings/:userId/permissions', element: <Permissions /> },
        { path: 'people/:authorId', element: <ArtworksByAuthor /> },
        { path: 'people/:authorId/artworks/:artworkId', element: <ArtworkDetail /> },
        { path: 'settings', element: <Settings />  },
      ]
    }
  ], { basename: "/"});

return (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}>
    <ReactQueryDevtools initialIsOpen={false} />
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </PersistQueryClientProvider>
);
}

export default App;
