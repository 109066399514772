import _ from 'lodash';
import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import QRCode from 'qrcode';
import urlJoin from 'url-join';
import { BASE_URL } from '../../../utils/constants';

type Props = {
    width?: number;
    qrData?: object;
    qrUrl?: string;
}

const QRCodeImage = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [QRCodeDataUrl, setQRCodeDataUrl] = useState('');
    const location = useLocation();

    const currentLocation = location.pathname;
    const qrUrl = props.qrUrl ? props.qrUrl : currentLocation;
    const fullURL = useMemo(() => urlJoin(BASE_URL, qrUrl), [BASE_URL, qrUrl]);
    const { qrData } = props;
    const width = props.width ? props.width : 200; 

    useEffect(() => {
        const getQRCodeDataURL = async (url: string, qrData?: object) => {
            try {
                const result = {
                    url,
                    ...qrData
                }
                const dataURL = await QRCode.toDataURL(JSON.stringify(result), {
                    margin: 1.5
                });
                setQRCodeDataUrl(dataURL)
            } catch (e) {
                console.error('Error generating QR Code', e);
            } finally {
                setLoading(false);
            }
        };

        getQRCodeDataURL(fullURL, qrData);
    }, [fullURL, qrData]);

    if (!loading) {
        return (
            <Box
            component="img"
            sx={{
                width: width,
                borderRadius: '6px',
            }}
            src={QRCodeDataUrl}
            alt="QR Code"
        />
        )
    } else {
        return (
            <></>
        )
    }

}

export default QRCodeImage;