import React from 'react';
import { Container } from '@mui/material';
import Alerts from '../parts/Alerts';
import { Outlet } from "react-router-dom";

const PublicLayout: React.FC = () => {
    return (
        <>
        <Alerts></Alerts>
        <Container className='basic-layout' sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Outlet />
        </Container>
        </>
    )
}

export default PublicLayout;

