import React from 'react';
import { Chip } from '@mui/material';

type Props = {
    label: string;
}

const CustomChip: React.FC<Props> = (props) => {
    const chip = props.label ? <Chip size='small' variant="outlined" sx={{ color: 'inherit' }} {...props} /> : null;
    
    return (chip);
}

export default CustomChip;