import _ from 'lodash';
import React from 'react';
import CustomTextField from '../inputs/CustomTextField';

import { USER_ROLE_ADMIN, USER_ROLE_GUEST, USER_ROLE_MASTER, USER_ROLE_EDITOR } from '../../../../utils/constants';
import { Control } from 'react-hook-form';

const userRoles = [
  { value: USER_ROLE_GUEST, label: _.capitalize(USER_ROLE_GUEST) },
  { value: USER_ROLE_EDITOR, label: _.capitalize(USER_ROLE_EDITOR) },
  { value: USER_ROLE_ADMIN, label: _.capitalize(USER_ROLE_ADMIN) },
  { value: USER_ROLE_MASTER, label: _.capitalize(USER_ROLE_MASTER) },
];

type Props = {
  control: Control<any>;
}

const UserFormRole: React.FC<Props> = ({ control }) => {
  return (
    <CustomTextField
      name='roleName'
      label='User Role'
      options={userRoles}
      variant='standard'
      fullWidth={true}
      control={control}
      required={true}
    />
  )
}

export {
  UserFormRole
};