import _ from 'lodash';
import { del } from "idb-keyval";
import axios, { AxiosResponse } from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { ARTWORKS_CACHE_STORAGE_KEY, USER_DATA_KEY, USER_LOCAL_STORAGE_KEY } from '../utils/constants';
import { LoginFormData } from '../types/forms';
import { useAlert, useAuth } from '../store/zustand';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { IUser } from '../../../../common/interfaces/IData';
import { useNavigate } from 'react-router-dom';
import { useIDBPersister } from '../store/persister';

type CreateUser = {
  name: string;
  email: string;
  password: string;
  roleName: string;
}

type UpdateUserProfile = {
  userId: string | undefined,
  userData: IUser
}

type UpdateUserPassword = {
  userId: string | undefined,
  password: string | undefined
}

type DeleteUserData = {
  userId: string | undefined,
}

const userLogin = async (user: LoginFormData): Promise<string | undefined> => {
  const axiosConfig = getAxiosConfig();

  const { email, password } = user;
  if (!email || !password) return;
  const { data: token }: AxiosResponse<string> = await axios.post(`/user/login`, { user }, axiosConfig);

  return token;
}

const createUser = async (user: CreateUser): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { name, email, password, roleName } = user;

  const data = {
    user: {
      name, email, password, roleName
    }
  };

  return await axios.post('/user/register', data, axiosConfig);
}

const getUsers = async (): Promise<IUser[]> => {
  const axiosConfig = getAxiosConfig();

  const { data }: { data: IUser[] } = await axios.get('/user/find',
    axiosConfig
  );
  return data;
}

const getUser = async (userId: string | undefined): Promise<IUser> => {
  const axiosConfig = getAxiosConfig();

  const { data }: { data: IUser } = await axios.get(`/user/find/${userId}`,
    axiosConfig
  );
  return data;
}

const updateUserProfile = async (payload: UpdateUserProfile): Promise<void> => {
    const { userId, userData } = payload;

    const axiosConfig = getAxiosConfig();

    return axios.put(`/user/${userId}/profile`, { user: { ...userData } }, axiosConfig);
}

const updateUserPassword = async (payload: UpdateUserPassword): Promise<void> => {
    const { userId, password } = payload;
    const axiosConfig = getAxiosConfig();

    return axios.put(`/user/${userId}/password/`, { user: { password } }, axiosConfig);
}

const deleteUser = async (payload: DeleteUserData): Promise<void> => {
    const { userId } = payload;
    const axiosConfig = getAxiosConfig();
    return await axios.delete(`/user/${userId}`, axiosConfig);
}

export const useCreateUser = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (createUserData: CreateUser) => apiRequestPromise.handleApiRequestPromise(createUser, createUserData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['artworks'] });
      queryClient.invalidateQueries({ queryKey: ['authors'] });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  })
}

export const useUserLogin = () => {
  const apiRequestPromise = useApiRequestPromise();

  return useMutation({
    mutationFn: async (user: LoginFormData) => { return await apiRequestPromise.handleApiRequestPromise(userLogin, user) },
    onSuccess: (token) => {
      const tokenPayload = token ? jwtDecode<JwtPayload>(token) : null;
      const userData = _.pick(tokenPayload, ['name', 'roleName', 'userId', 'email', 'allowedResources', 'allowedOperations']) as IUser;
      useAuth.getState().createToken(token);
      useAuth.getState().createUser(userData);
    }
  })
}

export const useUsers = () => {
  const apiRequestPromise = useApiRequestPromise();

  return useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      return apiRequestPromise.handleApiRequestPromise(getUsers)
    },
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 5 * 60 * 1000,
  });
}

export const useUserDetail = (userId: string | undefined) => {
  const apiRequestPromise = useApiRequestPromise();

  return useQuery({
      queryKey: ["users", userId],
      queryFn: async () => apiRequestPromise.handleApiRequestPromise(getUser, userId),
      enabled: !!userId
      // placeholderData: (previousData, previousQuery) => previousData,
      // staleTime: 5 * 60 * 1000,
  });
}

export const useUpdateUserProfile = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
      mutationFn: (updateUserData: UpdateUserProfile) => apiRequestPromise.handleApiRequestPromise(updateUserProfile, updateUserData),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'User profile updated successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['users'] });
      }
  })
}

export const useUpdateUserPassword = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
      mutationFn: (updateUserData: UpdateUserPassword) => apiRequestPromise.handleApiRequestPromise(updateUserPassword, updateUserData),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'User password updated successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['users'] });
      }
  })

}

export const useDeleteUser = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
      mutationFn: (deleteUserData: DeleteUserData) => apiRequestPromise.handleApiRequestPromise(deleteUser, deleteUserData),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'User deleted successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['users'] });
          navigate('/settings');
      }
  })
}

export const useUserLogout = () => {
  const queryClient = useQueryClient();
  const persister = useIDBPersister();
  
  const logout = async () => {
    useAuth.getState().deleteToken();
    useAuth.getState().deleteUser();
    queryClient.removeQueries();
    return await persister.removeClient();
  };

  return { logout };
}