import _ from 'lodash';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardMedia, Skeleton, Typography, CardContent, Box } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { getArtworkDimensions, getArtworkYear, getDistinctFilterOptionsAtKey, getInputValue, getFilteredDataFromSearch2 } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_ARTWORK, RESOURCE_AUTHOR } from '../../../../utils/constants';

import { IArtwork } from '../../../../../../../common/interfaces/IData';

const ArtworkListCardView: React.FC<{ artworks: IArtwork[] }> = ({ artworks }) => {
  return (
    <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 2 }}>
      {_.map(artworks, (artwork: any) =>
        <Grid size={{ xs:12, md:4 }}>
          <Link to={_.get(artwork, 'linkTo.path')} style={{ color: 'inherit', textDecoration: 'none' }}>
            <Card variant='grey' sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
              {
                _.get(artwork, 'image') ?
                  <CardMedia
                    component="img"
                    height={400}
                    src={_.get(artwork, 'image')}
                    alt={_.get(artwork, 'title')}
                    sx={{ flex: '0 0 400px' }}
                  // style={{ objectFit: 'contain' }} use this if the images shouldn't be cropped
                  />
                  :
                  <Skeleton variant="rectangular" height={400} sx={{ borderRadius: '10px', flex: '0 0 400px' }} />
              }
              <Box sx={{ padding: 0, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardContent sx={{ paddingBottom: 0, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
                  <Typography gutterBottom variant="h4" component="div" sx={{ alignSelf: 'flex-start', mb: 1 }}>
                    {getInputValue({ inputName: 'title', value: _.get(artwork, 'title') })}
                  </Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
                    <Typography variant="body2">{getArtworkYear(_.get(artwork, 'year'))}</Typography>
                    <Typography variant="body2">{getArtworkDimensions(_.get(artwork, 'dimensions'))}</Typography>
                    <Typography variant="body2">{getInputValue({ inputName: 'technique', value: _.get(artwork, 'technique') })}</Typography>
                    <Typography variant="body2">{getInputValue({ inputName: 'material', value: _.get(artwork, 'material') })}</Typography>
                    <Typography variant="body2">{getInputValue({ inputName: 'medium', value: _.get(artwork, 'medium') })}</Typography>
                  </Box>
                </CardContent>
              </Box>
            </Card>
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

  export default withResourceGuard([RESOURCE_ARTWORK, RESOURCE_AUTHOR])(ArtworkListCardView);