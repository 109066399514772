import _ from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';
import Grid from '@mui/material/Grid2';
import { Card, CardHeader, CardContent, Container, Typography } from '@mui/material';

import UserList from '../parts/UserList';

import { USER_ROLE_ADMIN, USER_ROLE_MASTER } from '../../utils/constants';
import { useAuth } from '../../store/zustand';
import UserCard from '../parts/cards/user/UserCard';
import CreateUserModal from '../parts/modals/user/CreateUserModal';
import CustomCard from '../parts/cards/CustomCard';

const Settings = (props: any) => {
    const loggedInUser = useAuth(state => state.user);

    if (_.includes([USER_ROLE_ADMIN, USER_ROLE_MASTER], _.get(loggedInUser, 'roleName'))) {
        return (
            <>
                <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
                    <Grid size={{ xs:12, md:12 }}>
                        <UserCard />
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                    <Grid size={{ xs:12, md:12 }}>
                        <CustomCard
                            title={{ title: 'Settings' }}
                            subtitle='for Users'
                            variant='grey'
                            headerActions={<CreateUserModal buttonText='Create User' dialogTitle='Create User' />}
                            footerActionsDisabled={true}
                            content={
                                <UserList></UserList>
                            }
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    return <Navigate to='/people/list' replace />;

}

export default Settings;