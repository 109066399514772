import _, { values } from 'lodash';
import React from 'react';
import { Autocomplete, AutocompleteProps, TextFieldProps, TextField, createFilterOptions } from '@mui/material';
import { useController, Control, UseFormSetValue } from 'react-hook-form';

export type AutocompleteOption = {
    value: any;
    label: string;
}

interface Props extends Omit<AutocompleteProps<any, boolean, boolean, boolean, React.ElementType>, 'renderInput' | 'options'> {
    name: string;
    label?: string | undefined;
    rules?: any;
    options:  AutocompleteOption | AutocompleteOption[] | undefined;
    required?: boolean;
    filterOptions?: any;
    filterSelectedOptions?: boolean;
    disabledOptions?: { label?: string, value: any }[];
    textFieldProps?: TextFieldProps;
    control: Control<any>;
    setValue?: UseFormSetValue<any>;
}

const CustomAutocomplete = ({ name, label, rules, options, filterOptions, filterSelectedOptions, textFieldProps, control, setValue, required, ...autocompleteProps }: Props) => {
    const {
        field,
        fieldState: { error },
    } = useController({
        name,
        control
    });

    const labelName = _.isString(label) ? _.startCase(label) : _.startCase(_.last(_.split(name, '.')));

    const isOptionEqualToValue = _.get(autocompleteProps, 'isOptionEqualToValue') ? _.get(autocompleteProps, 'isOptionEqualToValue')
        :
        (option: any, value: any) => {
            const optionValue = _.get(option, 'value', option);
            const valueValue = _.get(value, 'value', value);
            return _.isEqual(optionValue, valueValue)
        }

    const getFilterOptions = (options: any, params: any) => {
        // use specific filter if it's available
        if (!_.isEmpty(filterOptions)) {
            return filterOptions(options, params);
        }
        const filter = createFilterOptions<AutocompleteOption>();
        // if freesolo, suggest the creation of new value by appending it to options list
        if (autocompleteProps.freeSolo === true && setValue) {
            const filtered = filter(options, params);
            const { inputValue } = params;
            const isExisting = _.some(options, (option) => _.toLower(inputValue) === _.toLower(_.get(option, 'label')));
            if (inputValue !== '' && !isExisting) {
                filtered.push({
                    value: inputValue,
                    label: inputValue
                });
            }

            return filtered;
        }
        // return default filtering
        return filter(options, params);
    };

    const onInputChange = autocompleteProps.freeSolo && setValue ? (event: any, userInput: string) => setValue(name, userInput) : undefined;

    return (
        <Autocomplete
            {...autocompleteProps}
            value={field.value}
            onChange={(event: any, data: AutocompleteOption) => {
                field.onChange(data);
            }}
            onAbort={field.onBlur}
            multiple={autocompleteProps.multiple || false}
            options={_.castArray(options) || []}
            isOptionEqualToValue={isOptionEqualToValue}
            filterOptions={getFilterOptions}
            filterSelectedOptions={filterSelectedOptions}
            getOptionLabel={(option) => _.get(option, 'label', option)}
            getOptionDisabled={(option) => _.includes(_.get(autocompleteProps, 'disabledOptions'), option)}
            freeSolo={autocompleteProps.freeSolo || false}
            onInputChange={onInputChange}
            includeInputInList={autocompleteProps.freeSolo || autocompleteProps.includeInputInList || false}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...textFieldProps}
                    label={labelName}
                    inputRef={field.ref}
                    error={!!error}
                    helperText={error ? _.get(error, 'message') : null}
                    required={required ? field?.value?.length === 0 : false}
                    variant={textFieldProps?.variant || 'standard'}
                />
            )}
        />
    );
};

export default CustomAutocomplete;

/*
const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: FilterFieldsOption) => {
        const { labelTitle, labelDescription } = option;
        return _.toString([labelTitle, labelDescription]);
    }
});
*/