import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { downloadImage, useDeleteImage } from '../../../../api/image';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography, Chip, Stack, IconButton } from '@mui/material';
import { IImage } from '../../../../../../../common/interfaces/IData';
import { getFileSizeMB, getFormattedDate } from '../../../../utils/helpers';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';
import DeleteModal from '../DeleteModal';
import FileUpdateFormModal from './FileUpdateFormModal';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_EXPORT } from '../../../../utils/constants';
import ImageDownloadButton from '../../buttons/ImageDownloadButton';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

export type ImageModalProps = {
    image: IImage,
    imageList: IImage[]
}

const ImageModal = (props: ImageModalProps) => {
    const [show, setShow] = useState(false);
    const [currentImage, setCurrentImage] = useState<IImage>(props.image);
    const { image, imageList } = props;
    const photographerId = _.get(image, 'photographerId');
    const photographerOption = { label: _.get(photographerId, 'name'), value: _.get(photographerId, '_id') };

    const deleteImage = useDeleteImage();
    
    const setPreviousImage = () => {
        const currentIndex = _.findIndex(imageList, (i) => i._id === currentImage._id);
        if (currentIndex > 0) {
            setCurrentImage(imageList[currentIndex - 1]);
        }
    }

    const setNextImage = () => {
        const currentIndex = _.findIndex(imageList, (i) => i._id === currentImage._id);
        if (currentIndex < imageList.length - 1) {
            setCurrentImage(imageList[currentIndex + 1]);
        }
    }

    return (
        <>
            <img onClick={() => setShow(!show)} src={_.get(currentImage, 'thumbnailBase64')} className="currentImageThumbnail" alt={_.get(currentImage, 'filename')} style={{ borderRadius: '10px', height: 'auto', width: '100%', objectFit: 'cover' }}></img>
            <Dialog open={show} onClose={() => setShow(!show)} aria-labelledby='contained-modal-title-vcenter' fullScreen={true}>
                <DialogTitle>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Button onClick={() => setShow(!show)} color='inherit' size='small'>
                            Close
                        </Button>
                        <SmallMenu>
                            <ImageDownloadButton image={currentImage} />
                            <FileUpdateFormModal dialogTitle='Update Image' buttonText='Update Image' buttonFullWidth={true} defaultValues={currentImage}></FileUpdateFormModal>
                            <DeleteModal action='delete' actionFunction={deleteImage} resourceTitle={_.get(currentImage, 'filename')} resourceDeletionData={{ imageId: _.get(currentImage, '_id') }} buttonFullWidth={true} buttonText='Delete Image' dialogTitle='Delete Image' />
                        </SmallMenu>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ height: '100%' }}>
                    <Button color="inherit" startIcon={<ArrowBack/>} onClick={setPreviousImage}>PREVIOUS</Button>
                    <img src={_.get(currentImage, 'thumbnailBase64')} alt="..." className='currentImageModal'></img>
                    <Button color="inherit" endIcon={<ArrowForward/>} onClick={setNextImage}>NEXT</Button>
                    </Stack>
                </DialogContent>
                <Typography variant='body1' sx={{ padding: '1rem 2rem 1rem 2rem' }}>{_.get(currentImage, 'description')}</Typography>
                <Stack direction='row' spacing={2} justifyContent='start' alignItems='center' sx={{ padding: '1rem 2rem 2rem 2rem', overflow: 'auto' }}>
                    <Chip label={_.get(currentImage, 'photographerId.name', 'photographer unknown')} />
                    <Chip label={_.get(currentImage, 'filename')} />
                    <Chip label={getFileSizeMB(_.get(currentImage, 'size'))} />
                    <Chip label={_.get(currentImage, 'type')} />
                    <Chip label={getFormattedDate(_.get(currentImage, 'createdAt'))} />
                </Stack>
            </Dialog>
        </>
    );
};

export default ImageModal;