import _ from 'lodash';
import React from 'react';
import { Stack, InputAdornment, Card, CardHeader, CardContent } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useWatch, UseFormSetValue, Control } from 'react-hook-form';

import CustomTextField from '../inputs/CustomTextField';
import CustomBooleanSwitch from '../inputs/CustomBooleanSwitch';
import CustomRadio from '../inputs/CustomRadio';
import CustomCheckbox from '../inputs/CustomCheckbox';
import SignatureBox from './SignatureBox';

import { adjustmentList, conditionList, materialList, mediumList, signatureSidesList, techniqueList } from '../../../../utils/constants';

type ArtworkFormProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const ArtworkForm = ({ control, setValue }: ArtworkFormProps) => {
  const adjustmentSelected = useWatch({ name: 'artwork.adjustment.selected', control });
  const signatureSelected = useWatch({ name: 'artwork.signature.selected', control });
  const signatureLocation = useWatch({ name: 'artwork.signature.location', control });

  const selectSignatureLocation = React.useCallback((e: any) => {
    const value = e.target.value;
    setValue('artwork.signature.location', value);
  }, [setValue]);

  return (
    <Stack spacing={2}>
      <CustomTextField
        name='artwork.title'
        label='artwork title'
        type='string'
        required={true}
        variant='standard'
        control={control}
      />

      <div>
        <CustomTextField
          name='artwork.year.createdStart'
          label='year created start'
          type='number'
          required={true}
          variant='standard'
          control={control}
        />
        <CustomTextField
          name='artwork.year.createdEnd'
          label='year created end'
          type='number'
          variant='standard'
          control={control}
          InputProps={{
            endAdornment: (<InputAdornment position="end"><i>(yyyy)</i></InputAdornment>)
          }}
        />
      </div>

      <div>
        <CustomTextField
          name='artwork.dimensions.height'
          label='height'
          type='number'
          required={true}
          variant='standard'
          control={control}
        />
        <CustomTextField
          name='artwork.dimensions.width'
          label='width'
          type='number'
          required={true}
          variant='standard'
          control={control}
        />
        <CustomTextField
          name='artwork.dimensions.depth'
          label='depth'
          type='number'
          variant='standard'
          control={control}
          InputProps={{
            endAdornment: (<InputAdornment position="end"><i>(cm)</i></InputAdornment>)
          }}
        />
      </div>

      <div>
        <CustomTextField
          name='artwork.medium.name'
          label='Artwork Medium Type'
          type='string'
          options={mediumList}
          required={true}
          variant='standard'
          control={control}
          fullWidth
        />
        <CustomTextField
          name='artwork.medium.description'
          label='description of medium'
          type='string'
          variant='standard'
          control={control}
          fullWidth
          multiline
        />
      </div>

      <div>
        <CustomTextField
          name='artwork.material.name'
          label='artwork material type'
          type='string'
          options={materialList}
          variant='standard'
          control={control}
          fullWidth
        />
        <CustomTextField
          name='artwork.material.description'
          label='description of material'
          type='string'
          variant='standard'
          control={control}
          fullWidth
          multiline
        />
      </div>

      <div>
        <CustomTextField
          name='artwork.technique.name'
          label='artwork technique type'
          type='string'
          options={techniqueList}
          variant='standard'
          control={control}
          fullWidth
        />
        <CustomTextField
          name='artwork.technique.description'
          label='description of technique'
          type='string'
          variant='standard'
          control={control}
          fullWidth
          multiline
        />
      </div>

      <Card>
        <CardHeader
          action={<CustomBooleanSwitch
            name='artwork.signature.selected'
            label='Artwork Signature'
            control={control}
          />}
        />
        {signatureSelected === true ? (
          <CardContent>
            <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" padding={2}>
              <Grid size={{ xs:12, md:12, lg:6 }}>
                <Grid container direction="column" justifyContent="center">
                  <CustomRadio
                    name='artwork.signature.side'
                    label='signature side'
                    options={signatureSidesList}
                    control={control}
                  />
                  <CustomTextField
                    name='artwork.signature.description'
                    label='description of signature'
                    type='string'
                    variant='standard'
                    control={control}
                    multiline
                  />
                </Grid>
              </Grid>
              <SignatureBox disabled={false} signatureLocation={signatureLocation} onClick={selectSignatureLocation} />

            </Grid>
          </CardContent>
        ) : <></>}
      </Card>

      <CustomTextField
        name='artwork.backSideDescription'
        label='artwork back side description'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />

      <Card>
        <CardHeader
          action={<CustomBooleanSwitch
            name='artwork.adjustment.selected'
            label='Artwork Adjustment'
            control={control}
          />}
        />
        {adjustmentSelected === true ? (
          <CardContent>
            <CustomCheckbox
              name='artwork.adjustment.options'
              label='adjustment options'
              options={adjustmentList}
              control={control}
              setValue={setValue}
            />
            <CustomTextField
              name='artwork.adjustment.description'
              label='artwork adjustment description'
              type='string'
              variant='standard'
              control={control}
              fullWidth
              multiline
            />
          </CardContent>
        ) : <></>}
      </Card>

      <CustomTextField
        name='artwork.accessories'
        label='artwork accessories'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />

      <div>
        <CustomTextField
          name='artwork.condition.name'
          label='artwork condition'
          type='string'
          options={conditionList}
          required={true}
          variant='standard'
          control={control}
          fullWidth
        />
        <CustomTextField
          name='artwork.condition.description'
          label='artwork condition description'
          type='string'
          variant='standard'
          control={control}
          fullWidth
          multiline
        />
      </div>

      <CustomTextField
        name='artwork.notes'
        label='any other notes on artwork...'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />
    </Stack>
  )
};


export default ArtworkForm;