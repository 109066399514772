import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import LocationForm from '../../forms/location/LocationForm';
import FormModal from '../FormModal';
import { useCreateLocation, useUpdateLocation } from '../../../../api/location';

import { ILocation } from '../../../../../../../common/interfaces/IData';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';
import { getAutocompleteValue, getOptionsWithLabel, getUpdatedFormFields } from '../../../../utils/helpers';
import _ from 'lodash';

interface LocationFormProps {
  action: string,
  locationId: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any
  dialogTitle: string,
}

const LocationFormModal = (props: LocationFormProps) => {
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, getValues, control, reset, formState: { isSubmitting, dirtyFields } } = useForm<ILocation>();

  const createLocation = useCreateLocation();
  const updateLocation = useUpdateLocation();

  const handleOpen = () => {
    if (props.action === 'update') {
      const { belongsToLocationIds, ...defaultValues } = props.defaultValues;
      const parsedDefaultValues = {
        ...defaultValues,
        belongsToLocationIds: getOptionsWithLabel(belongsToLocationIds, { value: '_id', label: 'name' })
      };

      reset(parsedDefaultValues);
    }

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    const values = getValues();

    const changedData = getUpdatedFormFields(values, dirtyFields) as ILocation;

    if (!_.isEmpty(changedData.belongsToLocationIds)) {
      const belongsToLocationIds = getAutocompleteValue(changedData.belongsToLocationIds)
      _.set(changedData, 'belongsToLocationIds', belongsToLocationIds);
    }

    if (props.action === 'create') {
      await createLocation.mutateAsync(changedData);
    }

    if (props.action === 'update') {
      await updateLocation.mutateAsync({ locationId: props.locationId, location: changedData });
    }

    handleClose();
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullScreen={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <LocationForm control={control} />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )}
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(LocationFormModal);