import _ from 'lodash';
import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';

import FormModal from '../FormModal';

import { useCreatePermission } from '../../../../api/permission';
import { RESOURCE_AUTHOR, RESOURCE_ARTWORK, PERMISSION_TYPE_LIMITED, RESOURCE_USER } from '../../../../utils/constants';
import CustomAutocomplete from '../../forms/inputs/CustomAutocomplete';
import { useArtworksByAuthorList, useArtworksList } from '../../../../api/artwork';
import { useAuthorSuggestions } from '../../../../api/people';
import { getAutocompleteValue, getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';


interface GlobalPermissionModalProps {
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any
  dialogTitle: string,
  userId: string | undefined,
  userEmail: string | undefined,
  allowedOperations: string[],
  allowedResources: string[],
}

interface GlobalPermissionModalForm {
  author: string | undefined,
  artwork: string | undefined,
  resource: string | undefined,
  resourceId: string | undefined,
  operations: string[] | undefined
}

const GlobalPermissionModal: React.FC<GlobalPermissionModalProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const {
    allowedOperations,
    allowedResources,
    buttonClassName,
    buttonText,
    buttonFullWidth,
    userEmail,
    userId
  } = props;

  const { control, reset, handleSubmit, formState: { isSubmitting } } = useForm<GlobalPermissionModalForm>();
  const resourceId = useWatch({ control, name: 'resourceId' });
  const resource = useWatch({ control, name: 'resource' });
  const operations = useWatch({ control, name: 'operations' });

  const createPermission = useCreatePermission();

  const handleOpen = () => {
    reset(props.defaultValues);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = () => {
    const permission = {
      resource: getAutocompleteValue(resource),
      resourceId: getAutocompleteValue(resourceId),
      operations: getAutocompleteValue(operations),
    }

    createPermission.mutateAsync({ ...permission, userId });
    handleClose();
  }

  const allowedResourcesOptions = getOptionsWithLabel(allowedResources);
  const allowedOperationsOptions = getOptionsWithLabel(allowedOperations);

  return (
    <FormModal dialogTitle={`Add global permissions for ${userEmail}`} buttonText={buttonText} buttonClassName={buttonClassName} buttonFullWidth={buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <CustomAutocomplete
              name='resource'
              label='Choose the resource you want to share'
              multiple={false}
              options={_.castArray(allowedResourcesOptions)}
              freeSolo={false}
              filterSelectedOptions={true}
              control={control}
            />
            <CustomAutocomplete
              name='operations'
              label='Please select permissions'
              multiple={true}
              options={_.castArray(allowedOperationsOptions)}
              freeSolo={false}
              filterSelectedOptions={true}
              control={control}
            />
          </Stack>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )
      }
    </FormModal >
  );
}

export default withResourceGuard([RESOURCE_USER])(GlobalPermissionModal);