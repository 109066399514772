import React from 'react';
import { AppBar, IconButton, Button, Container, Dialog, DialogContent, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface FormModalProps {
    buttonClassName?: string
    buttonFullWidth?: boolean
    buttonText: string
    buttonType?: 'Button' | 'MenuItem'
    dialogTitle: string
    handleClose: any
    handleOpen: any
    open: boolean
    children: any
    fullScreen?: boolean
    fullWidth?: boolean
}

const FormModal = (props: FormModalProps) => {
    return (
        <React.Fragment>
            <Button variant='child' className={props.buttonClassName} onClick={props.handleOpen} fullWidth={props.buttonFullWidth}>
                {props.buttonText}
            </Button>
            <Dialog open={props.open} onClose={props.handleClose} fullScreen={props.fullScreen} fullWidth={props.fullWidth}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={props.handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" color="inherit" component="div">
                            {props.dialogTitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className="mt-3 mb-3">
                    <DialogContent className='author-form-align'>
                        {props.children}
                    </DialogContent>
                </Container>
            </Dialog>
        </React.Fragment>
    );
}

export default FormModal;