import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getResourcePlaceholderAndLink } from '../../../utils/helpers';
import { Button } from '@mui/material';

interface ResourceLinkButtonProps {
  resourceData: any,
  resourceType: string
}

const ResourceLinkButton: React.FC<ResourceLinkButtonProps> = ({resourceData, resourceType }) => {
  const { placeholder, link } = getResourcePlaceholderAndLink({ resourceData, resourceType }) || {};

  return (
    <Button variant="outlined" color='primary' component={NavLink} to={link || ''}>{placeholder}</Button>
  )
}

export default ResourceLinkButton;