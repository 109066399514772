import _ from 'lodash';
import { set, del } from "idb-keyval";
import axios, { AxiosResponse } from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { ARTWORKS_CACHE_STORAGE_KEY, USER_DATA_KEY, USER_LOCAL_STORAGE_KEY } from '../utils/constants';
import { LoginFormData } from '../types/forms';
import { useAlert, useAuth } from '../store/zustand';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { IUser } from '../../../../common/interfaces/IData';
import { useNavigate } from 'react-router-dom';
import { validateRequestArguments } from '../utils/helpers';

type CreatePermissionParams = {
  operations: string[] | undefined,
  resource: string | undefined,
  resourceId: string | undefined,
  userId: string | undefined
}

type UpdatePermissionParams = {
  operations: string[] | undefined,
  permissionId: string | undefined,
  resource: string | undefined,
  userId: string | undefined
}

type DeletePermissionParams = {
  permissionId: string | undefined,
  userId: string | undefined
}

export const createPermission = async (payload: CreatePermissionParams): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { resource, resourceId, operations, userId } = payload;
  
  const permission = {
    resource,
    resourceId,
    operations,
    userId
  };

  validateRequestArguments([resource, resourceId, operations, userId]);
  console.log(permission);
  return await axios.post('/user/permission/create', {
    permission
  },
    axiosConfig);
}

const updatePermission = async (payload: UpdatePermissionParams): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { userId, permissionId, operations, resource } = payload;

  validateRequestArguments([userId, permissionId, operations]);

  return await axios.put(`/user/${userId}/permission/${permissionId}`, {
    permission: { operations }
  },
    axiosConfig);
}

const deletePermission = async (payload: DeletePermissionParams): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { userId, permissionId } = payload;

  validateRequestArguments([userId, permissionId]);

  return await axios.delete(`/user/${userId}/permission/${permissionId}`,
  axiosConfig
  );
}

export const useCreatePermission = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (createPermissionParams: CreatePermissionParams) => apiRequestPromise.handleApiRequestPromise(createPermission, createPermissionParams),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'Permission created successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export const useUpdatePermission = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updatePermissionData: UpdatePermissionParams) => apiRequestPromise.handleApiRequestPromise(updatePermission, updatePermissionData),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'Permission updated successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}

export const useDeletePermission = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (deletePermissionParams: DeletePermissionParams) => apiRequestPromise.handleApiRequestPromise(deletePermission, deletePermissionParams),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'Permission deleted successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users'] });
    }
  })
}