import _ from 'lodash';
import React from 'react';
import { Chip } from '@mui/material';
import { Control } from 'react-hook-form';
import CustomAutocomplete from '../forms/inputs/CustomAutocomplete';
import ClearIcon from '@mui/icons-material/Clear';
import CustomCard from './CustomCard';

type SearchCardProps = {
    options: any[],
    control: Control<any>;
}

const SearchCard: React.FC<SearchCardProps> = ({ control, options }) => {
    const groupBy = (option: { value: string, label: string }) => _.first(_.split(option.label, ":")) || "";

    return (
        <CustomCard
            variant='grey'
            headerActionsDisabled={true}
            footerActionsDisabled={true}
            content={
                    <CustomAutocomplete
                        name='search'
                        label='Search'
                        renderTags={(value, getTagProps) =>
                            _.map(value, (option, index) => (
                                <Chip
                                    variant="outlined"
                                    color='primary'
                                    deleteIcon={<ClearIcon />}
                                    label={option.label || option}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                        control={control}
                        options={options}
                        multiple={true}
                        freeSolo={true}
                        groupBy={groupBy}
                        sx={{
                            '& input': { padding: 2 },
                            '& .MuiInputLabel-root': {
                                color: 'inherit',
                                fontSize: '1.2rem',
                                lineHeight: '1.5rem',
                                letterSpacing: '0.05rem',
                                fontWeight: 300,
                            },
                            '& .MuiInput-underline:before': {
                                borderBottom: '1px solid black', // Add a 2px black solid bottom border on focus
                            },
                            '& .MuiInput-root': {
                                paddingBottom: 1
                            }
                        }}
                    />
            }
        >
        </CustomCard>

    )
}

export default SearchCard;
