import _ from 'lodash';
import dayjs from 'dayjs';
import { disabledResources, disabledGuestResources, USER_ROLE_GUEST, RESOURCE_ARTWORK, RESOURCE_AUTHOR } from './constants';
import { DirtyLens } from '@mui/icons-material';
import { data } from 'browserslist';
import { flatten } from 'flat';

interface GetInputValue {
  inputName: string,
  value: any
}
interface GetCheckedPermissions {
  resource?: string,
  value: string,
  permissions: any
}

interface Options {
  label: string | string[];
  value?: string;
}

type AnyDataObject = {
  [key: string]: any
}

const getOptionsWithLabel = (input: any[] | object, options?: Options) => {
  const output = _.map(_.castArray(input), item => {
    return {
      value: options?.value ? _.toString(_.get(item, options.value)) : _.toString(_.get(item, '_id', item)),
      label: options?.label ? _.join(_.compact(_.at(item, _.castArray(options.label) || item)), ' | ') : _.toString(item)
    };
  });
  const result = _.isArray(input) ? output : _.first(output);
  return result;
};

const showOnSelected = (selectedField: any, state: any) => {
  if (state[selectedField] === true) {
    return ''
  } else { return 'hidden' }
}

const selectedSignatureLocation = (selectedLocation: any, location: string) => {
  if (location === selectedLocation) {
    return { backgroundColor: '#f05c5c' }
  } else return {};
}

const getFileSizeMB = (size: any) => {
  if (_.isEmpty(size)) {
    return 'file size unknown'
  }
  return `${(size / 1000000).toFixed(2)} MB`
}

const getFormattedDate = (date: string | Date | number) => {
  if (_.isEmpty(date)) {
    return 'date unknown'
  }
  return dayjs(date).format('DD/MM/YYYY')
}

const getArtworkDimensions = (dimensions: any) => {
  if (_.isEmpty(dimensions)) {
    return 'dimensions unknown'
  } else {
    const { height, width, depth } = dimensions;
    const dimensionString = _.join(_.compact([height, width, depth]), ' x ');
    if (!dimensionString) {
      return 'dimensions unknown'
    }
    return `${dimensionString} cm`
  }
}

const getResourcePlaceholderAndLink = ({ resourceData, resourceType }: { resourceData: any, resourceType: string }) => {
  const resourceId = _.get(resourceData, '_id');
  let result;
  switch (resourceType) {
    case RESOURCE_ARTWORK:
      const authorId: string = _.get(resourceData, 'authorId._id', '');
      const authorName: string = _.get(resourceData, 'authorId.name', '');
      const artworkTitle: string = _.get(resourceData, 'title', '');
      const year: number = _.get(resourceData, 'year.createdStart', null);
      const placeholderAray = _.compact([authorName, artworkTitle, year]);
      result = {
        placeholder: _.join(placeholderAray, ", "),
        link: `/people/${authorId}/artworks/${resourceId}`
      };
      break;
    case RESOURCE_AUTHOR:
      result = {
        placeholder: _.get(resourceData, 'name'),
        link: `/people/${resourceId}`
      };
      break;
    default:
      result = null;
      break;
  }
  return result;
};

const getAuthorData = (type: string, data: any) => {
  if (!_.isEmpty(_.get(data, 'value'))) {
    return {
      authorId: _.get(data, 'value'),
      name: _.get(data, 'label'),
      description: _.get(data, 'description'),
      type
    }
  } else if (_.isString(data)) {
    return {
      name: data,
      type
    }
  } else {
    return undefined
  }
}

const getArtworkYear = (year: any) => {
  if (_.isEmpty(year)) {
    return 'unknown year'
  } else {
    const { createdStart, createdEnd } = year;
    const yearCreatedStart = dayjs(createdStart).isValid() ? createdStart : '';
    const yearCreatedEnd = dayjs(createdEnd).isValid() ? createdEnd : '';

    const yearString = _.join(_.compact([yearCreatedStart, yearCreatedEnd]), ' - ');
    return yearString;
  }
}

const getInputValue = ({ inputName, value }: GetInputValue) => {
  if (_.isEmpty(value)) {
    return `unknown ${inputName}`
  } else {
    return value;
  }
}

const getInputBoolean = (value: string | boolean | null | undefined) => {
  if (value === 'true' || _.isBoolean(value)) {
    return true
  } else {
    return false
  }
}

const getFirstCharacterOfString = (string: string | undefined) => {
  return _.map(_.split(string, (/\s+/)), (word) => _.join(word[0], (' ')));
}
const getCheckedPermissions = ({ resource, value, permissions }: GetCheckedPermissions) => {
  if (_.isObject(_.first(permissions))) {
    return _.find(permissions, { resource, code: value }) ? true : false;
  } else {
    return _.includes(permissions, value) ? true : false;
  }
}

const getDisabledResources = (resource: string, loggedInUser: any) => {
  const userRole = _.get(loggedInUser, 'roleName');
  const resources = userRole === USER_ROLE_GUEST ? disabledGuestResources : disabledResources;
  return _.includes(resources, resource);
}

const formatPermissionsList = (permissionsList: any, resource: string) => {
  const resourcePermissions = _.filter(permissionsList, { resource });
  const reformat = _.flatMap(resourcePermissions, resourcePermission =>
    _.map(_.get(resourcePermission, 'resourceIds'), resourceId => ({
      ...resourceId,
      code: _.get(resourcePermission, 'code'),
      resource: _.get(resourcePermission, 'resource'),
      permissionId: _.get(resourcePermission, '_id')
    }))
  );
  return _.groupBy(reformat, '_id');
}

const getNormalizedString = (string: any) => _.toString(string).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const getObjectWithoutEmptyValues = (object: object) => _.pickBy(object, value => !_.isEmpty(value));

const isValueEmpty = (value: any): boolean => {
  if (value === undefined || value === null || value === '') {
    return true;
  }
  return false;
};

const isAnyItemEmpty = (items: any) => _.map(items, (item) => {
  const emptyItem = _.find(items, isValueEmpty);
  if (emptyItem) {
    return true;
  }
});

const validateRequestArguments = (items: any[]) => {
  const emptyItem = _.find(items, isValueEmpty);
  if (emptyItem) {
    throw new Error(`The request has missing arguments: ${_.findKey(items, emptyItem)}!`);
  }
};

const isEmptyCustom = (value: any): boolean => {
  if (_.isObject(value) && !_.isArray(value)) {
    return _.every(_.mapValues(value, isEmptyCustom), _.isUndefined);
  }
  return _.isNull(value) || _.isUndefined(value) || _.isNaN(value); // not removing string as to allow setting the field to empty
};

const removeEmptyValues = (object: any): any => {
  if (_.isObject(object) && !_.isArray(object)) {
    return _.omitBy(_.mapValues(object, (value) => {
      if (_.isObject(value)) {
        return removeEmptyValues(value);
      }
      return value;
    }), isEmptyCustom);
  } else if (_.isArray(object)) {
    return _.map(object, (item) => removeEmptyValues(item));
  }

  return object;
};

const getUpdatedFormFields = (values: object, dirtyFields: object) => {
  const dirtyKeys = _.pickBy(dirtyFields, (value) => value === true);
  const updatedFields = _.pick(values, _.keys(dirtyKeys));
  return updatedFields;
}

const getAutocompleteValue = (value: any) => {
  if (_.isArray(value)) {
    return _.map(value, 'value');
  }
  if (_.isObject(value)) {
    return _.get(value, 'value');
  }
  return value;
}

const prepareSearchDataOptions = (dataArray: any[]) => {
  const options = _.flatMap(dataArray, (data) => _.map(data, (value, key) => !_.isEmpty(value) ? `${key}:${value}` : null));
  const optionsWithLabels = _.orderBy(_.map(_.uniq(_.compact(options)), (option) => ({ label: _.join(_.map(_.split(option, ":"), _.toLower), ":"), value: option })), ['value'], ['asc']);
  return optionsWithLabels;
}

const getFilteredDataFromSearch = (dataArray: any[], searchTerms: any[]) => _.filter(dataArray, (data) => {
  const searchConditions = _.map(searchTerms, (searchTerm) => {
    // the search term is a selected value from autocomplete
    if (_.isObject(searchTerm)) {
      const [key, value] = _.split(_.get(searchTerm, 'value'), ":");
      const dataValueAtKey = _.get(data, key);

      // first case: value at key is an array of objects, we check if any of the objects has a value that matches the search value
      if (_.isArray(dataValueAtKey) && _.isObject(_.first(dataValueAtKey))) {
        return _.map(dataValueAtKey, (dataObj) => _.some(dataObj, (dataObjValue) => _.toString(dataObjValue) === _.toString(value)));
      }

      // second case: value at key is an object, we check if any of the values of the object matches the search value
      if (_.isObject(dataValueAtKey)) {
        return _.some(dataValueAtKey, (dataValueAtKey) => _.toString(dataValueAtKey) === _.toString(value));
      }

      // third case: value at key is a string, we check if the value matches the search value
      if (_.isString(dataValueAtKey)) {
        return _.toString(dataValueAtKey) === _.toString(value);
      }

      return false;
    }

    // the search term is a freesolo value from autocomplete, a string
    if (_.isString(searchTerm)) {
      const regex = new RegExp(getNormalizedString(searchTerm), 'i');

      return _.some(data, (val) => {
        // first case is an array of objects, we check if any of the objects has a value that matches the search value
        if (_.isArray(val) && _.isObject(_.first(val))) {
          return _.map(val, (vl) => _.some(vl, (v) => regex.test(v)));
        }
        // second case is an object, we check if any of the values of the object matches the search value
        if (_.isObject(val) && !_.isArray(val)) {
          return _.some(val, (nestedObjectValue) => regex.test(getNormalizedString(nestedObjectValue)));
        }
        // third case is a string, we check if the value matches the search value
        if (_.isString(val)) {
          return regex.test(getNormalizedString(val));
        }
        return false;
      })
    }

    return false;
  });

  if (_.isEmpty(searchTerms)) {
    return true;
  }
  if (_.every(searchConditions)) {
    return true;
  }
  return false
});

type OperationValueEnum = 'eq' | 'ne' | 'gt' | 'gte' | 'lt' | 'lte' | 'contains' | 'ncontains' | 'empty' | 'nempty';

type SearchFilter = {
  field: string,
  operation: OperationValueEnum,
  value: string
}

// TODO FULLTEXT SEARCH!!!
const getFilteredDataFromSearch2 = (dataArray: any[], searchTerms: string[], searchFilters: SearchFilter[]) => {
  if (_.isEmpty(searchTerms) && _.isEmpty(searchFilters)) {
    return dataArray;
  }

  const validSearchFilters = _.map(searchFilters, (searchFilter) => {
    const { field, operation } = searchFilter;
    if (!_.isEmpty(field) && !_.isEmpty(operation)) {
      return searchFilter
    }
  });

  const filter = _.filter(dataArray, (data) => {
    // todo: do not run when searchFilters is empty
    const searchFilterConditions = _.map(validSearchFilters, (searchFilter) => {
      if (_.isEmpty(searchFilter)) {
        return true;
      }

      // the search filter is applied, an object with field, operator and value
      const { field, operation, value } = searchFilter;
  
      const dataValueAtKey = _.get(data, field);

      switch (operation) {
        case 'contains': {
          const regex = new RegExp(getNormalizedString(value), 'i');
          return regex.test(getNormalizedString(dataValueAtKey));
        }
        case 'ncontains': {
          const regex = new RegExp(getNormalizedString(value), 'i');
          return !regex.test(getNormalizedString(dataValueAtKey));
        }
        case 'eq': {
          return _.toString(dataValueAtKey) === _.toString(value);
        }
        case 'gt': {
          return _.toNumber(dataValueAtKey) > _.toNumber(value);
        }
        case 'lt': {
          return _.toNumber(dataValueAtKey) < _.toNumber(value);
        }
        case 'gte': {
          return _.toNumber(dataValueAtKey) >= _.toNumber(value);
        }
        case 'lte': {
          return _.toNumber(dataValueAtKey) <= _.toNumber(value);
        }
        case 'ne': {
          return _.toString(dataValueAtKey) !== _.toString(value);
        }
        case 'empty': {
          return _.isEmpty(dataValueAtKey);
        }
        case 'nempty': {
          return !_.isEmpty(dataValueAtKey);
        }
        default: {
          return false;
        }
      }
    });

    // the search term is a freesolo value from autocomplete, a string
    const searchTermsConditions = _.map(searchTerms, (searchTerm) => {
      if (!_.isEmpty(searchTerm) && _.isString(searchTerm)) {
        const regex = new RegExp(getNormalizedString(searchTerm), 'i');

        return _.some(data, (val) => {
          // first case is an array of objects, we check if any of the objects has a value that matches the search value
          if (_.isArray(val) && _.isObjectLike(_.first(val)) && !_.isArray(_.first(val))) {
            return _.find(val, (vl) => _.some(vl, (v) => regex.test(v)));
          }
          // second case is an object, we check if any of the values of the object matches the search value
          if (_.isObject(val) && !_.isArray(val)) {
            return _.some(val, (nestedObjectValue) => regex.test(getNormalizedString(nestedObjectValue)));
          }
          // third case is a string, we check if the value matches the search value
          if (_.isString(val)) {
            return regex.test(getNormalizedString(val));
          }
          return false;
        })
      }
    });

    if (_.every(_.union(searchFilterConditions, searchTermsConditions))) {
      return true;
    }

    return false
  });

  return filter;
};

const excludeFieldsFromFlattening = ({ data, excludedFieldsArray }: { data: any[], excludedFieldsArray?: string[] }) => {
  const result = _.map(data, (item) => {
    const excludeFromFlatten = _.pick(item, _.castArray(excludedFieldsArray)); // TODO
    const includeToFlatten = _.omit(item, _.castArray(excludedFieldsArray)); // TODO
    const flattenedObject = flatten(includeToFlatten) as object;
    return {
      ...flattenedObject,
      ...excludeFromFlatten
    }
  });
  return result;
}

// TODO: MAKE PERFECT!
const getDistinctFilterOptionsAtKey = ({ data, excludedFieldsArray }: { data: any[], excludedFieldsArray?: string[] }) => {
  const filter = _.map(data, (item) => _.omit(item, _.castArray(excludedFieldsArray)));
  const flattenedData = _.map(filter, flatten);

  const exampleObject = _.first(filter) || {};
  const exampleObjectFlatten = flatten(exampleObject);
  const exampleObjectKeys = _.keysIn(exampleObjectFlatten);

  const distinctValuesAtKey: { [key: string]: any[] } = _.reduce(
    flattenedData,
    (result, currentItem) => {
      const flattenedObject = _.pick(currentItem, _.castArray(exampleObjectKeys));
      _.forEach(flattenedObject, (value, key) => {
        result[key] = _.union(result[key] || [], [value]);
      });
      return result;
    },
    {} as AnyDataObject
  );
  
  return _.forEach(distinctValuesAtKey, (values, key) => distinctValuesAtKey[key] = _.sortBy(_.compact(values), 'asc'));
}

export {
  formatPermissionsList,
  getArtworkDimensions,
  getArtworkYear,
  getAuthorData,
  getAutocompleteValue,
  getCheckedPermissions,
  getDisabledResources,
  getFileSizeMB,
  getFirstCharacterOfString,
  getFormattedDate,
  getInputBoolean,
  getInputValue,
  getNormalizedString,
  getObjectWithoutEmptyValues,
  getOptionsWithLabel,
  getResourcePlaceholderAndLink,
  getUpdatedFormFields,
  getFilteredDataFromSearch,
  getFilteredDataFromSearch2,
  prepareSearchDataOptions,
  isAnyItemEmpty,
  removeEmptyValues,
  selectedSignatureLocation,
  showOnSelected,
  validateRequestArguments,
  getDistinctFilterOptionsAtKey,
  excludeFieldsFromFlattening
}