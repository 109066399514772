import _ from 'lodash';
import React from 'react';
import CustomCard from '../CustomCard';
import { RESOURCE_ARTWORK, RESOURCE_LOCATION } from '../../../../utils/constants';
import { useRemoveLocationFromResource } from '../../../../api/location';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Chip, Typography, TableContainer, Table, TableHead, TableRow, TableBody, Stack, Button } from '@mui/material';
import { ILocation } from '../../../../../../../common/interfaces/IData';
import DeleteModal from '../../modals/DeleteModal';
import { Link, NavLink } from 'react-router-dom';
import dayjs from 'dayjs';
import CustomTableCellCenter from '../../forms/inputs/CustomTableCellCenter';
import AddLocationToResourceFormModal from '../../modals/location/AddLocationToResourceFormModal';
import CustomClickableChip from '../../CustomClickableChip';
import CustomChip from '../../CustomChip';

type Props = {
    resource: string;
    resourceId: string;
    locationId?: ILocation;
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const ArtworkLocationCard: React.FC<Props> = (props) => {
    const { locationId } = props;

    const removeLocationFromResource = useRemoveLocationFromResource();

    const tableHead = ['Name', 'Type', 'Function', 'Belongs to Locations', 'Date Added', 'Action'];
    
    return (
        <CustomCard
            title={{ title: 'Location' }}
            variant='grey'
            content={
                <>
                    <TableContainer className='table-responsive'>
                        <Table className='table align-middle' color="inherit">
                            <TableHead>
                                <TableRow sx={{ alignText: 'center' }}>
                                    {_.map(tableHead, (head: string) => (
                                        <CustomTableCellCenter key={head}>{head}</CustomTableCellCenter>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {
                                !locationId || _.isString(locationId) ?
                                <></>
                                :
                                <TableBody>
                                <TableRow sx={{ alignText: 'center' }} className='no-border-bottom table-hover'>
                                    <CustomTableCellCenter>
                                        <CustomClickableChip label={_.capitalize(_.get(locationId, 'name'))} linkTo={`/locations/${_.get(locationId, '_id')}`} />
                                    </CustomTableCellCenter>
                                    <CustomTableCellCenter>
                                    </CustomTableCellCenter>
                                    <CustomTableCellCenter>
                                        <CustomChip label={_.capitalize(_.get(locationId, 'function'))} />
                                    </CustomTableCellCenter>
                                    <CustomTableCellCenter>
                                        <Stack direction="row" spacing={1}>
                                            {
                                                _.isArray(locationId.belongsToLocationIds) && _.every(locationId.belongsToLocationIds, _.isObject) ?
                                                _.map(locationId.belongsToLocationIds as ILocation[], (belongsToLocationId: ILocation, index: number) => (
                                                    <CustomClickableChip key={`belongsToLocationIds-${index}`} label={_.get(belongsToLocationId, 'name')} linkTo={`/locations/${_.get(belongsToLocationId, '_id')}`} />
                                                ))
                                                :
                                                null
                                            }
                                        </Stack>
                                    </CustomTableCellCenter>
                                    <CustomTableCellCenter>
                                        <Chip size='small' variant="outlined" label={dayjs(_.get(locationId, 'updatedAt', null)).format('DD/MM/YYYY')} />
                                    </CustomTableCellCenter>
                                    <CustomTableCellCenter>
                                            <DeleteModal action='delete' actionFunction={removeLocationFromResource} resourceTitle={_.get(locationId, 'name')} resourceDeletionData={{ locationId: _.get(locationId, '_id'), resourceId: props.resourceId, resource: props.resource }} buttonFullWidth={true} buttonText='Remove Location' dialogTitle={`Remove location from ${_.toLower(props.resource)}`} />
                                    </CustomTableCellCenter>
                                </TableRow>

                            </TableBody>
                            }
                            
                        </Table>
                    </TableContainer>
                </>
            }
            footerActionsDisabled={true}
            headerActions={
                <AddLocationToResourceFormModal buttonFullWidth={true} buttonText='Update Location' dialogTitle='Update Location' resource={props.resource} resourceId={props.resourceId} />
            }
        />
    )
}

export default withResourceGuard([RESOURCE_LOCATION])(ArtworkLocationCard);