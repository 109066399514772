import _ from 'lodash';
import React from 'react';
import { LinearProgress } from '@mui/material';
import { useProgress } from '../../store/zustand';

type ProgressProps = {
    className?: string
}

const Progress: React.FC<ProgressProps> = ({ className }) => {
    const progress = useProgress.getState().progress;

    if (progress > 0 || progress === 100) {
        return (
            <div className={className}>
                <LinearProgress variant="determinate" value={progress} />
            </div>
        )
    } return null
}

export default Progress;
