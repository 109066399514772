import _ from 'lodash';
import React from 'react';
import { Stack, FormHelperText } from '@mui/material';
import { Control, UseFormSetValue } from 'react-hook-form';

import CustomAutocomplete from '../inputs/CustomAutocomplete';
import CustomTextField from '../inputs/CustomTextField';

import { useAuthorSuggestions } from '../../../../api/people';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import { authorTypesList } from '../../../../utils/constants';

type AuthorFormProps = {
  authorType: string;
  fieldErrorMessage: string | null;
  control: Control<any>;
  setValue: UseFormSetValue<any>;
}

const AuthorForm = (props: AuthorFormProps) => {
  const { authorType, fieldErrorMessage, control, setValue } = props;
  const { data: authors } = useAuthorSuggestions();

  const authorSuggestionsOptions = _.castArray(getOptionsWithLabel(_.filter(authors, { type: authorType }), { label: 'name' }));

  return (
    <Stack spacing={2}>
      <CustomTextField
        name={_.join(['author', 'type'], '.')}
        options={authorTypesList}
        required={true}
        variant='standard'
        fullWidth
        control={control}
      />
      <CustomAutocomplete
        name={_.join(['author', 'name'], '.')}
        multiple={false}
        freeSolo={true}
        options={authorSuggestionsOptions}
        disabledOptions={authorSuggestionsOptions}
        textFieldProps={{ variant: 'standard' }}
        control={control}
        setValue={setValue}
      />
      <CustomTextField
        name={_.join(['author', 'description'], '.')}
        label={`description`}
        fullWidth
        variant='standard'
        multiline={true}
        control={control}
      />
      <FormHelperText error>{fieldErrorMessage}</FormHelperText>
    </Stack>
  );
}

export default AuthorForm;