import _ from 'lodash';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { jwtDecode, JwtPayload, JwtHeader } from "jwt-decode";
import { Navigate } from 'react-router-dom';
import { useUserLogout } from '../../api/user';
import { useAuth } from '../../store/zustand';

type AuthenticationProps = {
    children: React.ReactNode
}

const Authentication: React.FC<AuthenticationProps> = ({ children }) => {
    const token = useAuth((state) => state.token);
    const user = useAuth((state) => state.user);
    const userLogout = useUserLogout();

    const tokenPayload = token ? jwtDecode<JwtPayload>(token) : null;
    const tokenHeader = token ? jwtDecode<JwtHeader>(token, { header: true }) : null;
    const tokenExpired = _.get(tokenPayload, 'exp') ? dayjs().isAfter(dayjs.unix(_.get(tokenPayload, 'exp', 0))) : true;

    useEffect(() => {
        if (tokenExpired) {
            userLogout.logout();
        }
    }, [tokenExpired, userLogout]);

    if (_.isEmpty(token)) {
        return <Navigate to='/login' replace />;
    }

    if (tokenExpired === true) {
        return <Navigate to='/login' replace />;
    }

    if (
        _.get(tokenHeader, 'typ') === 'JWT' &&
        _.get(tokenPayload, 'iss') === 'velflcollection.com' &&
        _.get(tokenPayload, 'email') === _.get(user, 'email')
    ) {
        return (
            <>{children}</>
        )
    } else {
        return <Navigate to='/login' replace />;
    }
}

export default Authentication;
