import _ from 'lodash';
import React from 'react';
import { Button } from '@mui/material';
import { IImage } from '../../../../../../common/interfaces/IData';
import { PERMISSION_EXPORT } from '../../../utils/constants';
import { downloadImage } from '../../../api/image';
import withPermissionGuard from '../../authentication/PermissionGuard';

type Props = {
  image: IImage
}

const ImageDownloadButton: React.FC<Props> = ({ image }) => {
  return (
      <Button variant='child' fullWidth onClick={() => downloadImage({ imageId: _.get(image, '_id'), filename: _.get(image, 'filename') })}>
          Download Image
      </Button>
  )
}

export default withPermissionGuard([PERMISSION_EXPORT])(ImageDownloadButton);