import { Box, Typography, Card, CardHeader, CardContent, Container, Button, Stack } from '@mui/material';
import _ from 'lodash';
import React from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';


const ErrorPage: React.FC = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  return (
    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box>
        <Typography variant="h1" sx={{ textAlign: 'center' }}>Ooops! Something went wrong.</Typography>
        <Typography variant="h4" sx={{ textAlign: 'center' }}>Please let our developers know by sharing the following message</Typography>
        <Card variant='grey' sx={{ my: 2 }}>
          <CardHeader
            title={<Typography variant="h4">{_.get(error, 'message')}</Typography>}
          />
          <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
            <Typography variant="body2">{_.get(error, 'stack')}</Typography>
          </CardContent>
        </Card>
        <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>Go Home</Button>
        </Stack>
      </Box>
    </Container>
  )
}

export default ErrorPage;