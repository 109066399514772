import { toggleButtonGroupClasses } from '@mui/material/ToggleButtonGroup';
import { createTheme } from '@mui/material/styles';

declare module "@mui/material/styles" {
    interface CustomPalette {
        dark: {
            light: string;
            main: string;
            dark: string;
            contrastText: string;
        };
    }

    interface Palette extends CustomPalette { }
    interface PaletteOptions extends CustomPalette { }
}

declare module "@mui/material/Paper" {
    interface PaperPropsVariantOverrides {
        dark: true;
        grey: true;
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        child: true;
    }
}

export const colorPalette = createTheme({
    typography: {
        fontFamily: [
            'Helvetica Neue',
            'Helvetica',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            fontSize: '3.5rem',
            lineHeight: '5rem',
            letterSpacing: '0.1rem',
            fontWeight: 400,
            fontVariant: 'normal',
        },
        h2: {
            fontSize: '2.5rem',
            lineHeight: '3rem',
            letterSpacing: '0.1rem',
            fontWeight: 300,
            fontVariant: 'normal',
        },
        h3: {
            fontSize: '2rem',
            lineHeight: '2.5rem',
            letterSpacing: '0.1rem',
            fontWeight: 300,
            fontVariant: 'normal',
        },
        h4: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
            letterSpacing: '0.1rem',
            fontWeight: 300,
            fontVariant: 'normal',
        },
        h5: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            letterSpacing: '0.1rem',
            fontWeight: 300,
            fontVariant: 'normal',
        },
        h6: {
            fontSize: '1.2rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.05rem',
            fontWeight: 300,
        },
    },
    palette: {
        primary: {
            light: '#398a69',
            main: '#086D44',
            dark: '#054c2f',
            contrastText: '#fff',
        },
        secondary: {
            main: '#eeeeee'
        },
        warning: {
            main: '#FFD966',
        },
        dark: {
            light: '#464648',
            main: '#19191b',
            dark: '#1b1b1b',
            contrastText: '#fff',
        }
    }
});

export const theme = createTheme({
    ...colorPalette,
    components: {
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '10px',
                    backgroundColor: 'inherit',
                }
            },
            variants: [
                {
                    props: { variant: 'dark' },
                    style: {
                        backgroundColor: colorPalette.palette.dark.main,
                        color: colorPalette.palette.dark.contrastText,
                        border: 'none',
                    }
                },
                {
                    props: { variant: 'grey' },
                    style: {
                        backgroundColor: colorPalette.palette.secondary.main,
                        border: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        height: '100%'
                    },
                },
            ]
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px'
                    },
                    my: 'auto'
                }
            }
        },
        MuiCardActionArea: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                }
            }
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'child' },
                    style: {
                        color: 'inherit',
                        backgroundColor: 'transparent',
                        borderShadow: 'none',
                        border: 'none',
                        '&:hover': {
                            color: 'inherit'
                        },
                    },
                },
            ]
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        textDecoration: 'none',
                        color: 'inherit',
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: 0,
                    lineHeight: 1.5
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    paddingLeft: 0,
                    paddingRight: 0
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none'
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: 'none'
                }
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: '1px solid grey'
                }
            }
        },
    }
});