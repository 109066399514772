import _ from 'lodash';
import React from 'react';
import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, FormHelperText, FormControlProps } from '@mui/material';
import { useController, Control } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

interface OptionProps {
    value: string,
    label: string
}

interface Props extends FormControlProps {
    name: string;
    label?: string;
    rules?: any;
    options?: OptionProps[];
    control: Control<any>;
}

const CustomRadio = ({ name, label, rules, options, control, ...props }: Props) => {
    const {
        field,
        fieldState: { error }
    } = useController({
        name,
        rules,
        control
    });
    
    const theme = useTheme();

    const fieldErrorMessage = React.useMemo(() => error ? _.toString(error.message) : null, [error]);
    const labelName = React.useMemo(() => _.isString(label) ? _.startCase(label) : _.startCase(_.last(_.split(name, '.'))), [label, name]);

    return (
        <FormControl>
            <FormLabel id={field.name}>{labelName}</FormLabel>
            <RadioGroup
                row
                {...props}
                name={field.name}
                value={field.value}
            >
                {
                    options &&
                    _.map(options, (option: OptionProps, index: number) => (
                        <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.label} style={{ color: theme.palette.text.secondary }}
                        />
                    ))
                }
            </RadioGroup>
            <FormHelperText>{fieldErrorMessage}</FormHelperText>
        </FormControl>
    );
};

export default CustomRadio;