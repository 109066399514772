import _ from 'lodash';
import isStrongPassword from 'validator/lib/isStrongPassword';
import React from 'react';
import { Button, Stack } from '@mui/material';

import FormModal from '../FormModal';
import { useForm, useWatch } from 'react-hook-form';
import { useCreateUser } from '../../../../api/user';
import { UserFormCreate } from '../../forms/user/UserForm';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_USER } from '../../../../utils/constants';

interface CreateUserModalProps {
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  dialogTitle: string,
}

type CreateUserFormValues = {
  name: string;
  email: string;
  roleName: string;
  password: string;
  password2: string;
}

const CreateUserModal = (props: CreateUserModalProps) => {
  const [open, setOpen] = React.useState(false);

  const createUser = useCreateUser();
  const { control, reset, handleSubmit, setError, clearErrors, trigger, getValues, formState: { errors } } = useForm<CreateUserFormValues>();
  const password = useWatch({ name: 'password', control });
  const password2 = useWatch({ name: 'password2', control });

  React.useEffect(() => {
    if (_.size(password) > 0 && !isStrongPassword(password || '')) {
      setError('password', { message: 'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.' });
    } else if (password !== password2) {
      setError('password', { message: 'Passwords do not match!' });
    } 
    if (password === password2 && isStrongPassword(password || '')) {
      clearErrors('password');
      trigger('password');
    }
  }, [clearErrors, password, password2, setError, trigger]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    const { password2, ...values } = getValues();

    if (_.isEmpty(errors)) {
      createUser.mutateAsync(values);
      handleClose();
    }
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserFormCreate control={control} />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Button color="inherit" onClick={handleClose}>Cancel</Button>
          <Button type='submit' variant='contained' disabled={!_.isEmpty(errors)}>Create User</Button>
        </Stack>
      </form>
    </FormModal>
  );
};

export default withResourceGuard([RESOURCE_USER])(CreateUserModal);