import _ from 'lodash';
import React from 'react';

import { useAuthorSuggestions } from '../../../../api/people';
import CustomAutocomplete from '../inputs/CustomAutocomplete';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import { Control } from 'react-hook-form';


interface AuthorFormProps {
  name: string
  label?: string;
  type: string
  control: Control<any>;
}

const AuthorForm = (props: AuthorFormProps) => {
  const { data: authors } = useAuthorSuggestions();
  
  const authorSuggestionsOptions = getOptionsWithLabel(_.filter(authors, { type: props.type }), { label: 'name' });

  return (
    <div className='author-form-align'>
      <CustomAutocomplete
        name={props.name || props.type}
        label={props.label || props.type}
        multiple={false}
        freeSolo={false}
        options={_.castArray(authorSuggestionsOptions)}
        textFieldProps={{ variant: 'standard' }}
        control={props.control}
      />
    </div>
  )
}

export default AuthorForm;