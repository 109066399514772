import _ from 'lodash';
import React from 'react';
import { Stack } from '@mui/material';
import CustomTextField from '../inputs/CustomTextField';

import { USER_ROLE_ADMIN, USER_ROLE_GUEST, USER_ROLE_MASTER, USER_ROLE_EDITOR } from '../../../../utils/constants';
import { useUsers } from '../../../../api/user';

function UserFormName(props: any) {
  return (
    <CustomTextField
      name='name'
      label='Name'
      required={true}
      variant='standard'
      control={props.control}
      fullWidth={true}
    />
  )
}

function UserFormEmail(props: any) {
  return (
    <CustomTextField
      name='email'
      label='Email'
      required={true}
      variant='standard'
      control={props.control}
      fullWidth={true}
    />
  )
}

function UserFormPassword({ control, ...props }: any) {
  return (
    <>
      <CustomTextField
        name='password'
        label='Password'
        required={true}
        variant='standard'
        type='password'
        control={control}
        fullWidth={true}
      />
      <CustomTextField
        name='password2'
        label='Confirm Password'
        required={true}
        variant='standard'
        type='password'
        control={control}
        fullWidth={true}
      />
    </>
  )
}

function UserFormRole(props: any) {
  const userRoles = [
    { value: USER_ROLE_GUEST, label: _.capitalize(USER_ROLE_GUEST) },
    { value: USER_ROLE_EDITOR, label: _.capitalize(USER_ROLE_EDITOR) },
    { value: USER_ROLE_ADMIN, label: _.capitalize(USER_ROLE_ADMIN) },
    { value: USER_ROLE_MASTER, label: _.capitalize(USER_ROLE_MASTER) },
  ];

  return (
    <CustomTextField
      name='roleName'
      label='User Role'
      options={userRoles}
      variant='standard'
      fullWidth={true}
      control={props.control}
    />
  )
}

function SelectUserFromUsers(props: any) {
  const users = useUsers();

  const userOptions = _.map(users, user => {
    return {
      value: _.get(user, '_id'),
      label: _.join([_.get(user, 'name'), _.get(user, 'email')], ' | ')
    }
  });

  return (
    <CustomTextField
      name={_.join(['permission', 'sharing', 'userId'], '.')}
      label='User Role'
      options={userOptions}
      variant='standard'
      fullWidth={true}
      control={props.control}
    />
  )
}

function UserFormCreate({ control, ...props }: any) {
  return (
    <Stack spacing={2}>
      <UserFormName control={control} />
      <UserFormEmail control={control} />
      <UserFormPassword control={control} />
      <UserFormRole control={control} />
    </Stack>
  )
};


export {
  UserFormCreate,
  UserFormRole,
  UserFormName,
  UserFormEmail,
  UserFormPassword,
  SelectUserFromUsers
};