import _ from 'lodash';
import React from 'react';
import { FormGroup, Checkbox, FormLabel, FormControlLabel, FormControl, FormHelperText, FormControlProps } from '@mui/material';
import { useController, Control, UseFormSetValue } from 'react-hook-form';

interface OptionProps {
    value: string,
    label: string
}

interface Props extends FormControlProps {
    name: string;
    label?: string;
    rules?: any;
    options?: OptionProps[];
    control: Control<any>;
    setValue: UseFormSetValue<any>;
}

const CustomCheckbox = ({ name, label, rules, options, control, setValue, ...props }: Props) => {
    const {
        field,
        fieldState: { error }
    } = useController({
        name,
        control,
        rules,
        defaultValue: [],
    });

    const fieldErrorMessage = error ? _.toString(error.message) : null;
    const labelName = _.isString(label) ? _.startCase(label) : _.startCase(_.last(_.split(name, '.')));
    console.log(options);
    return (
        <FormControl>
            <FormLabel id={name}>{labelName}</FormLabel>
            <FormGroup>
                {
                    options &&
                    _.map(options, (option: OptionProps, index: number) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    {...field}
                                    value={option.value}
                                    checked={_.includes(field.value, option.value) || false}
                                    onChange={(e) => {
                                        const checkedValue = e.target.value;

                                        // Create a new array with the updated values
                                        const updatedValue = _.includes(field.value, checkedValue)
                                            ? _.filter(field.value, (v) => v !== checkedValue)
                                            : [...field.value, checkedValue];

                                        field.onChange(updatedValue);
                                        setValue(name, updatedValue);
                                    }}
                                />}
                            label={option.label}
                        />
                    ))}
            </FormGroup>
            <FormHelperText>{fieldErrorMessage}</FormHelperText>
        </FormControl>

    );
};

export default CustomCheckbox;