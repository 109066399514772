import React from 'react';
import { Stack, InputAdornment } from '@mui/material';
import { Control } from 'react-hook-form';

import CustomTextField from '../inputs/CustomTextField';
import AuthorFormSelect from '../author/AuthorFormSelect';

type ExhibitionFormProps = {
  control: Control<any>;
}

// TODO authorIds: <AuthorFormSelect control={control} name="curator" type="curator"/>

const ExhibitionForm = ({ control }: ExhibitionFormProps) => {
  return (
    <Stack spacing={2}>

      <CustomTextField
        name='title'
        label='exhibition title'
        type='string'
        required={true}
        variant='standard'
        control={control}
      />

      <CustomTextField
        name='year'
        label='year'
        type='number'
        required={true}
        variant='standard'
        control={control}
        InputProps={{
          endAdornment: (<InputAdornment position="end"><i>(yyyy)</i></InputAdornment>)
        }}
      />

    
      <CustomTextField
        name='notes'
        type='string'
        variant='standard'
        control={control}
        fullWidth
        multiline
      />

    </Stack>
  )
};


export default ExhibitionForm;