import _ from 'lodash';
import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { useAlert } from '../../store/zustand';

const Alerts: React.FC = () => {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
    };

    const alerts = useAlert(state => state.alerts);

    return (
        <>
            {_.map(alerts, (alert) => (
                <Snackbar
                    key={alert.id}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={alert.severity}>
                        {alert.message}
                    </Alert>
                </Snackbar>
            ))}
        </>
    )
}

export default Alerts;
