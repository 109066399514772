import _, { random } from 'lodash';
import React, { useMemo } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Button, Box, Avatar, List, ListItem, Stack, SwipeableDrawer } from '@mui/material';
import { useAuth } from '../../../store/zustand';

import { USER_ROLE_ADMIN, USER_ROLE_MASTER, USER_ROLE_EDITOR, RESOURCE_ALL, brandName, navigationItems } from '../../../utils/constants';
import { getFirstCharacterOfString } from '../../../utils/helpers';
import { SmallMenu, useSmallMenu } from '../SmallMenu';
import { useUserLogout } from '../../../api/user';
import { Link, NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PaintSplashIcon from './PaintSplashIcon';

const Navigation: React.FC = () => {
    const user = useAuth(state => state.user);
    const userLogout = useUserLogout();
    const usernameShort = getFirstCharacterOfString(_.get(user, 'name'));
    const theme = useTheme();

    const filteredNavigation = useMemo(() => {
        const allowedResources = _.get(user, 'allowedResources', []);
        if (_.includes(allowedResources, RESOURCE_ALL)) {
            return navigationItems;
        }
        if (!_.isEmpty(allowedResources)) {
            const regexExpressions = _.map(allowedResources, (resource) => new RegExp(`^${resource}`, 'gmi'));

            const filter = _.filter(navigationItems, (item) => _.some(regexExpressions, (regex) => regex.test(_.get(item, 'name'))));
            return filter;
        }
        return [];
    }, [user]);

    return (
        <>
            <AppBar component="nav" sx={{ backgroundColor: 'transparent', boxShadow: 'none', border: 'none', color: 'black' }}>
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, [theme.breakpoints.down('sm')]: { display: 'none' } }}
                    >
                        <Link to='/'>
                            <PaintSplashIcon />
                        </Link>
                    </Typography>
                    <Box sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}>
                        {_.map(filteredNavigation, (item) => (
                            <Button variant='child' color='secondary' key={_.get(item, 'name', '')} component={NavLink} to={_.get(item, 'path')}>
                                {_.get(item, 'name')}
                            </Button>
                        ))}
                        <SmallMenu customMenuButtonIcon={<Avatar sx={{ width: '32px', height: '32px', bgcolor: 'black' }}>{usernameShort}</Avatar>}>
                            <Button variant='child' color='secondary' key='settings' fullWidth component={NavLink} to='/settings'>
                                Settings
                            </Button>
                            <Button variant='child' key='logout' fullWidth onClick={() => userLogout.logout()}>
                                Logout
                            </Button>
                        </SmallMenu>
                    </Box>
                </Toolbar>
            </AppBar >
        </>
    )
}

export default Navigation;
