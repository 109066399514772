import _ from 'lodash';
import axios from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import { removeEmptyValues } from '../utils/helpers';
import { useAlert } from '../store/zustand';
import { IExhibition } from '../../../../common/interfaces/IData';

interface IUpdateExhibition {
  exhibitionId: string,
  exhibition: IExhibition
}

interface IAddArtworksToExhibition {
  exhibitionId: string,
  artworkIds: string[]
}

interface IDeleteExhibition {
  exhibitionId: string
}

const createExhibition = async (payload: IExhibition | undefined): Promise<IExhibition> => {
  const axiosConfig = getAxiosConfig();
  const exhibitionCleanData = removeEmptyValues(payload);
  
  const { data }: { data: IExhibition } = await axios.post('/exhibition/create',
      { exhibition: exhibitionCleanData },
      axiosConfig
  );

  return data;
}

const addArtworksToExhibition = async ({ artworkIds, exhibitionId }: IAddArtworksToExhibition): Promise<IExhibition> => {
  const axiosConfig = getAxiosConfig();

  const { data }: { data: IExhibition } = await axios.post(`/exhibition/${exhibitionId}/add/artworks`,
      { exhibition: { artworkIds } },
      axiosConfig
  );

  return data;
}

const getExhibitions = async (): Promise<IExhibition[]> => {
    const axiosConfig = getAxiosConfig();
    const { data }: { data: [IExhibition] } = await axios.get('/exhibition/list', axiosConfig);
    return data;
}

const getExhibition = async (exhibitionId: string): Promise<IExhibition> => {
  const axiosConfig = getAxiosConfig();
  const { data }: { data: IExhibition } = await axios.get(`/exhibition/${exhibitionId}`, axiosConfig);
  return data;
}

const updateExhibition = async ({ exhibition, exhibitionId }: IUpdateExhibition): Promise<IExhibition> => {
  const axiosConfig = getAxiosConfig();

  const exhibitionCleanData = removeEmptyValues(exhibition);

  const { data }: { data: IExhibition } = await axios.put(`/exhibition/${exhibitionId}`,
      { exhibition: exhibitionCleanData },
      axiosConfig
  );

  return data;
}

const deleteExhibition = async ({ exhibitionId }: IDeleteExhibition): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  
  const { data } = await axios.delete(`/exhibition/${exhibitionId}`,
      axiosConfig
  );

  return data;
}

export const useCreateExhibition = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (exhibition: IExhibition) => apiRequestPromise.handleApiRequestPromise(createExhibition, exhibition),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Exhibition created successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
      }
  })
}

export const useAddArtworksToExhibition = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (payload: IAddArtworksToExhibition) => apiRequestPromise.handleApiRequestPromise(addArtworksToExhibition, payload),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Success!', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
      }
  })
}

export const useExhibitions = () => {
  const apiRequestPromise = useApiRequestPromise();
  return useQuery({
    queryKey: ["exhibitions"],
    queryFn: async () => {
      return apiRequestPromise.handleApiRequestPromise(getExhibitions)
    },
    placeholderData: (previousData, previousQuery) => previousData,
    staleTime: 5 * 60 * 1000,
  });
}

export const useExhibitionDetail = (exhibitionId: string | undefined) => {
  const apiRequestPromise = useApiRequestPromise();
  return useQuery({
      queryKey: ["exhibitions", "detail", exhibitionId],
      queryFn: async () => apiRequestPromise.handleApiRequestPromise(getExhibition, exhibitionId),
      enabled: !_.isEmpty(exhibitionId),
      placeholderData: (previousData, previousQuery) => previousData,
      staleTime: 5 * 60 * 1000,
  });
}

export const useUpdateExhibition = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (exhibition: IUpdateExhibition) => apiRequestPromise.handleApiRequestPromise(updateExhibition, exhibition),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Exhibition updated successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
      }
  })
}

export const useDeleteExhibition = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();
  return useMutation({
      mutationFn: (payload: IDeleteExhibition) => apiRequestPromise.handleApiRequestPromise(deleteExhibition, payload),
      onSuccess: () => {
          useAlert.getState().addAlert({ message: 'Exhibition deleted successfully', severity: 'success' });
          queryClient.invalidateQueries({ queryKey: ['exhibitions'] });
      }
  })
}

