import _ from 'lodash';
import React from 'react';
import { Typography, Card, CardHeader, CardContent, CardActions, PaperPropsVariantOverrides, TypographyProps, SxProps, Theme } from '@mui/material';
import { Link } from 'react-router-dom';

type TitleProps = {
    title?: string;
    titleSx?: React.CSSProperties;
    linkTo?: string;
}

interface CustomTypographyProps extends TypographyProps {
    to?: string;
}

type CustomCardProps = {
    footerActionLink?: React.ReactNode;
    headerActions?: React.ReactNode;
    headerActionsDisabled?: boolean;
    content?: React.ReactNode;
    subtitle?: string;
    title?: TitleProps;
    variant?: keyof PaperPropsVariantOverrides;
    footerActionsDisabled?: boolean;
    cardSx?: SxProps<Theme>;
    headerSx?: SxProps<Theme>;
    contentSx?: SxProps<Theme>;
    elevation?: number;
}

const CustomCard = ({ footerActionLink, headerActions, content, subtitle, title, variant = 'dark', headerActionsDisabled = false, footerActionsDisabled = false, cardSx, headerSx, contentSx, elevation = 1}: CustomCardProps) => {    
    return (
        <Card variant={variant} sx={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%', ...cardSx }} elevation={elevation}>
            <CardHeader
                title={ title && <Link to={title.linkTo || ""} style={{ color: 'inherit', textDecoration: 'none' }}><Typography variant="h3">{title.title}</Typography></Link>}
                subheader={ subtitle && <Typography variant="h4" sx={{ color: 'inherit' }}>{subtitle}</Typography>}
                action={!headerActionsDisabled && headerActions}
                sx={{ paddingBottom: '0', ...headerSx }}
            />
            <CardContent sx={{ paddingTop: 1, marginTop: 'auto', ...contentSx }}>
                {content}
            </CardContent>
            {
                !footerActionsDisabled &&
                <CardActions sx={{ marginTop: 'auto', paddingTop: 0 }}>
                    {footerActionLink}
                </CardActions>
            }
        </Card>
    )
}

export default CustomCard;