import _ from 'lodash';
import isStrongPassword from 'validator/lib/isStrongPassword';
import React from 'react';
import { Button, Stack } from '@mui/material';

import FormModal from '../FormModal';
import CustomTextField from '../../forms/inputs/CustomTextField';
import { useForm, useWatch } from 'react-hook-form';
import { useUpdateUserPassword } from '../../../../api/user';

interface UpdatePasswordModalProps {
  userId: string | undefined, 
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  dialogTitle: string,
}

const UpdatePasswordConfirmationButton = ({ errors, buttonText }: { errors: object, buttonText: string }) => {
  return (
    <Button type='submit' variant='contained' disabled={!_.isEmpty(errors)}>{buttonText}</Button>
  );
}

const UpdatePasswordModal = (props: UpdatePasswordModalProps) => {
  const [open, setOpen] = React.useState(false);

  const updatePassword = useUpdateUserPassword();
  const { control, reset, handleSubmit, setError, clearErrors, trigger, formState: { errors } } = useForm();
  const password = useWatch({ name: 'password', control });
  const password2 = useWatch({ name: 'password2', control });

  React.useEffect(() => {
    if (_.size(password) > 0 && !isStrongPassword(password)) {
      setError('password', { message: 'Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.' });
    } else if (password !== password2) {
      setError('password', { message: 'Passwords do not match!' });
    } 
    if (password === password2) {
      clearErrors('password');
      trigger('password');
    }
  }, [clearErrors, password, password2, setError, trigger]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    if (_.isEmpty(errors)) {
      updatePassword.mutateAsync({ userId: props.userId, password: password });
      handleClose();
    }
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField control={control} variant='standard' type='password' name='password' fullWidth />
        <CustomTextField control={control} variant='standard' type='password' name='password2' label='Confirm Password' fullWidth />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Button color="inherit" onClick={handleClose}>Cancel</Button>
          <UpdatePasswordConfirmationButton errors={errors} buttonText={props.buttonText} />
        </Stack>
      </form>
    </FormModal>
  );
};

export default UpdatePasswordModal;