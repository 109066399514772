import _ from 'lodash';
import React from 'react';

import CustomAutocomplete from '../inputs/CustomAutocomplete';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import { Control } from 'react-hook-form';
import { useUsers } from '../../../../api/user';


type UserFormSelectProps = {
  control: Control<any>,
  multiple: boolean,
  required: boolean
}

const UserFormSelect = (props: UserFormSelectProps) => {
  const { data: users } = useUsers();
  
  const authorSuggestionsOptions = getOptionsWithLabel(users, { value: '_id', label: 'email' });

  return (
    <div className='author-form-align'>
      <CustomAutocomplete
        name='userId'
        label='User'
        multiple={props.multiple}
        freeSolo={false}
        options={_.castArray(authorSuggestionsOptions)}
        textFieldProps={{ variant: 'standard'  }}
        control={props.control}
        required={props.required}
      />
    </div>
  )
}

export default UserFormSelect;