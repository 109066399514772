import _ from 'lodash';
import React, { useMemo, useCallback } from 'react';
import { Button, Typography, Stack } from '@mui/material';

import FormModal from './FormModal';
import CustomTextField from '../forms/inputs/CustomTextField';
import { useForm, useWatch } from 'react-hook-form';
import withPermissionGuard from '../../authentication/PermissionGuard';
import { PERMISSION_DELETE } from '../../../utils/constants';

interface DeleteModalProps {
  action: 'delete',
  actionFunction: any,
  resourceTitle: string | undefined,
  resourceDeletionData: string | object | undefined,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  dialogTitle: string,
}

const DeleteConfirmationButton = ({ errors, buttonText }: { errors: object, buttonText: string }) => {
  return (
    <Button type='submit' variant='contained' color='error' disabled={!_.isEmpty(errors)}>{buttonText}</Button>
  );
}

const DeleteModal = (props: DeleteModalProps) => {
  const [open, setOpen] = React.useState(false);

  const { control, reset, handleSubmit, setError, clearErrors, trigger, formState: { errors } } = useForm();
  const confirmation = useWatch({ name: 'confirmation', control });

  React.useEffect(() => {
    if (confirmation === props.resourceTitle) {
      clearErrors('confirmation');
      trigger('confirmation');
    } else {
      setError('confirmation', { message: 'Please confirm by writing exactly what appears above.' });

    }
  }, [clearErrors, confirmation, props.resourceTitle, setError, trigger]);

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);

  const onSubmit = useCallback(async () => {
    if (_.isEmpty(errors)) {
      await props.actionFunction.mutateAsync(props.resourceDeletionData);
      handleClose();
    }
  }, [errors, handleClose, props.actionFunction, props.resourceDeletionData]);

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      <Typography variant='body1'>Please write <b>{props.resourceTitle}</b> in the form below if you wish to {props.action} this resource. This operation is irreversible!</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField control={control} variant='standard' type='string' name='confirmation' fullWidth />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Button color="inherit" onClick={handleClose}>Cancel</Button>
          <DeleteConfirmationButton errors={errors} buttonText={props.buttonText} />
        </Stack>
      </form>
    </FormModal>
  );
};

export default withPermissionGuard([PERMISSION_DELETE])(DeleteModal);