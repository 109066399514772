import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware'
import { IUser } from '../../../../common/interfaces/IData';
import { Alert } from '../types/alert';

type AuthState = {
    user: IUser | null,
    token: string | null,
    createUser: (user: IUser) => void,
    deleteUser: () => void,
    createToken: (token: string) => void,
    deleteToken: () => void
}

type AlertState = {
    alerts: Alert[],
    addAlert: (alert: Alert) => void
}

type ProgressState = {
    progress: number,
    setProgress: (progress: number) => void
}

export const useAuth = create<AuthState>()(
    persist(
        (set) => ({
            user: null,
            token: null,
            createUser: (user) => set({ user }),
            deleteUser: () => set({ user: null }),
            createToken: (token) => set({ token }),
            deleteToken: () => set({ token: null })
        }),
        {
            name: 'authStorage',
            storage: createJSONStorage(() => localStorage)
        }
    )
);

export const useAlert = create<AlertState>((set) => ({
    alerts: [],
    addAlert: (alert: Alert) => {
        const id = new Date().getTime();
        const { severity, message } = alert;
        set((state) => ({
            alerts: [...state.alerts, { id, severity, message }],
        }));
        setTimeout(() => {
            set((state) => ({
                alerts: state.alerts.filter((alert) => alert.id !== id),
            }));
        }, 5000);
    },
}));

export const useProgress = create<ProgressState>((set) => ({    
    progress: 0,
    setProgress: (progress: number) => set({ progress })
}));
