import _ from 'lodash';
import React from 'react';
import { FormGroup, FormControlLabel, Switch, FormHelperText, Paper, Typography } from '@mui/material';
import { useController, Control } from 'react-hook-form';

type CustomBooleanSwitchProps = {
  name: string;
  label?: string;
  rules?: any;
  control: Control<any>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

const CustomBooleanSwitch = ({ name, label, rules, control, labelPlacement }: CustomBooleanSwitchProps) => {
  const {
    field,
    fieldState: { error }
  } = useController({
    control,
    name,
    rules,
  });

  const fieldErrorMessage = React.useMemo(() => error ? _.toString(error.message) : null, [error]);
  const labelName = React.useMemo(() => _.isString(label) ? _.startCase(label) : _.startCase(_.last(_.split(name, '.'))), [label, name]);
  const labelPlacementMargin = labelPlacement === 'start' ? { ml: 0 } : {};
  
  return (
    <FormGroup sx={{ alignSelf: 'start' }}>
      <FormControlLabel
        labelPlacement={labelPlacement ? labelPlacement : 'end' }
        sx={labelPlacementMargin}
        label={
          <Typography variant="subtitle1" color="text.secondary">
            {labelName}
          </Typography>
        }
        control={
          <Switch
            checked={field.value || false}
            onChange={field.onChange}
            onBlur={field.onBlur}
            name={field.name}
            color="primary"
          />}
      />
      {error && (
        <FormHelperText error>{fieldErrorMessage}</FormHelperText>
      )}
    </FormGroup>
  );
};

export default CustomBooleanSwitch;