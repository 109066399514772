import _ from 'lodash';
import React, { useRef, useCallback, forwardRef, ForwardedRef } from 'react';
import { Button, Typography, Stack, Box } from '@mui/material';
import { saveAs } from 'file-saver';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { flatten } from 'flat';
import FormModal from './FormModal';
import CustomTextField from '../forms/inputs/CustomTextField';
import { useForm } from 'react-hook-form';
import withPermissionGuard from '../../authentication/PermissionGuard';
import { PERMISSION_EXPORT } from '../../../utils/constants';
import { getOptionsWithLabel } from '../../../utils/helpers';
import { json2csv } from 'json-2-csv';
import { IArtwork } from '../../../../../../common/interfaces/IData';
import dayjs from 'dayjs';
import QRCodeImage from '../cards/QRCodeImage';

interface ExportModalProps {
  data: Object | Array<Object>,
  qrUrl: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  dialogTitle: string,
}

type ContentToPrintProps = {
  data: Object | Array<Object>;
  qrUrl: string;
}

const ContentToPrint = forwardRef((props: ContentToPrintProps, ref: ForwardedRef<any>) => {
  const { data } = props;
  const textData = flatten(_.omit(data, ['images', 'private', '_id', '__v'])) as object;
  const firstImage = _.get(data, 'images') ? _.first(_.get(data, 'images')) : null;
  return (
    <Box ref={ref}>
        <Box sx={{ width: '90%', height: '90%', maxHeight: '300px', display: 'flex', justifyContent: 'center', my: 2 }}>
            <img src={_.get(firstImage, 'thumbnailBase64')} alt={_.get(firstImage, 'filename')} style={{ objectFit: 'contain', borderRadius: '4px' }} />
        </Box>
        {
        _.map(textData, (value, key) => (
            <Typography key={key} variant='body1'>{_.replace(key, '.', ' ')}: {value}</Typography>
        ))
        }
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
            <QRCodeImage qrData={textData} qrUrl={props.qrUrl} width={150} />
        </Box>
    </Box>
  );
});


const ExportModal = (props: ExportModalProps) => {
  const contentToPrint = useRef(null);
  const [open, setOpen] = React.useState(false);
  const data: Partial<object> | Partial<object>[] = _.omit(props.data, ['images', 'private']);
  const exportTitle = `Velfl Collection Export - ${dayjs().format('DD.MM.YYYY')}`;
  
  const handleCsvExport = async () => {
    const csv = await json2csv(_.castArray(data), { delimiter: { field: ';' }, useLocaleFormat: true, escapeHeaderNestedDots: false, excelBOM: true });
    saveAs(new Blob([csv], { type: "text/plain;charset=utf-8" }), `${exportTitle}.csv`);
  }

  const handleJsonExport = async () => {
    const json = JSON.stringify(data);
    saveAs(new Blob([json], { type: "text/json;charset=utf-8" }), `${exportTitle}.json`);
  }

  const reactToPrint = useReactToPrint({
    documentTitle: exportTitle,
    contentRef: contentToPrint
  });

  const handlePrint = async () => {
    reactToPrint();
  }

  const { control, reset, handleSubmit, setError, clearErrors, trigger, watch, formState: { errors } } = useForm();
  const selectedExportFormat = watch('exportFormat');

  const exportFormats = ['Excel', 'Print', 'JSON'];
  const exportOptions = _.castArray(getOptionsWithLabel(exportFormats));

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);

  const onSubmit = async () => {
    if (selectedExportFormat === 'Excel') {
      handleCsvExport();
    }
    if (selectedExportFormat === 'Print') {
      handlePrint();
    }
    if (selectedExportFormat === 'JSON') {
      handleJsonExport();
    }
  }

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      <Typography variant='body1'>Please choose the file format of the export!</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomTextField control={control} variant='standard' options={exportOptions} type='string' name='exportFormat' fullWidth required />
        <Stack spacing={2} sx={{ mt: 2 }}>
          <Button color="inherit" onClick={handleClose}>Cancel</Button>
          <Button type='submit' variant='contained' color='success'>Export</Button>
        </Stack>
      </form>
      <Box style={{ display: 'none', overflow: 'visible', height: 'fit-content' }}>
        <ContentToPrint data={props.data} qrUrl={props.qrUrl} ref={contentToPrint} />
      </Box>
    </FormModal>
  );
};

export default withPermissionGuard([PERMISSION_EXPORT])(ExportModal);