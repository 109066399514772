import _ from 'lodash';
import React from 'react';
import { Stack, ListItem, ListItemIcon, ListItemText, Chip } from '@mui/material';

import { useAuth } from '../../../../store/zustand';
import { useDeleteUser } from '../../../../api/user';

import CustomCard from '../CustomCard';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';
import DeleteModal from '../../modals/DeleteModal';
import UpdateUserProfileModal from '../../modals/user/UpdateUserProfileModal';
import UpdatePasswordModal from '../../modals/user/UpdatePasswordModal';

type UserCardProps = {
}

const UserCard: React.FC<UserCardProps> = (props) => {
    const user = useAuth.getState().user;

    const deleteUser = useDeleteUser();

    return (
        <CustomCard
            title={{ title: 'Settings' }}
            subtitle={`for ${_.get(user, 'name')}`}
            content={
                <Stack spacing={1}>
                    <ListItem>
                        <Chip variant="outlined" size="small" label={_.get(user, 'email')} sx={{ color: 'inherit' }} />
                    </ListItem>
                    <ListItem>
                        <Chip variant="outlined" size="small" label={_.toLower(_.get(user, 'roleName'))} sx={{ color: 'inherit' }} />
                    </ListItem>
                </Stack>
            }
            headerActions={
                <SmallMenu>
                    <UpdatePasswordModal userId={_.get(user, 'userId')} buttonText='Update Password' buttonFullWidth dialogTitle='Update Password' />
                    <UpdateUserProfileModal userId={_.get(user, 'userId')} buttonText='Update Profile' buttonFullWidth dialogTitle='Update Profile' defaultValues={user} />
                    <DeleteModal action='delete' actionFunction={deleteUser} resourceTitle={_.get(user, 'name')} resourceDeletionData={{ userId: _.get(user, 'userId') }} buttonFullWidth={true} buttonText='Delete Account' dialogTitle='Delete User Account' />
                </SmallMenu>
            }
        />
    )
}

export default UserCard;