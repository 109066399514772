import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useAddLocationToResource } from '../../../../api/location';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { getAutocompleteValue } from '../../../../utils/helpers';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';

import FormModal from '../FormModal';
import AddLocationToResourceForm from '../../forms/location/AddLocationToResourceForm';

interface LocationFormProps {
  resource: string,
  resourceId: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any,
  dialogTitle: string,
}

const LocationFormModal = (props: LocationFormProps) => {
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, getValues, control, reset, formState: { isSubmitting } } = useForm();

  const addLocationToResource = useAddLocationToResource();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    const values = getValues();

    await addLocationToResource.mutateAsync({
      locationId: getAutocompleteValue(values.locationId),
      resourceId: props.resourceId,
      resource: props.resource
    });

    handleClose();
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddLocationToResourceForm control={control} />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )}
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(LocationFormModal);