import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { flatten } from 'flat';

import { DataGrid, DataGridProps, GridColDef, GridColumnVisibilityModel, GridToolbarContainer, GridToolbarExport, GridColumnApi, GridColumnMenu, GridColumnMenuProps, GridColumnMenuSortItem, GridColumnMenuFilterItem, GridToolbarColumnsButton, GridToolbar, GridPrintGetRowsToExportParams, GridPrintExportOptions, GridRowId, selectedGridRowsSelector, gridFilteredSortedRowIdsSelector, useGridApiRef } from '@mui/x-data-grid';
import { Box, Button, Divider, Stack, TextField } from '@mui/material';
import { Link } from 'react-router-dom';

import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ExportButtonMuiGrid from './buttons/ExportButtonMuiGrid';
import { excludeFieldsFromFlattening } from '../../utils/helpers';

type Data = {
    [key: string]: any;
    linkTo?: {
        label: string;
        path: string;
    }
}
type Props = {
    data: Data[];
    density: 'compact' | 'standard' | 'comfortable';
}

const CustomDataGrid: React.FC<Props> = ({ data, density }) => {
    const [fullScreen, setFullScreen] = useState(false);

    const apiRef = useGridApiRef();

    const gridToolbar = () => {
        return (
            <GridToolbarContainer sx={{ justifyContent: 'right' }}>
                <GridToolbarColumnsButton slotProps={{
                    button: {
                        style: { color: 'inherit' }
                    }
                }} />
                <ExportButtonMuiGrid apiRef={apiRef} />            
            </GridToolbarContainer>
        );
    }

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    const flattenedData = excludeFieldsFromFlattening({ data, excludedFieldsArray: ['linkTo'] });

    const keys = _.keysIn(_.first(flattenedData));

    const tableColumns: GridColDef[] =
        _.map(keys, (key) => {
            if (_.toString(key) === 'linkTo') {
                console.log('linkTo');
                return {
                    field: key,
                    headerName: 'Action',
                    headerAlign: 'center',
                    minWidth: 200,
                    disableExport: true,
                    renderCell: (params) => {
                        const { value: link } = params;
                        return (
                            <Button
                                variant="child"
                                component={Link}
                                to={link.path}
                                type='button'
                            >
                                {link.label}
                            </Button>
                        );
                    }
                }
            }
            if (key === 'image') {
                return (
                    {
                        field: key,
                        headerName: _.startCase(key),
                        headerAlign: 'center',
                        minWidth: 150,
                        disableExport: true,
                        renderCell: (params) => {
                            const { value: image } = params;
                            if (image) {
                                return (
                                    <Box sx={{ width: '90%', height: '90%', display: 'flex', justifyContent: 'center' }}>
                                        <img src={image} alt={key} style={{ objectFit: 'contain', borderRadius: '4px' }} />
                                    </Box>
                                );
                            }
                        }
                    }
                )
            }
            else {
                return {
                    field: key,
                    minWidth: 150,
                    headerName: _.startCase(key),
                    headerAlign: 'center',
                }
            }
        });


    const hideColumns = ['_id', 'condition', 'notes', 'accessories'];
    const displayColumnKeys = _.difference(keys, _.intersection(keys, hideColumns));
    const displayColumnKeysWithLinkTo = [..._.take(displayColumnKeys, 6), 'linkTo'];
    const columnVisibilityModel = _.mapValues(
        _.keyBy(keys),
        (key) => _.includes(displayColumnKeysWithLinkTo, key)
    );

    return (
                <DataGrid
                    apiRef={apiRef}
                    rows={flattenedData}
                    columns={tableColumns}
                    ignoreDiacritics={true}
                    density={density}
                    initialState={{
                        sorting: { sortModel: [{ field: 'author', sort: 'asc' }] },
                        pagination: {
                            paginationModel: {
                                pageSize: 30,
                            },
                        },
                        columns: {
                            columnVisibilityModel
                        }
                    }}
                    slots={{
                        toolbar: gridToolbar,
                        columnSortedDescendingIcon: ExpandMore,
                        columnSortedAscendingIcon: ExpandLess
                    }}
                    pageSizeOptions={[30]}
                    getRowId={(row) => row._id}
                    sx={{ border: 'none' }}
                    checkboxSelection={true}
                    disableRowSelectionOnClick={true}
                    disableColumnMenu={true}
                />
    )
}

export default CustomDataGrid;