import _ from 'lodash';
import React from 'react';
import { Stack, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';

import CustomTextField from '../inputs/CustomTextField';
import { useUserLogin } from '../../../../api/user';
import { handleError } from '../../../../api/api';
import { LoginFormData } from '../../../../types/forms';
import { useAuth } from '../../../../store/zustand';
import { Navigate } from 'react-router-dom';

const LoginForm = () => {
  const token = useAuth(state => state.token);

  const { control, getValues, handleSubmit, reset, clearErrors, formState: { isSubmitting, errors } } = useForm<LoginFormData>();

  const isValid = _.isEmpty(errors);

  const userLogin = useUserLogin();

  const onSubmit = async () => {
    const user = getValues();

    try {
      await userLogin.mutateAsync(user);
      reset();
    } catch (e) {
      handleError(e);
      clearErrors();
    }
  }

  if (!_.isEmpty(token)) {
    return <Navigate to='/' />;
  }
  else {
    return (
        <form onSubmit={handleSubmit(onSubmit)} className='login-form w-100 m-auto'>
          <Stack spacing={2}>
          <CustomTextField
            name='email'
            fullWidth
            control={control}
            required
            variant='standard'
          />

          <CustomTextField
            name='password'
            fullWidth
            required
            control={control}
            type='password'
            variant='standard'
          />
          

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            disabled={!isValid || isSubmitting}
          >
            Login
          </Button>
          </Stack>
        </form>
    )
  }


};

export default LoginForm;