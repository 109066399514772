import axios from 'axios';
import { getAxiosConfig, useApiRequestPromise } from './api';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { validateRequestArguments } from '../utils/helpers';
import { useAlert } from '../store/zustand';

type UpdateUserRoleTypeParams = {
  userId: string | undefined,
  roleName: string | undefined
}

const updateUserRoleType = async (payload: UpdateUserRoleTypeParams): Promise<void> => {
  const axiosConfig = getAxiosConfig();
  const { userId, roleName } = payload;

  validateRequestArguments([userId, roleName]);

  const data = {
    role: {
      roleName
    }
  }

  return await axios.put(`/user/${userId}/role`, data, axiosConfig);
}

export const useUpdateUserRoleType = () => {
  const apiRequestPromise = useApiRequestPromise();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (updateUserRoleTypeData: UpdateUserRoleTypeParams) => apiRequestPromise.handleApiRequestPromise(updateUserRoleType, updateUserRoleTypeData),
    onSuccess: () => {
      useAlert.getState().addAlert({ message: 'User role was updated successfully', severity: 'success' });
      queryClient.invalidateQueries({ queryKey: ['users'] })
    }
  })
}