import _ from 'lodash';
import React, { } from 'react';
import { Button, Stack } from '@mui/material';
import { useForm, useWatch } from 'react-hook-form';

import FormModal from '../FormModal';
import { useUsers } from '../../../../api/user';
import CustomAutocomplete, { AutocompleteOption } from '../../forms/inputs/CustomAutocomplete';
import UserFormSelect from '../../forms/user/UserFormSelect';
import { getAutocompleteValue } from '../../../../utils/helpers';
import { useCreatePermission } from '../../../../api/permission';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_USER } from '../../../../utils/constants';
const { permissionsByRole } = require('../../../../utils/constants');

type ShareProps = {
  resource: string,
  resourceOptions: AutocompleteOption | AutocompleteOption[] | undefined,
  resourceDisabled?: boolean,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any,
  dialogTitle: string,
}

type ShareFormValues = {
  resourceId: AutocompleteOption | undefined,
  userId: AutocompleteOption | undefined,
  operations: AutocompleteOption[] | undefined,
}

const ShareResourceWithUserModal = (props: ShareProps) => {
  const [open, setOpen] = React.useState(false);

  const { getValues, setError, clearErrors, reset, setValue, control, handleSubmit, formState: { errors, isSubmitting } } = useForm<ShareFormValues>();
  
  const { data: users } = useUsers();
  const createPermission = useCreatePermission();
  const selectedUserId = useWatch({ control, name: 'userId' });
  const selectedUser = _.find(users, { _id: _.get(selectedUserId, 'value') });
  const allowedOperationsOptions = _.get(permissionsByRole, selectedUser?.roleName, []);

  const handleOpen = () => {
    reset(props.defaultValues);
    setOpen(true);
  }

  const handleClose = () => {
    // this function closes the modal and resets the form
    setOpen(false);
    reset();
  }

  const onSubmit = async () => {
    const values = getValues();
    
    const { userId, resourceId, operations } = values;
    
    const permission = {
      resource: props.resource,
      userId: getAutocompleteValue(userId),
      resourceId: getAutocompleteValue(resourceId),
      operations: getAutocompleteValue(operations)
    };

    await createPermission.mutateAsync(permission);
    handleClose();
  }

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} buttonType='MenuItem' handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserFormSelect control={control} multiple={false} required={true} />
          <CustomAutocomplete name='resourceId' label='Resource' multiple={false} freeSolo={false} options={props.resourceOptions} textFieldProps={{ variant: 'standard' }} control={control} required disabled={props.resourceDisabled || false }/>
          <CustomAutocomplete name='operations' label='Operations' multiple={true} freeSolo={false} options={allowedOperationsOptions} textFieldProps={{ variant: 'standard' }} control={control} required />
            <Stack spacing={2} sx={{ mt: 2 }}>
              <Button color="inherit" onClick={handleClose}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={createPermission.isLoading}>Share</Button>
            </Stack>
        </form>
    </FormModal>
  );
}

export default withResourceGuard([RESOURCE_USER])(ShareResourceWithUserModal);