import _ from 'lodash';
import React from 'react';
import { useAuth } from '../../store/zustand';
import { RESOURCE_ALL } from '../../utils/constants';

type ResourceToCheck = ('AUTHOR' | 'ARTWORK' | 'LOCATION' | 'IMAGE' | 'USER' | 'EXHIBITION' | 'PUBLICATION' | 'COLLECTION')[];

type Props = {
    resourceToCheck: ResourceToCheck
    children: React.ReactNode
}

const ResourceGuard: React.FC<Props> = ({ resourceToCheck, children }) => {
    const user = useAuth.getState().user;
    const allowedResources = _.get(user, 'allowedResources', []);
    const resourcesToCheck = _.union(_.castArray(resourceToCheck), [RESOURCE_ALL]);

    if (_.size(_.intersection(allowedResources, resourcesToCheck)) > 0) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return null;
    }
};

const withResourceGuard = (resourceToCheck: ResourceToCheck) => (WrappedComponent: React.ComponentType<any>) => (props: any) => <ResourceGuard resourceToCheck={resourceToCheck}>{<WrappedComponent {...props} />}</ResourceGuard>;

export default withResourceGuard;