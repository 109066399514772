import _ from 'lodash';
import React from 'react';
import { Button } from '@mui/material';
import { IImage } from '../../../../../../common/interfaces/IData';
import { PERMISSION_UPDATE } from '../../../utils/constants';
import { downloadImage } from '../../../api/image';
import withPermissionGuard from '../../authentication/PermissionGuard';
import { useUpdateArtworkCurrentLocation } from '../../../api/artwork';

type Props = {
  locationId: string;
  resourceId: string;
}

const MakeCurrentLocationButton: React.FC<Props> = ({ locationId, resourceId }) => {
  const updateArtworkCurrentLocation = useUpdateArtworkCurrentLocation();

    const handleMakeCurrentLocation = async (currentLocation: { artworkId: string, locationId: string }) => {
        await updateArtworkCurrentLocation.mutateAsync(currentLocation);
    }
  return (
    <Button variant="child" fullWidth onClick={() => handleMakeCurrentLocation({ artworkId: resourceId, locationId })}>Make Current Location</Button>
  )
}

export default withPermissionGuard([PERMISSION_UPDATE])(MakeCurrentLocationButton);