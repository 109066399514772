import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid2';
import { getInputValue } from '../../utils/helpers';
import ImageModal from '../parts/modals/image/ImageModal';
import { useArtworkDetail } from '../../api/artwork';
import ArtworkCard from '../parts/cards/artwork/ArtworkCard';
import { Card, CardHeader, CardContent, List, Stack, Typography } from '@mui/material';
import CustomListItemText from '../parts/CustomListItemText';
import SignatureBox from '../parts/forms/artwork/SignatureBox';
import withResourceGuard from '../authentication/ResourceGuard';
import { RESOURCE_ARTWORK } from '../../utils/constants';
import ArtworkLocationCard from '../parts/cards/location/ArtworkLocationCard';
import QRCodeImage from '../parts/cards/QRCodeImage';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum AuthorTypes {
  Artist = 'artist',
  Photographer = 'photographer',
  Publisher = 'publisher',
  Curator = 'curator'
}

const ArtworkDetail: React.FC = () => {
  const { authorId, artworkId } = useParams();

  const { data: artwork } = useArtworkDetail({ authorId, artworkId });
  const images = _.sortBy(_.get(artwork, 'images', []), ['createdAt', 'filename']);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 2 }}>
        <Grid size={{ xs:12, md:6}} flex={1}>
          <ArtworkCard artwork={artwork} qrcode={<QRCodeImage width={100} />} >
          </ArtworkCard>
        </Grid>
        <Grid size={{ xs:12, md:6}}>
          <Stack direction='row' spacing={2} overflow='auto' sx={{ }}>
            {_.map(images, (image, index: number) =>
              <ImageModal image={image} imageList={images}></ImageModal>
            )}
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
        <Grid size={{ xs:12, md:6 }}>
          <Card variant='grey'>
            <CardHeader
              title={<Typography variant="h4">Material, Medium, Technique and Condition</Typography>}
            />
            <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
              <List>
                <CustomListItemText label='Material' value={`${getInputValue({ inputName: 'material', value: _.get(artwork, 'material.name') })} ${_.get(artwork, 'material.description') ? ` / ${_.get(artwork, 'material.description')}` : ''}`} />
                <CustomListItemText label='Medium' value={`${getInputValue({ inputName: 'medium', value: _.get(artwork, 'medium.name') })} ${_.get(artwork, 'medium.description') ? ` / ${_.get(artwork, 'medium.description')}` : ''}`} />
                <CustomListItemText label='Technique' value={`${getInputValue({ inputName: 'technique', value: _.get(artwork, 'technique.name') })} ${_.get(artwork, 'technique.description') ? ` / ${_.get(artwork, 'technique.description')}` : ''}`} />
                <CustomListItemText label='Condition' value={`${getInputValue({ inputName: 'condition', value: _.get(artwork, 'condition.name') })} ${_.get(artwork, 'condition.description') ? ` / ${_.get(artwork, 'condition.description')}` : ''}`} />
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs:12, md:6 }}>
          <Card variant='grey'>
            <CardHeader
              title={<Typography variant="h4">Signature</Typography>}
            />
            <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
              <SignatureBox disabled={true} signatureLocation={_.get(artwork, 'signature.location')} />
              <List>
                <CustomListItemText label='Signature Side' value={`${getInputValue({ inputName: 'signature side', value: _.get(artwork, 'signature.side') })}`} />
                <CustomListItemText label='Signature Description' value={`${getInputValue({ inputName: 'signature description', value: _.get(artwork, 'signature.description') })}`} />
                <CustomListItemText label='Back Side Description' value={`${getInputValue({ inputName: 'back side description', value: _.get(artwork, 'backSideDescription') })}`} />
              </List>
            </CardContent>
          </Card>
        </Grid>

        <Grid size={{ xs:12, md:12 }}>
            <Card variant='grey'>
              <CardHeader
                title={<Typography variant="h4">Notes</Typography>}
              />
              <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                <Typography variant="body1">{getInputValue({ inputName: 'artwork notes', value: _.get(artwork, 'notes') })}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid size={{ xs:12, md:12 }}>
            <Card variant='grey'>
              <CardHeader
                title={<Typography variant="h4">Accessories</Typography>}
              />
              <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                <Typography variant="body1">{getInputValue({ inputName: 'accessories', value: _.get(artwork, 'accessories') })}</Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid size={{ xs:12, md:12 }}>
            <Card variant='grey'>
              <CardHeader
                title={<Typography variant="h4">Adjustment</Typography>}
              />
              <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                {_.get(artwork, 'adjustment.selected') ?
                  <Typography variant="body1">{_.join(_.compact(_.castArray(getInputValue({ inputName: 'adjustment', value: _.get(artwork, 'adjustment.options') }))), ', ')}</Typography>
                  :
                  <Typography variant="body1">unknown artwork adjustment</Typography>
                }
                <Typography variant="body1">{_.get(artwork, 'adjustment.description')}</Typography>
              </CardContent>
            </Card>
          </Grid>

        <Grid size={{ xs:12, md:12 }}>
            <ArtworkLocationCard locations={_.get(artwork, 'locations')} resourceId={_.get(artwork, '_id')} resource={RESOURCE_ARTWORK} />
          </Grid>
        
      </Grid > 
    </>
  )
}

export default withResourceGuard([RESOURCE_ARTWORK])(ArtworkDetail);