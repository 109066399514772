import _ from 'lodash';
import React, { FC, Component, useEffect, useState } from 'react';

import { RESOURCE_EXHIBITION } from '../../utils/constants';
import { getNormalizedString } from '../../utils/helpers';
import { useExhibitions } from '../../api/exhibition';
import { Button, Typography, Stack, Container, TextField } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomAutocomplete from '../parts/forms/inputs/CustomAutocomplete';
import { FormProvider, useForm } from 'react-hook-form';
import ExhibitionFormModal from '../parts/modals/exhibition/ExhibitionFormModal';
import ExhibitionsCard from '../parts/cards/exhibition/ExhibitionsCard';
import SearchCard from '../parts/cards/SearchCard';
import { Link } from 'react-router-dom';
import withResourceGuard from '../authentication/ResourceGuard';

enum ExhibitionTypes {
  Artist = 'artist',
  Photographer = 'photographer',
  Publisher = 'publisher',
  Curator = 'curator'
}


const ExhibitionList: React.FC = (props: any) => {
  const [searchString, setSearchString] = useState('');

  const { data: exhibitions } = useExhibitions();

  const regex = new RegExp(getNormalizedString(searchString), 'i');

  const normalizedExhibition = _.map(exhibitions, (exhibition) => {
    const { title, ...exhibitionData } = exhibition;
    return {
      normalizedTitle: getNormalizedString(title),
      title,
      ...exhibitionData
    }
  });

  const filteredExhibitions = _.filter(normalizedExhibition, exhibition => {
    const title = _.get(exhibition, 'normalizedTitle');
    if (title && regex.test(title)) {
      return exhibition;
    }
  });

  const orderedExhibitions = _.orderBy(filteredExhibitions, ['normalizedName'], ['asc']);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4 }}>
          <ExhibitionsCard />
        </Grid>
        <Grid size={{ xs:12, md:8 }}>
          <SearchCard searchFunction={setSearchString} />
        </Grid>
      </Grid>

      {
        _.map(orderedExhibitions, (exhibition) =>
          <Stack direction='row' sx={{ textAlign: 'left' }}>
            <Button
              component={Link}
              type="button"
              to={`/exhibitions/${_.get(exhibition, '_id')}`}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="h2" color="text.primary">{_.get(exhibition, 'title')}</Typography>
            </Button>
          </Stack>
        )
      }
    </>
  )
}

export default withResourceGuard([RESOURCE_EXHIBITION])(ExhibitionList);