import React from 'react';
import { Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';

import { useAddArtworksToExhibition } from '../../../../api/exhibition';
import withPermissionGuard from '../../../authentication/PermissionGuard';
import { getAutocompleteValue } from '../../../../utils/helpers';
import { PERMISSION_CREATE, PERMISSION_UPDATE } from '../../../../utils/constants';

import FormModal from '../FormModal';
import CustomAutocomplete, { AutocompleteOption } from '../../forms/inputs/CustomAutocomplete';

interface AddArtworksToExhibitionProps {
  artworkOptions: AutocompleteOption | AutocompleteOption[] | undefined,
  exhibitionId: string,
  buttonClassName?: string,
  buttonFullWidth?: boolean,
  buttonText: string,
  defaultValues?: any,
  dialogTitle: string
}

const AddArtworksToExhibition = (props: AddArtworksToExhibitionProps) => {
  const [open, setOpen] = React.useState(false);

  const { handleSubmit, getValues, control, reset, formState: { isSubmitting } } = useForm();

  const addArtworksToExhibition = useAddArtworksToExhibition();

  const handleOpen = () => {
    setOpen(true);
    reset({ artworkIds: props.defaultValues });
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async () => {
    const values = getValues();

    await addArtworksToExhibition.mutateAsync({
      artworkIds: getAutocompleteValue(values.artworkIds),
      exhibitionId: props.exhibitionId
    });

    handleClose();
  };

  return (
    <FormModal dialogTitle={props.dialogTitle} buttonText={props.buttonText} buttonClassName={props.buttonClassName} buttonFullWidth={props.buttonFullWidth} handleClose={handleClose} open={open} handleOpen={handleOpen} fullWidth={true}>
      {open && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomAutocomplete name='artworkIds' label='Artworks' multiple={true} freeSolo={false} options={props.artworkOptions} textFieldProps={{ variant: 'standard' }} control={control} required />
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Button color="inherit" onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained" disabled={isSubmitting}>Save</Button>
          </Stack>
        </form>
      )}
    </FormModal>
  );
}

export default withPermissionGuard([PERMISSION_CREATE, PERMISSION_UPDATE])(AddArtworksToExhibition);