import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Chip, Stack, ListItem } from '@mui/material';

import CustomCard from '../CustomCard';
import { IUser } from '../../../../../../../common/interfaces/IData';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { RESOURCE_USER } from '../../../../utils/constants';

type UserCardProps = {
    user: IUser;
    allowedOperations: string[];
}

const PermissionCard: React.FC<UserCardProps> = (props) => {
    const { user } = props;

    return (
        <CustomCard
            title={{ title: 'Permissions' }}
            subtitle={`for ${_.get(user, 'name')}`}
            content={
                <Stack spacing={1}>
                    <ListItem>
                        <Chip variant="outlined" size="small" label={_.get(user, 'email')} sx={{ color: 'inherit' }} />
                    </ListItem>
                    <ListItem>
                        <Chip variant="outlined" size="small" label={_.toLower(_.get(user, 'roleId.roleTypeId.roleName'))} sx={{ color: 'inherit' }} />
                    </ListItem>
                </Stack>
            }
        />
    )
}

export default withResourceGuard([RESOURCE_USER])(PermissionCard);