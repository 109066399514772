import _ from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Chip, Card, CardHeader, CardContent, Button, TableContainer, Table, TableHead, TableRow, TableBody } from '@mui/material';
import CustomTableCellCenter from './forms/inputs/CustomTableCellCenter';
import Grid from '@mui/material/Grid2';

import ResourceLinkButton from './buttons/ResourceLinkButton';
import GlobalPermissionModal from './modals/permission/GlobalPermissionModal';
import DeleteModal from './modals/DeleteModal';
import { SmallMenu, useSmallMenu } from './SmallMenu';

import { useUserDetail } from '../../api/user';
import { useDeletePermission } from '../../api/permission';

const PermissionList: React.FC = () => {
    const { userId } = useParams();
    const { data: managedUser } = useUserDetail(userId);
    const deletePermission = useDeletePermission();
    const managedUserRole = managedUser;

    const userEmail = _.get(managedUser, 'email', '');
    const role = _.get(managedUserRole, 'roleId');
    const roleType = _.get(role, 'roleTypeId');
    const roleName = _.get(roleType, 'roleName');
    const allowedResources: string[] = _.get(roleType, 'allowedResources', [])
    const allowedOperations: string[] = _.get(roleType, 'allowedOperations', [])
    const limitedPermissions = _.filter(_.get(role, 'permissionIds'), { limitedPermission: true });
    const fullPermissions = _.filter(_.get(role, 'permissionIds'), { limitedPermission: false });

    return (
        <>
            <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                <Grid size={{ xs:12, md:12 }}>
                    <Card variant='grey'>
                        <CardHeader
                            title={<Typography variant="h4">Full Permissions</Typography>}
                            action={
                                <GlobalPermissionModal
                                    dialogTitle='Add Permission'
                                    buttonText='Add Permission'
                                    allowedResources={allowedResources}
                                    allowedOperations={allowedOperations}
                                    userEmail={userEmail}
                                    userId={userId}
                                    buttonFullWidth={true}
                                />
                            }
                        />
                        <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                        <TableContainer className='table-responsive'>
                                <Table className='table align-middle'>
                                    <TableHead>
                                        <TableRow sx={{ alignText: 'center' }}>
                                            {_.map(['Resource', 'Operations', 'Action'], (head: string) => (
                                                <CustomTableCellCenter key={head}>{head}</CustomTableCellCenter>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(fullPermissions, (permission: any) => (
                                            <TableRow key={permission._id} sx={{ alignText: 'center' }}>
                                                <CustomTableCellCenter><Button variant="outlined">{_.get(permission, 'resource', '')}</Button></CustomTableCellCenter>
                                                <CustomTableCellCenter>{_.map(_.get(permission, 'operations'), (operation: string) => <Chip size="small" color="primary" variant="outlined" sx={{ mx: '0.25rem' }} label={_.capitalize(operation)} />)}</CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <SmallMenu
                                                        customMenuButtonIcon={<Typography variant="button">Update Permission</Typography>}
                                                    >
                                                        <DeleteModal action='delete' actionFunction={deletePermission} resourceTitle={_.get(permission, 'resource')} resourceDeletionData={{ permissionId: _.get(permission, '_id'), userId }} buttonFullWidth={true} buttonText='Delete Permission' dialogTitle='Delete Permission' />
                                                    </SmallMenu>
                                                </CustomTableCellCenter>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid size={{ xs:12, md:12 }}>
                    <Card variant='grey'>
                        <CardHeader
                            title={<Typography variant="h4">Limited Permissions</Typography>}
                            subtitle={<Typography variant="body1">The user has limited access to the following resources</Typography>}
                        />
                        <CardContent sx={{ paddingTop: '0', paddingBottom: '0' }}>
                            <TableContainer className='table-responsive'>
                                <Table className='table align-middle'>
                                    <TableHead>
                                        <TableRow sx={{ alignText: 'center' }}>
                                            {_.map(['Resource', 'Operations', 'Action'], (head: string) => (
                                                <CustomTableCellCenter key={head}>{head}</CustomTableCellCenter>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {_.map(limitedPermissions, (permission: any) => (
                                            <TableRow key={permission._id} sx={{ alignText: 'center' }}>
                                                <CustomTableCellCenter><ResourceLinkButton resourceData={_.get(permission, 'resourceId')} resourceType={_.get(permission, 'resource', '')}></ResourceLinkButton></CustomTableCellCenter>
                                                <CustomTableCellCenter>{_.map(_.get(permission, 'operations'), (operation: string) => <Chip size="small" color="primary" variant="outlined" sx={{ mx: '0.25rem' }} label={_.capitalize(operation)} />)}</CustomTableCellCenter>
                                                <CustomTableCellCenter>
                                                    <SmallMenu
                                                        customMenuButtonIcon={<Typography variant="button">Update Permission</Typography>}
                                                    >
                                                        <DeleteModal action='delete' actionFunction={deletePermission} resourceTitle={_.get(permission, 'resource')} resourceDeletionData={{ permissionId: _.get(permission, '_id'), userId }} buttonFullWidth={true} buttonText='Delete Permission' dialogTitle='Delete Permission' />
                                                    </SmallMenu>
                                                </CustomTableCellCenter>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                
            </Grid>
        </>
    )
}

export default PermissionList;
