import React from 'react';

import CustomCard from '../CustomCard';
import LoginForm from '../../forms/user/LoginForm';
import { Box } from '@mui/material';

const LoginCard: React.FC = () => {
    return (
        <CustomCard
            title={{ title: 'Welcome to Artworks', titleSx: { color: 'black' }}}
            variant='grey'
            content={
                <Box sx={{ px: 1 }}>
                <LoginForm />
                </Box>
            }
            headerActionsDisabled={true}
            footerActionsDisabled={true}
            contentSx={{ p: 0 }}
            cardSx={{ display: 'flex', height: 'auto', p: 1, maxWidth: '600px'}}
        />
    )
}

export default LoginCard;