import _ from 'lodash';
import React from 'react';
import { useAuth } from '../../store/zustand';

type OperationsToCheck = ('CREATE' | 'READ' | 'UPDATE' | 'DELETE' | 'EXPORT')[];

type Props = {
    operationsToCheck: OperationsToCheck,
    children: React.ReactNode
}

const PermissionGuard: React.FC<Props> = ({ operationsToCheck, children }) => {
    const user = useAuth.getState().user;
    const allowedOperations = _.get(user, 'allowedOperations', []);

    if (_.size(_.intersection(allowedOperations, operationsToCheck)) > 0) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return null;
    }
};

const withPermissionGuard = (operationsToCheck: OperationsToCheck) => (WrappedComponent: React.ComponentType<any>) => (props: any) => <PermissionGuard operationsToCheck={operationsToCheck}>{<WrappedComponent {...props} />}</PermissionGuard>;

export default withPermissionGuard;