import _ from 'lodash';
import React from 'react';
import ExhibitionFormModal from '../../modals/exhibition/ExhibitionFormModal';
import CustomCard from '../CustomCard';
import ShareResourceWithUserModal from '../../modals/permission/ShareResourceWithUserModal';
import { RESOURCE_EXHIBITION } from '../../../../utils/constants';
import { useDeleteExhibition, useExhibitions } from '../../../../api/exhibition';
import { getOptionsWithLabel } from '../../../../utils/helpers';
import withResourceGuard from '../../../authentication/ResourceGuard';
import { Box, ListItem } from '@mui/material';
import { IExhibition } from '../../../../../../../common/interfaces/IData';
import DeleteModal from '../../modals/DeleteModal';
import { SmallMenu, useSmallMenu } from '../../SmallMenu';

type Props = {
    exhibition: IExhibition;
    headerActionsDisabled?: boolean;
    footerActionsDisabled?: boolean;
}

const ExhibitionCard: React.FC<Props> = (props) => {
    const { exhibition } = props;

    const deleteExhibition = useDeleteExhibition();

    const exhibitionOptions = getOptionsWithLabel(exhibition, { value: '_id', label: 'title' });

    return (
        <CustomCard
            title={{ title: _.get(exhibition, 'title'), linkTo: `/exhibitions/${_.get(exhibition, '_id')}` }}
            content={
                <Box>
                    <ListItem>{_.get(exhibition, 'year')}</ListItem>
                </Box>
            }
            headerActions={
                <SmallMenu>
                    <ExhibitionFormModal action='update' exhibitionId={_.get(exhibition, '_id')} buttonFullWidth={true} buttonText='Update Exhibition' defaultValues={exhibition} dialogTitle='Update Exhibition' />
                    <DeleteModal action='delete' actionFunction={deleteExhibition} resourceTitle={_.get(exhibition, 'title')} resourceDeletionData={{ exhibitionId: _.get(exhibition, '_id') }} buttonFullWidth={true} buttonText='Delete Exhibition' dialogTitle='Delete Exhibition' />
                    <ShareResourceWithUserModal
                        resource={RESOURCE_EXHIBITION}
                        resourceOptions={exhibitionOptions}
                        resourceDisabled={true}
                        defaultValues={{ resourceId: exhibitionOptions }}
                        buttonFullWidth={true}
                        buttonText='Share Exhibition'
                        dialogTitle='Share Exhibition'
                    />
                </SmallMenu>
            }
            footerActionsDisabled={true}
        />
    )
}

export default withResourceGuard([RESOURCE_EXHIBITION])(ExhibitionCard);