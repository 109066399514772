import _ from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Typography, Stack, Container } from '@mui/material';
import Grid from '@mui/material/Grid2';

import AuthorsCard from '../parts/cards/author/AuthorsCard';
import SearchCard from '../parts/cards/SearchCard';
import withResourceGuard from '../authentication/ResourceGuard';
import { RESOURCE_AUTHOR } from '../../utils/constants';
import { getNormalizedString } from '../../utils/helpers';
import { useAuthors } from '../../api/people';

enum AuthorTypes {
  Artist = 'artist',
  Photographer = 'photographer',
  Publisher = 'publisher',
  Curator = 'curator'
}


const AuthorList = (props: any) => {
  const [artistName, searchArtist] = useState('');

  const { data: authors } = useAuthors();

  const artists = _.filter(authors, { type: 'artist' });
  const regex = new RegExp(getNormalizedString(artistName), 'i');

  const normalizedArtists = _.map(artists, (artist) => {
    const { name, ...artistData } = artist;
    return {
      normalizedName: getNormalizedString(name),
      name,
      ...artistData
    }
  });

  const filteredArtistsByName = _.filter(normalizedArtists, artist => {
    const name = _.get(artist, 'normalizedName');
    if (name && regex.test(name)) {
      return artist;
    }
  });

  const orderedArtists = _.orderBy(filteredArtistsByName, ['normalizedName'], ['asc']);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'stretch', marginBottom: 1 }}>
        <Grid size={{ xs:12, md:4}}>
          <AuthorsCard />
        </Grid>
        <Grid size={{ xs:12, md:8}}>
          <SearchCard searchFunction={searchArtist} />
        </Grid>
      </Grid>

      {
        _.map(orderedArtists, (artist) =>
          <Stack direction='row' sx={{ textAlign: 'left' }}>
            <Button
              component={Link}
              type="button"
              to={`/people/${_.get(artist, '_id')}`}
              sx={{ textTransform: 'none' }}
            >
              <Typography variant="h3" color="text.primary">{_.get(artist, 'name')}</Typography>
            </Button>
          </Stack>
        )
      }
    </>
  )
}

export default withResourceGuard([RESOURCE_AUTHOR])(AuthorList);